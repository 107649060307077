<template>
  <div >
    <div class="mb-7" style="overflow-x:auto;">
      <div class="d-flex">
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.color') }}
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.fabric_type') }}
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.color') }}
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.color_ok') }}
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.weight_with_unit') }}
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.width') }}
      </div>
    </div>
    <div class="d-flex">
      <div class="col-6 py-2 text-center top-1-items">
        <div class="px-2">
          <custom-multi-select
              :item-per-row="1"
              :max="1"
              :model.sync="modelProxy.order_size_and_color_id"
              :not-list="true"
              :options="orderSizeAndColorOptions"
              :placeholder="$t('general.please_select_option')"
              :required="false"
              :title="null"
              name="fabricDetailOrderSizeAndColorOptions"
              width="300px"
              @onSelect="orderSizeAndColorSave($event)"
          ></custom-multi-select>
        </div>
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        <div class="px-2">
          <input
              type="text"
              :name="`fabric_type_${uid}`"
              v-model="modelProxy.fabric_type"
              :placeholder="$t('order.please_enter_fabric_type')"
              class="form-control form-control-sm"
              :class="{'is-invalid': !modelProxy.fabric_type}"
          >
        </div>
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        <div class="px-2">
          <input
              type="text"
              :name="`color_${uid}`"
              v-model="modelProxy.color"
              :placeholder="$t('order.please_enter_color')"
              class="form-control form-control-sm"
              :class="{'is-invalid': !modelProxy.color}"
          >
        </div>
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        <div class="px-2">
          <input
              type="text"
              :name="`color_arrow_${uid}`"
              v-model="modelProxy.color_ok"
              :placeholder="$t('order.please_enter_color_arrow')"
              class="form-control form-control-sm"
              :class="{'is-invalid': !modelProxy.color_ok}"
          >
        </div>
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        <div class="px-2">
          <input
              type="text"
              :name="`weight_${uid}`"
              v-model="modelProxy.weight"
              :placeholder="$t('order.please_enter_weight')"
              class="form-control form-control-sm"
              style="border-color: #78cdea; background-color:#f3f6f9;"
              :class="{'is-invalid': !modelProxy.weight}"
          >
        </div>
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        <div class="px-2">
          <input
              type="text"
              :name="`width_${uid}`"
              :placeholder="$t('order.please_enter_width')"
              v-model="modelProxy.width"
              class="form-control form-control-sm"
              style="border-color: #78cdea; background-color:#f3f6f9;"
              :class="{'is-invalid': !+modelProxy.width}"
          >
        </div>
      </div>
    </div>
    </div>

    <div class="mb-7" style="overflow-x:auto;">
      <div class="d-flex ">
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.quantity') }}
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.unit') }}
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.tolerance_quantity') }}(%)
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.total_quantity') }}
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.incoming_quantity') }}
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        {{ $t('order.remaining_quantity') }}
      </div>
    </div>
    <div class="d-flex">
      <div class="col-6 py-2 text-center top-1-items">
        <div class="p-2">
          <input
              v-model="modelProxy.amount"
              :class="{'is-invalid': !modelProxy.amount}"
              :name="`order_fabric_knit_type_amount_${uid}`"
              :placeholder="$t('order.please_enter_quantity')"
              :required="false"
              :step="1"
              :title="null"
              class="form-control form-control-sm"
              min="0"
              step="0.01"
              type="number"
              style="border-color: #78cdea; background-color: #f3f6f9;"
          />
        </div>
      </div>
      <div class="col-6 py-2 text-center top-1-items">
        <custom-multi-select
            :has-bottom-margin="false"
            :item-per-row="2"
            :max="1"
            :model.sync="modelProxy.amount_unit_id"
            :name="`amount_unit_id_${index}`"
            :not-list="true"
            :options="amountUnitOptions"
            :required="false"
            :title="null"
            size="sm"
        ></custom-multi-select>
  </div>
      <div class="col-6 py-2 text-center top-1-items">
        <div class="px-2">
          <input
              v-model="modelProxy.tolerance_amount"
              :disabled="true"
              :name="`order_fabric_knit_type_amount_tolerance_quantity_${uid}`"
              :placeholder="$t('order.please_enter_tolerance_amount')"
              :required="false"
              :step="1"
              :title="null"
              class="form-control form-control-sm"
              type="number"
          >
        </div>
      </div>
      <div class="col-6 py-2 text-center top-1-items d-flex justify-content-center items-center">
        {{ totalAmount | amountFormat}}
      </div>
      <div class="col-6 py-2 text-center top-1-items d-flex justify-content-center items-center">{{ modelProxy.incoming_amount | amountFormat }}</div>
      <div class="col-6 py-2 text-center d-flex justify-content-center items-center top-1-items"> {{ modelProxy.remaining_amount | amountFormat  }}</div>
    </div>
    </div>
    
  </div>
</template>

<script>
import {v4 as uuid} from 'uuid';
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";

export default {
  name: "FabricDetailTableRow",
  components: {
    CustomMultiSelect
  },
  props: {
    model: {
      required: true
    },
    orderSizeAndColorOptions: {
      required: true
    },
    amountUnitOptions: {
      required: false
    },
  },
  data() {
    return {
      uid: uuid(),
    }
  },
  methods: {
    orderSizeAndColorSave(selectedValue) {
      this.$emit('colorChanged', selectedValue)
    },
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    },
    totalAmount() {
      this.modelProxy.total_amount = this.modelProxy.amount * (1 + (+this.modelProxy.tolerance_amount / 100))
      return (+this.modelProxy.total_amount) || 0;
    },
  }
}
</script>

<style scoped>

</style>
