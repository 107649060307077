<template>
  <div class="px-5">
    <template v-for="(currentDetail, index) in model">
      <h5 class="m-2 text-center">
        <button
          :class="{ selected: currentDetail.form_type === 2 }"
          @click="currentDetail.form_type = 1"
        >
          {{ $t("order_form.mid_control") }}
        </button>
        /
        <button
          :class="{ selected: currentDetail.form_type === 1 }"
          @click="currentDetail.form_type = 2"
        >
          {{ $t("order_form.final_report") }}
        </button>
      </h5>
      <template>
        <div class="mid-control-master border-15px">
          <div class="d-flex navy-blue-border-bottom-1px">
            <div
              style="width: 50%"
              class="d-flex text-center justify-content-center align-items-center navy-blue-border-right-1px m-0 p-0"
            >
              <div
                class="w-50 d-flex text-center justify-content-center align-items-center"
              >
                <b class="mr-1">{{ $t("order_form.stop") }}</b>
                <v-checkbox
                  @change="selectStatus(index)"
                  v-model="currentDetail.status"
                  class="m-0 p-0"
                  size="5"
                  hide-details
                  :true-value="0"
                  :false-value="1"
                >
                </v-checkbox>
                <b class="mr-1">{{ $t("order_form.okey") }}</b>
                <v-checkbox
                  v-model="currentDetail.status"
                  @change="selectStatus(index)"
                  class="m-0 p-0"
                  size="5"
                  hide-details
                  :true-value="1"
                  :false-value="0"
                >
                </v-checkbox>
              </div>
            </div>
            <div
              style="width: 50%"
              class="py-2 text-center d-flex justify-content-center"
            >
              <date-picker-input
                class="font-weight-bold d-flex justify-content-center"
                :model.sync="currentDetail.date"
                :required="false"
                :title="$t('general.date') + ':'"
              ></date-picker-input>
            </div>
          </div>
          <div
            class="d-flex justify-content-center navy-blue-border-bottom-1px"
            v-if="currentDetail.status == 0"
          >
            <custom-multi-select
              :item-per-row="1"
              :model.sync="currentDetail.stop_options"
              :not-list="false"
              :options="formStopOptions"
              :placeholder="$t('general.please_select_option')"
              :required="false"
              :title="null"
              name="sourcingTypeOptions"
              class="pt-2"
              @save="changeSizes($event, index)"
            ></custom-multi-select>
          </div>
          <div class="d-flex navy-blue-border-bottom-1px">
            <div style="width: 50%" class="py-2 text-center navy-blue-border-right-1px">
              <b>{{ $t("order_form.order_number") }}:</b> <br />{{ order.order_number }}
            </div>
            <div
              style="width: 50%"
              class="py-2 text-center d-flex justify-content-center align-items-center"
            >
              <div class="row">
                <b class="col-12">{{ $t("order_form.supplier_company") }}:</b>

                <div class="mt-1 col-12">
                  <custom-multi-select
                    :item-per-row="1"
                    :model.sync="currentDetail.supplier_company_id"
                    :not-list="true"
                    :max="1"
                    :options="supplierCompanies"
                    :placeholder="$t('general.please_select_option')"
                    :required="false"
                    :title="null"
                    :is-inline="true"
                    name="sourcingTypeOptions"
                    class="pt-2"
                    @save="changeSizes($event, index)"
                  ></custom-multi-select>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex navy-blue-border-bottom-1px">
            <div style="width: 50%" class="py-2 text-center navy-blue-border-right-1px">
              <b>{{ $t("order_form.model_no") }}:</b>
              <p>{{ order.model }}</p>
            </div>
            <div
              style="width: 50%"
              class="py-2 text-center d-flex justify-content-center align-items-center"
            >
              <div class="row d-flex justify-content-center">
                <div class="col-12">
                  <b class="pr-1">{{ $t("order_form.operation_type") }}: </b>
                </div>
                <div class="col-12">
                  <input
                    v-model="currentDetail.operation_type"
                    class="form-control form-control-sm"
                    style="max-width: 100%"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex navy-blue-border-bottom-1px">
            <div
              class="flex-1 p2 d-flex justify-content-center align-items-center "
            >
              <b class="mr-2">{{ $t("order_form.measurement_location") }}</b>
              <span
                class="d-flex justify-content-center items-center cursor-pointer font-size-sm"
                @click="addNewSizeLine(index)"
              >
                <span
                  v-html="
                    getIconByKey('icons.waybill.add_document', {
                      class: 'w-20px h-20px object-cover d-inline-block opacity-75 ',
                    })
                  "
                >
                </span>
              </span>
            </div>
            <div
              class="flex-1 p2 d-flex p-0 justify-content-center align-items-center"
            >
              <b>{{ $t("order_form.sizes") }}:</b>
              <custom-multi-select
                :item-per-row="1"
                :model.sync="currentDetail.sizes"
                :not-list="false"
                :options="bodySizeOptions"
                :placeholder="$t('general.please_select_option')"
                :required="false"
                :title="null"
                name="sourcingTypeOptions"
                class="pt-2"
                @save="changeSizes($event, index)"
              ></custom-multi-select>
            </div>
           
          </div>
          <div class="d-flex navy-blue-border-bottom-1px">
            <div style="width: 100%" class="m-0 p-0 navy-blue-border-right-1px">
              <div v-if="currentDetail.measuring_location.length > 0">
                <template
                  v-for="(
                    measuring_location, measuringIndex
                  ) in currentDetail.measuring_location"
                >
                  <div>
                    <div
                      class="row mx-0 py-3 navy-blue-border-bottom-1px"

                    >
                      <div
                        class="col-12 d-flex justify-content-center align-items-end pb-3 text-lg font-weight-bold"
                      >
                        <span
                          @click="deleteMeasuringLocation(index, measuringIndex)"
                          v-html="
                            getIconByKey('icons.sample.delete_red', {
                              class:
                                'w-20px h-20px m-0 p-0 object-cover d-inline-block opacity-75 mr-2',
                            })
                          "
                        >
                        </span>
                        <input
                          v-model="measuring_location.measuring_location"
                          required
                          class="form-control form-control-sm min-width-50"
                          type="text"
                        />
                      </div>
                    </div>
                    <div
                      class="mx-0 d-flex justify-content-center align-items-center text-center m-0 p-0"
                    >
                      <div
                        class="table-responsive m-0 p-0"
                        v-if="measuring_location.sizes.length"
                      >
                        <table class="table table-sm">
                          <thead>
                            <tr>
                              <template v-for="size in measuring_location.sizes">
                                <th
                                  class="text-center navy-blue-border-bottom-1px m-0 p-0"
                                >
                                  <div
                                    class="w-100 d-flex justify-content-center items-center"
                                  >
                                    <span class="d-inline-block px-1">
                                      {{ size.name }}
                                    </span>
                                  </div>
                                </th>
                              </template>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="h-100">
                              <template v-for="note in measuring_location.sizes">
                                <td
                                  class="navy-blue-border-right-1px navy-blue-border-top-1px navy-blue-border-bottom-1px"
                                >
                                  <div class="d-flex justify-content-center">
                                    <input
                                      v-model="note.note_1"
                                      class="form-control form-control-sm"
                                      style="min-width: 55px"
                                      type="text"
                                    />
                                    <input
                                      v-model="note.note_2"
                                      class="form-control form-control-sm"
                                      style="min-width: 55px"
                                      type="text"
                                    />
                                    <input
                                      v-model="note.note_3"
                                      class="form-control form-control-sm"
                                      style="min-width: 55px"
                                      type="text"
                                    />
                                  </div>
                                </td>
                              </template>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="text-center w-100 navy-blue-border-bottom-1px" v-else>
                <b>{{ $t("order_form.please_select_size") }}</b>
              </div>
              <div class="d-flex navy-blue-border-bottom-1px">
                <div class="flex-1 text-center justify-content-center pt-2 pb-2 navy-blue-border-right-1px"
                style="width: 50%;"
                >
                  <b>{{ $t("order_form.released_errors") }}</b>
                </div>
                <div
                class="flex-1 text-center justify-content-center pt-2 pb-2"
              >
                <b>{{ $t("order_form.measures_to_be_taken") }}</b>
              </div>
              </div>
              <div class="d-flex">
                <div class="flex-1 text-center p-2 justify-content-center navy-blue-border-right-1px"
                style="width: 50%;"
                >
                  <textarea
                    class="form-control"
                    v-model="currentDetail.errors"
                    name="errors_area"
                    id="errors_area"
                    cols="30"
                    rows="10"
                  >
                  </textarea>
                </div>
                <div class="flex-1 text-center p-2 justify-content-center">
                  <textarea
                  class="form-control"
                  v-model="currentDetail.measures"
                  name="measures_area"
                  id="measures_area"
                  cols="30"
                  rows="15"
                >
                </textarea>
                </div>
              </div>
            </div>
          </div>

          <!-- imza kısmı -->
          <div class="d-flex">
            <div
              class="flex-1 justify-content-center text-center p2 pt-2 navy-blue-border-right-1px text-sm"
              style="height: 60px"
            >
              <b>{{ $t("order_form.company_responsible") }}</b>
            </div>
            <div
              class="flex-1 justify-content-center text-center p2 pt-2 navy-blue-border-right-1px text-sm"
              style="height: 60px"
            >
              <b>{{ $t("order_form.workshop_manager") }}</b>
            </div>
            <div
              class="flex-1 justify-content-center text-center p2 pt-2 text-sm"
              style="height: 60px"
            >
              <b>{{ $t("order_form.manager") }}</b>
            </div>
          </div>
        </div>
      </template>
      <span
        v-if="isValidToSubmit && index == modelProxy.length - 1"
        class="cursor-pointer"
        @click="addNewForm"
        v-html="
          getIconByKey('icons.waybill.waybill_entry', {
            class: 'w-50px h50px d-line-block object-fill',
          })
        "
      ></span>
      <span
        v-if="index > 0 && !currentDetail.id"
        class="cursor-pointer"
        @click="deleteNewForm(index)"
        v-html="
          getIconByKey('icons.sample.delete_red', {
            class: 'w-20px h-20px d-inline-block mr-3 ',
          })
        "
      ></span>

      <template
        v-if="
          isUserGranted(
            'OrderMidControlFinalReportForm',
            ['update', 'create', 'delete'],
            false,
            true
          )
        "
      >
        <bottom-buttons
          :emails="[]"
          :is-editing.sync="currentDetail.is_editing"
          :is-valid-to-submit="isValidToSubmit(index)"
          :model="currentDetail"
          :order-id="order.id"
          name="mid-form"
          @onClickCancelForm="onClickCancelForm(currentDetail.id, index)"
          @submit="submitForm(index)"
        ></bottom-buttons>
      </template>
    </template>
  </div>
</template>
<script>
import TextInput from "@/assets/components/inputs/TextInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import BottomButtons from "@/view/pages/order/forms/layout/BottomButtons";
import moment from "moment";
import { LARAVEL_DATE_TIME_FORMAT } from "@/core/config/constant";
import {
  CREATE_ITEM,
  DELETE_ITEM_BY_ID,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/REST.module";

import _ from "lodash";
import $ from "jquery";
export default {
  name: "MidControl",
  components: {
    TextInput,
    TextareaInput,
    DatePickerInput,
    CustomMultiSelect,
    BottomButtons,
  },
  data() {
    return {
      is_submitting: false,
    };
  },
  props: {
    model: {
      required: true,
    },
    defaultModel: {
      required: true,
    },
    supplierCompanies: {
      required: true,
    },
    orderSizeAndColors: {
      required: true,
    },
    bodySizes: {
      required: true,
    },
    formStopOptions: {
      required: true,
    },
    order: {
      required: true,
    },
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit("update:modelProxy", value);
      },
    },
    bodySizeOptions() {
      let sizes = this.convertObjectToArray(this.orderSizeAndColors);
      return this.convertArrayToObjectByKey(sizes, "body_size_id", "name");
    },
    selectSizesFilter() {
      let items = [];
      let bodySizes = _.cloneDeep(this.bodySizes);
      Object.entries(bodySizes).forEach(([key, size]) => {
        if (this.form.measuring_location.includes(size.name)) {
          items.push(size.name);
        }
      });
      return items;
    },
  },
  methods: {
    deleteNewForm(index) {
      this.model.splice(index);
    },
    onClickCancelForm(itemId, index) {
      const self = this;
      this.$store
        .dispatch(DELETE_ITEM_BY_ID, {
          url: `api/order/${this.order.id}/form/${itemId}`,
        })
        .then(function (response) {
          if (response.status) {
            self.sweetAlertSuccess(self.$t("general.successfully_deleted"));
            self.modelProxy.splice(index, 1);
            if (!self.modelProxy.length) {
              self.modelProxy = [self.defaultModel];
            }
          } else {
            self.sweetAlertError(self.$t("general.internal_error"));
          }
        });
    },
    isValidToSubmit(index) {
      try {
        let returnData = true;
        const current = this.modelProxy[index];
        if (!current.date) returnData = false;
        if (current.errors === "" || current.errors === null) returnData = false;
        if (!current.form_type || current.form_type === "" || current.form_type === null)
          returnData = false;
        if (!current.measures || current.measures === "" || current.measures === null)
          returnData = false;
        if (!current.measuring_location.length) returnData = false;
        if (
          !current.operation_type ||
          current.operation_type === "" ||
          current.operation_type === null
        )
          returnData = false;
        if (!current.sizes.length) returnData = false;
        if (current.status == 0) {
          if (!current.stop_options.length) returnData = false;
        }
        if (!current.supplier_company_id) returnData = false;
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    submitForm(index) {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    fixedData(index) {
      let items = [];
      let item = this.modelProxy[index];
      item.date = moment(item.date).format(LARAVEL_DATE_TIME_FORMAT);
      items.push(item);
      return items;
    },
    addNewForm() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.id = null;
      this.$emit("getIncomingFabricAmount");
      this.modelProxy.push(copy);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store
        .dispatch(CREATE_ITEM, {
          url: `api/order/${this.order.id}/form`,
          contents: {
            form: self.fixedData(index),
          },
        })
        .then((response) => {
          if (response.status) {
            let detail = self.modelProxy[index];
            let is_editing = !!detail.is_editing;
            let responseData = response.data[0];
            detail.is_editing = true;
            detail.id = responseData.id;

            self.modelProxy[index] = detail;

            self.sweetAlertSuccess(
              self.$t(
                is_editing
                  ? "general.successfully_updated"
                  : "general.successfully_created"
              )
            );
          } else {
            let result = response.data.response;
            if (result.hasOwnProperty("data") && result.data.hasOwnProperty("message")) {
              self.sweetAlertError(result.data.message);
            } else {
              self.sweetAlertError(result.data);
            }
          }
          self.sweetAlertClose();
          self.is_submitting = false;
        });
    },
    addNewSizeLine(index) {
      if (this.model[index].sizes.length <= 0) {
        this.sweetAlertError("Lütfen Beden Seçiniz");
        return false;
      }
      let tmpMeasuringLocation = {
        measuring_location: null,
        sizes: [],
      };
      this.model[index].sizes.forEach((size) => {
        tmpMeasuringLocation.sizes.push({
          name: this.bodySizeOptions[size],
          size_id: +size,
          note_1: null,
          note_2: null,
          note_3: null,
        });
      });
      this.model[index].measuring_location.push(tmpMeasuringLocation);
    },
    deleteMeasuringLocation(index, measuringIndex) {
      this.model[index].measuring_location.splice(measuringIndex, 1);
    },
    changeSizes(event, index) {
      let newValue = event.newValue;
      let oldValue = event.oldValue;

      let nonExistingItems = oldValue.filter((item) => !newValue.includes(item));

      nonExistingItems.forEach((item) => {
        let size = this.bodySizeOptions[item];
        this.model[index].measuring_location.forEach(
          (alreadyExitsControl, measuring_index) => {
            let findSize = _.findIndex(
              alreadyExitsControl.sizes,
              (e) => {
                return e.size_id == +item;
              },
              0
            );

            if (findSize != -1) {
              this.model[index].measuring_location[measuring_index].sizes.splice(
                findSize,
                1
              );
            }

            if (!newValue.length) {
              this.model[index].measuring_location = [];
            }
          }
        );
      });
      newValue.forEach((item) => {
        let size = this.bodySizeOptions[item];
        this.model[index].measuring_location.forEach(
          (alreadyExitsControl, measuring_index) => {
            if (alreadyExitsControl.sizes.find((e) => e.name === size)) {
            } else {
              let tmpSize = {
                name: this.bodySizeOptions[item],
                size_id: +item,
                note_1: null,
                note_2: null,
                note_3: null,
              };
              this.model[index].measuring_location[measuring_index].sizes.push(tmpSize);
            }
          }
        );
      });
    },
    selectStatus(index) {
      if (this.model[index].status == 1) {
        this.model[index].stop_options = [];
      }
    },
  },
};
</script>
<style scoped>
.selected {
  opacity: 0.25;
  text-decoration: line-through;
}
.mid-control-master {
  border: 1px solid #0b5a85;
  background-color: #f3f6f9 !important;
  border-radius: 12px;
}
</style>
