<template>
  <div class="px-0 ">
    <template v-for="(currentDetail, index, i) in modelProxy">
      <div  :class="{'mt-5': +i !== 0}" class="w-100" 
      :ref="`yarn_${currentDetail.id}`"
      :id="`yarn_${currentDetail.id}`">
        <!--TOP 1-->
        <div class="mb-5" style="overflow-x: auto !important;">
          <div class="d-flex">
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.supplier_company') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.dispatch_location') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.yarn_type') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.flow_date') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items" style="border-right: none !important;">
            {{ $t('order.end_date') }}
          </div>
        </div>
        <div class="d-flex">
          <div class="py-2 text-center col-6 top-1-items d-flex mx-0 px-0" style="justify-content:center !important;">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="1"
                :max="1"
                :disabled="currentDetail.lockable_company &&  isUserGranted('Order', ['updateInstructionSupplierCompany'],false,true)"
                :model.sync="currentDetail.supplier_company_id"
                :name="`order_fabric_yarn_supplier_company_${index}`"
                :not-list="true"
                :options="supplierCompanyOptions"
                :required="false"
                :title="null"
            ></custom-multi-select>
          </div>
          <div class="py-2 text-center col-6 top-1-items d-flex mx-0 px-0" style="justify-content:center !important;">
            <custom-multi-select
                :has-bottom-margin="false"
                :is-right="true"
                :item-per-row="1"
                :model.sync="currentDetail.dispatch_location_ids"
                :name="`order_fabric_yarn_fabric_yarn_type_${index}`"
                :options="dispatchLocationOptions"
                :required="false"
                :title="null"
                :max="1"
            ></custom-multi-select>
          </div>
          <div class="py-2 text-center col-6 top-1-items d-flex mx-0 px-0" style="justify-content:center !important;">
            <div class="px-2 text-center">
              <input
                  v-model="currentDetail.yarn_type"
                  :class="{'is-invalid': currentDetail.yarn_type === '' || currentDetail.yarn_type == null }"
                  :name="`order_fabric_yarn_fabric_yarn_type_${index}`"
                  :required="false"
                  class="form-control form-control-sm"
                  type="text"
                  style="border-color: #78cdea; background-color: #f3f6f9;"
              >
            </div>
          </div>
          <div class="py-2 text-center col-6 top-1-items d-flex" style="justify-content:center !important;">
            <div class="text-center">
              <date-picker-input
                  :has-bottom-margin="false"
                  :input-classes="'w-100px'"
                  :model.sync="currentDetail.flow_date"
                  :name="`order_fabric_yarn_flow_date_${index}`"
                  :required="false"
                  :title="null"
              ></date-picker-input>
            </div>
          </div>
          <div class="py-2 text-center col-6 top-1-items d-flex" style="justify-content:center !important; border-right: none !important;">
            <div class="w-100 text-center">
              <date-picker-input
                  :has-bottom-margin="false"
                  :input-classes="'w-100px'"
                  :model.sync="currentDetail.end_date"
                  :name="`order_fabric_yarn_end_date_${index}`"
                  :required="false"
                  :title="null"
              ></date-picker-input>
            </div>
          </div>
        </div>
        </div>

        <!--TOP 2-->
      <div class="mb-5" style="overflow-x: auto !important;">
        <div class="d-flex">
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.color') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.color') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.quantity') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.unit') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.tolerance_quantity') }}(%)
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.total_quantity') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ $t('order.incoming_quantity') }}
          </div>
          <div class="py-2 text-center col-6 top-1-items" style="border-right: none !important;">
            {{ $t('order.remaining_quantity') }}
          </div>
        </div>

        <div class="d-flex">
          <div class="py-2 text-center col-6 top-1-items">
            <div class="px-2">
                 <custom-multi-select
                    :item-per-row="1"
                    :max="1"
                    :model.sync="currentDetail.order_size_and_color_id"
                    :not-list="true"
                    :options="orderSizeAndColorOptions"
                    :placeholder="$t('general.please_select_option')"
                    :required="false"
                    :title="null"
                    name="orderSizeAndColorOptions"
                    width="300px"
                    @onSelect="orderSizeAndColorSave($event, index)"
                ></custom-multi-select>
             </div>
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            <div class="px-2">
              <input
                  v-model="currentDetail.color"
                  :class="{'is-invalid': !currentDetail.color }"
                  :name="`order_fabric_yarn_fabric_color_${index}`"
                  :placeholder="$t('order.please_enter_color')"
                  :required="false"
                  :title="null"
                  class="form-control form-control-sm"
                  type="text"
              >
            </div>
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            <div class="px-2">
              <input
                  v-model="currentDetail.amount"
                  :class="{'is-invalid': !+currentDetail.amount }"
                  :name="`order_fabric_yarn_fabric_quantity_${index}`"
                  :placeholder="$t('order.please_enter_quantity')"
                  :required="false"
                  :title="null"
                  class="form-control form-control-sm"
                  type="number"
              >
            </div>
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            <div class="px-2">
            <custom-multi-select
                :has-bottom-margin="false"
                :item-per-row="2"
                :max="1"
                :model.sync="currentDetail.amount_unit_id"
                :name="`amount_unit_id_${index}`"
                :not-list="true"
                :options="amountUnitOptions"
                :required="false"
                :title="null"
                size="sm"
            ></custom-multi-select>
            </div>
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            <div class="px-2">
              <input
                  v-model="currentDetail.tolerance_amount"
                  :class="{'is-invalid': !+currentDetail.tolerance_amount || +currentDetail.tolerance_amount > 100 || +currentDetail.tolerance_amount < 0 }"
                  :disabled="true"
                  :name="`order_fabric_yarn_fabric_tolerance_quantity_${index}`"
                  :placeholder="$t('order.please_enter_tolerance_amount')"
                  :required="false"
                  :step="1"
                  :title="null"
                  class="form-control form-control-sm"
                  type="number"
              >
            </div>
          </div>
          <div class="py-2 text-center col-6 top-1-items">
            {{ totalAmount(index) | amountFormat}}
          </div>
          <div class="py-2 text-center col-6 top-1-items">{{ currentDetail.incoming_amount | amountFormat }}</div>
          <div class="py-2 text-center col-6 top-1-items" style="border-right: none !important;"> {{ currentDetail.remaining_amount | amountFormat  }}</div>
        </div>
      </div>

      <!--TOP 3-->
        <currency-table-row
            :currency-options="currencyOptions"
            :currency-options-raw="currencyOptionsRaw"
            :currency-rate-options="currencyRateOptions"
            :expiration-type-options="expirationTypeOptions"
            :model="currentDetail"
            :v-a-t-rate-options="VATRateOptions"
        ></currency-table-row>

        <div class="d-flex mt-5 txt-area">
          <span class="text-md font-weight-bolder mx-2">Açıklama</span>
          <div class="flex-1 px-2 py-3">
            <textarea-input
                :is-valid="!!currentDetail.description"
                :model.sync="currentDetail.description"
                :name="`order_fabric_yarn_fabric_description_${index}`"
                :required="false"
            ></textarea-input>
          </div>
        </div>
      </div>

      <span v-if="index > 0 && !currentDetail.id" class="cursor-pointer" @click="deleteNewDetail(index)" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-20px h-20px d-inline-block mr-3 ' })"></span>

      <span v-if="isValidToSubmit && index == modelProxy.length - 1"
            class="cursor-pointer"
            @click="addNewDetail"
            v-html="getIconByKey('icons.waybill.waybill_entry', {
            class: 'w-50px h50px d-line-block object-fill', })"></span>
      <bottom-buttons
          :emails="[]"
          :is-editing.sync="currentDetail.is_editing"
          :is-valid-to-submit="isValidToSubmit(index)"
          :model="currentDetail"
          :send-mail-url="sendMailUrl(index)"
          :order-id="orderId"
          name="yarn-request"
          @addNewDetail="addNewDetail"
          @onCancelInstruction="onCancelInstruction(currentDetail.id, index)"
          @submit="submitOrderFabricYarn (index)"
      ></bottom-buttons>
    </template>
  </div>
</template>

<script>
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import TextInput from "@/assets/components/inputs/TextInput";
import NumberInput from "@/assets/components/inputs/NumberInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import _ from 'lodash';
import TestMethodRow from "@/view/pages/order/layout/TestMethodRow";
import CurrencyTableRow from "@/view/pages/order/layout/CurrencyTableRow";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import moment from "moment";
import {LARAVEL_DATE_TIME_FORMAT} from "@/core/config/constant";
import {CREATE_ITEM, DELETE_ITEM_BY_ID} from "@/core/services/store/REST.module";
import BottomButtons from "@/view/pages/order/layout/BottomButtons";

export default {
  name: "OrderFabricYarnTypeTable",
  components: {
    BottomButtons,
    ButtonWithIcon,
    CurrencyTableRow,
    TestMethodRow,
    TextareaInput,
    NumberInput,
    TextInput,
    DatePickerInput,
    CustomMultiSelect,
  },
  props: {
    model: {
      required: true
    },
    amountUnitOptions: {
      required: true
    },
    defaultModel: {
      required: true
    },
    orderId: {
      required: true,
      default: null,
    },
    order: {
      required: true,
      default: null
    },
    currentInstructionTypeId: {
      required: true,
    },
    maxTolerance: {
      required: true,
      default: 0,
    },
    currencyOptions: {
      required: true
    },
    currencyOptionsRaw: {
      required: true
    },
    VATRateOptions: {
      required: true,
    },
    supplierCompanyOptions: {
      required: true,
    },
    fabricYarnTypeOptions: {
      required: true,
    },
    testMethodOptions: {
      required: true,
    },
    dispatchLocationOptions: {
      required: false,
    },
    expirationTypeOptions: {
      required: true,
    },
    currencyRateOptions: {
      required: true,
    },
    didChangeProcessingType: {
      required: true,
      type: Boolean,
    },
    productProcesses: {
      required: true,
    },
    orderFabricOptions: {
      required: true,
    },
    fabricProcessingType: {
      required: true,
    },
    orderFabricId: {
      required: true,
    },
    netIncomingFabricAmount: {
      required: true,
    },
    orderSizeAndColorOptions: {
      required: true,
    }
  },
  data() {
    return {
      is_submitting: false
    }
  },
  methods: {
    orderSizeAndColorSave(selectedValue, index) {
      let model = this.modelProxy[index];
      let value = Number(selectedValue);
      let newItem = this.order.order_fabric_requirements.find(x => parseInt(x.order_fabric_id) === parseInt(model.order_fabric_id) && parseInt(x.order_size_and_color_id) === parseInt(model.order_size_and_color_id));
      model.amount = newItem.amount;
      if (this.netIncomingFabricAmount) {
        model.amount -= Number(this.netIncomingFabricAmount.find(item => +item.id === value).amount);
      }
    },
    onCancelInstruction(itemId, index) {
      const self = this;
      this.$store.dispatch(DELETE_ITEM_BY_ID, {
        url: `api/order/${this.orderId}/yarn-request/${itemId}`
      }).then(function (response) {
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_deleted'));
          self.modelProxy.splice(index, 1);
          if (!self.modelProxy.length) {
            self.modelProxy = [self.defaultModel];
          }
        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      });
    },
    addNewDetail() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.is_submitted = false;
      copy.is_editing = false;
      copy.id = null;
      this.sweetAlertLoading();
      this.$emit("getIncomingFabricAmount");
      setTimeout(() => {
        this.modelProxy.push(copy);
        this.sweetAlertClose();
      }, 1000);
    },
    deleteNewDetail(index) {
      this.modelProxy.splice(index, 1);
    },
    fixedData(index) {
      let items = [];
      let self = this;
      let item = this.modelProxy[index];

      item.dispatch_locations = item.dispatch_location_ids.map(location => {
        return {
          id: location,
        }
      });

      item.order_fabric_id = self.orderFabricId;
      item.flow_date = moment(item.flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.end_date = moment(item.end_date).format(LARAVEL_DATE_TIME_FORMAT);

      items.push(item);

      return items;
    },
    submitOrderFabricYarn(index) {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store.dispatch(CREATE_ITEM, {
        url: `api/order/${self.orderId}/yarn-request`,
        contents: {
          order_yarn_requests: self.fixedData(index),
        }
      }).then(response => {
        if (response.status) {
          let detail = self.modelProxy[index];
          let is_editing = !!detail.id;
          let responseData = response.data[0];
          detail.id = responseData.id
          detail.is_submitted = true;
          detail.is_editing = false;
          detail.pdf_link = responseData.pdf_link;
          detail.order_fabric_excel_link = responseData.order_fabric_excel_link
          detail.remaining_amount = responseData.remaining_amount

          self.modelProxy[index] = detail;

          self.sweetAlertSuccess(self.$t(is_editing ? 'general.successfully_updated' : 'general.successfully_created'));
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
        self.sweetAlertClose();
        self.is_submitting = false;
      });
    }
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value);
      }
    },
    totalAmount() {
      return function (index) {
        this.modelProxy[index].total_amount = this.modelProxy[index].amount * (1 + (+this.modelProxy[index].tolerance_amount / 100))
        return (+this.modelProxy[index].total_amount);
      }
    },
    sendMailUrl() {
      try {
        let returnData = null;
        returnData = function (key) {
          const itemId = this.modelProxy[key].id;
          return `api/order/${this.orderId}/yarn-request/${itemId}/share`
        }
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    isValidToSubmit() {
      try {
        let returnData = null;

        returnData = function (key) {
          const current = this.modelProxy[key];
          if (!+current.supplier_company_id) return false;
          if (+current.yarn_type === 0) return false;
          // if (+current.tolerance_amount === 0) return false;
          if (current.dispatch_location_ids.length === 0) return false;
          if (+current.amount === 0) return false;
          // if(+current.total_amount === 0) return false;
          if (current.color === null || current.color === '') return false;
          //if (current.selectedProductProcesses.length === 0) return false;
          if (+current.currency_unit_id === 0) return false;
          if (+current.expiry_id === 0) return false;
          if (+current.unit_price === 0) return false;
          if (+current.vat_rate_id === 0) return false;
          if (!current.description) return false;
          return true;
        }

        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  },
  mounted() {
    let self = this;
    this.modelProxy.forEach(model => {
      model.fabric_supply_type_id = this.fabricProcessingType;
      model.tolerance_amount = this.maxTolerance;
      model.order_fabric_id = this.orderFabricId;
    })
    if(this.$route.query.qr_type=="yarn-request"){
      setTimeout(() => {
        let instructionsId = this.$route.query.data.id
        let element = this.$refs["yarn_"+instructionsId][0]
        if(element){
          element.scrollIntoView({behavior: "smooth"})
        }
      }, 2000);
    }
  },
  watch: {
    modelProxy: {
      handler(newValue, oldValue) {
        let self = this;
        if (newValue) {
          newValue.forEach(model => {
            model.fabric_supply_type_id = this.fabricProcessingType;
            model.tolerance_amount = this.maxTolerance;
            model.order_fabric_id = this.orderFabricId;
          })
        }
      },
      deep: true
    },
    orderFabricId(value) {
      let self = this;
      this.modelProxy.forEach(model => {
        model.order_fabric_id = value;
        let newItem = this.order.order_fabric_requirements.find(x => parseInt(x.order_fabric_id) === parseInt(model.order_fabric_id) && parseInt(x.order_size_and_color_id) === parseInt(model.order_size_and_color_id));
        if (newItem) {
          model.amount = newItem.amount;
          if (self.netIncomingFabricAmount) {
            model.amount -= Number(self.netIncomingFabricAmount.find(item => +item.id === +model.order_size_and_color_id).amount);
          }
        }
      })
    },
    maxTolerance(value) {
      this.modelProxy.forEach(model => {
        model.tolerance_amount = value;
      })
    }
  }
}
</script>

<style lang="scss">
/*Mobile Start*/
@import "@/assets/sass/mobile/order/orderFabricYarnTypeTable.scss";
/*Mobile End*/
</style>