<template>
  <div class="col-12">
    <h5 class="m-2">{{$t('order.order_reports')}}</h5>
    <div class="row mx-3 d-flex justify-content-left">
      <div class="p-2 excel align-items-center box color-1" role="button" @click="selectReport(1)">
        <i class="fa fa-calendar fa-3x m-2 icon"> </i>
        <h6 class="order-inner-title">{{$t('order.planned_report')}}</h6>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderReports",
  data() {
    return {
        reportType:null
    };
  },
  methods: {
    selectReport(value){
        this.reportType=value;
        this.$emit('selectReport',value);
    }
  },
  computed: {},
};
</script>
<style scoped lang="scss">
.color-1 {
  border: 1px solid #d1353a;
  background-color: #da5459;
}
.box {
  position: relative;
  border-radius: 8px;
  color: white;
  display: grid;
  width: 100%;
  text-align: center;
  width: 12.5% !important;
  margin: 0.3rem;
  .icon {
    color: white;
  }
  .remove-button {
    position: absolute;
    color: white;
    top: 0px;
    right: 0px;
  }
}
@media screen and (max-width: 767px) {
  .box {
    width: 47.2% !important;
  }
}

@media screen and (max-width: 350px) {
  .box {
    width: 100% !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box {
    width: 23.99% !important;
  }
}
</style>
