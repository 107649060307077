<template>
  <div class="row order-master">
    <b-modal
      id="instructions-modal"
      ref="instructions-modal"
      hide-footer
      hide-header
      hide-header-close
      scrollable
      size="xl"
    >
      <instructions-modal
        :expenses="expenses"
        :instruction-types="instructionTypes"
        :items="instructions"
        :units="options.unitOptions3"
        @onCloseModal="onCloseModal"
      />
    </b-modal>

    <b-modal
      id="display-pdf-modal"
      ref="display-pdf-modal"
      hide-header
      hide-header-close
      scrollable
      size="xl"
    >
      <div class="row mx-0 py-7">
        <div class="col-12 mx-0 py-2 text-xl">
          <pdf
            v-for="i in numPages"
            :key="i"
            :page="i"
            :src="src"
            style="display: inline-block; width: 100%"
          ></pdf>
        </div>
      </div>
      <template #modal-footer>
        <div class="col-12 text-center">
          <button
            class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
            @click="closeModal('display-pdf-modal')"
          >
            {{ $t("general.close") }}
          </button>

          <button
            class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
            @click="downloadPdf"
          >
            {{ $t("order.download") }}
          </button>
        </div>
      </template>
    </b-modal>

    <b-modal id="display-order-pdf" ref="display-order-pdf" hide-header hide-header-close scrollable size="xl">
          <div id="reportPrint">
            <order-pdf-report
            :lang="orderReportData.lang"
            :data="orderReportData.data"
            >
          </order-pdf-report>
          </div>
          <template #modal-footer>
            <div class="col-12 text-center">
              

              <button
                class="btn btn-sm btn-outline-danger p-2 btn-pill font-weight-bold cursor-pointer mr-5"
                @click="closeModal('display-order-pdf')"
              >
                {{ $t("general.close") }}
              </button>
        
              <button
                class="btn btn-sm btn-outline-info p-2 btn-pill font-weight-bold cursor-pointer mr-5"
                v-print="reportPrintObject"
              >
              {{ $t("general.print") }}
              </button>

              <button
                class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer"
                @click="downloadPdf"
              >
                {{ $t("order.download") }}
              </button>
            </div>
          </template>
    </b-modal>

    <modal
      v-if="form.id"
      :adaptive="true"
      height="auto"
      name="open-qr-code-modal"
      width="750"
    >
      <div class="card card-custom">
        <div class="card-body card-body-scroll d-flex justify-content-center mt-5">
          <q-r-code
            :code="form.code"
            :font-classes="'text-lg font-weight-boldest'"
            :size="300"
            :value="form.pdf_link"
            level="H"
          ></q-r-code>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button
            class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3"
            type="button"
            @click="closeModalByName('open-qr-code-modal')"
          >
            {{ $t("editSample.close") }}
          </button>
        </div>
      </div>
    </modal>

    <div class="col-md-12">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-5 main_wrapper">
            <!--Mobile Card Top Start-->
            <div class="card_top">
              <h4 class="text-center top_order_number">
                {{ form.order_number }}
              </h4>
              <div class="card_sub_top">
                <div
                  v-if="form && 'qr_code_link' in form && form.qr_code_link !== null"
                  class="card_qr"
                >
                  <div class="card_qr_sub">
                    <div
                      v-if="form.id"
                      class="cursor-pointer qr_self"
                      @click="$modal.show('open-qr-code-modal')"
                    >
                      <q-r-code
                        :code="form.code"
                        :size="88"
                        :value="form.pdf_link"
                        level="H"
                      ></q-r-code>
                    </div>
                  </div>
                  <button
                    class="btn btn-xs btn-primary rounded instruct_btn"
                    @click="showInstructions"
                  >
                    {{ $t("general.instructions") }}
                  </button>
                </div>

                <div class="card_img" style="overflow: hidden; border-radius: 10px">
                  <img
                    id="image_holder"
                    :src="
                      form.image_link ? form.image_link : getIconByKey('general.no-image')
                    "
                    class="cursor-pointer"
                    style=""
                    @click="getOriginalImage"
                  />
                  <div
                    class="w-20px h-20px ml-8 plus_icon"
                    @click="$refs.orderImage.click()"
                  >
                    <span
                      v-html="
                        getIconByKey('icons.waybill.waybill_entry', {
                          class:
                            'w-30px h-30px d-inline-block object-fill cursor-pointer',
                        })
                      "
                    >
                    </span>
                  </div>
                </div>

                <input
                  ref="orderImage"
                  class="input-file form-control"
                  style="display: none"
                  type="file"
                  @change="onChangeOrderImage"
                />
              </div>

              <!--Card Footer-->
              <div class="card_footer_wrapper" v-if="id">
                <div class="list_order_card_footer">
                  <span class="detail_item">{{
                    form.user ? capitalizeStr(form.user.name) : ""
                  }}</span>
                  <span class="detail_item">{{
                    form.company ? capitalizeStr(form.company.name) : ""
                  }}</span>
                  <span class="detail_item">{{ form.amount }}</span>
                  <span class="detail_item">{{ form.model }}</span>
                </div>
                <p class="description_p">{{ form.description }}</p>
              </div>

              <div class="order_detail" v-if="id">
                <div class="order-col">
                  <!--Imalatçı-->
                  <div class="order-col-row">
                    <div class="order-col-row-subs">
                      <span class="detail_label">İmalatçı</span>
                    </div>
                    <div class="order-col-row-subs" style="position: relative">
                      <custom-multi-select
                        :disabled="!form.esc_table_id"
                        :is-inline="false"
                        :item-per-row="1"
                        :max="1"
                        :model.sync="form.company_id"
                        :not-list="true"
                        :options="options.customerOptions"
                        :title="''"
                        class="detail_customer_input"
                        input-max-width="100px"
                        input-width="80px"
                        name="order_company_id"
                        style="position: absolute; left: -2%; top: 0"
                        @save="onCompanyChange"
                      ></custom-multi-select>
                    </div>
                  </div>
                  <!--Değ Tar-->
                  <div class="order-col-row">
                    <div class="order-col-row-subs">
                      <span class="detail_label">Değ.Tar.</span>
                    </div>
                    <div class="order-col-row-subs">
                      <span class="detail_label_soft">{{ getExportDate() }}</span>
                    </div>
                  </div>
                </div>
                <div class="order-col">
                  <div class="order-col-row">
                    <div class="order-col-row-subs">
                      <span class="detail_label">Ihr.Tar.</span>
                    </div>
                    <div class="order-col-row-subs">
                      <span class="detail_label_soft" style="padding-left: 3px">{{
                        getExportDate()
                      }}</span>
                    </div>
                  </div>
                  <div class="order-col-row">
                    <div class="order-col-row-subs">
                      <span class="detail_label">Ihr.Tar.2</span>
                    </div>
                    <div class="order-col-row-subs">
                      <span class="detail_label_soft">{{ getExportDate2() }}</span>
                    </div>
                  </div>
                </div>
                <div class="order-col">
                  <div class="order-col-row">
                    <div class="order-col-row-subs">
                      <span class="detail_label">Kumaş Termin 1</span>
                    </div>
                    <div class="order-col-row-subs">
                      <span class="detail_label_soft" style="padding-left: 3px">{{
                        getOrderFabrics()
                      }}</span>
                    </div>
                  </div>
                  <div class="order-col-row">
                    <div class="order-col-row-subs">
                      <span class="detail_label">Kumaş Termin 2</span>
                    </div>
                    <div class="order-col-row-subs">
                      <span class="detail_label_soft">{{ getOrderFabrics2() }}</span>
                    </div>
                  </div>
                </div>
                <div class="order-col">
                  <div class="order-col-row">
                    <div class="order-col-row-subs">
                      <span class="detail_label"> {{ $t("order.esc_no") }}:* </span>
                    </div>
                    <div class="order-col-row-subs" style="position: relative">
                      <div
                        class="detail_label_soft esc-div"
                        style="position: absolute; left: -3.5%; top: 0"
                      >
                        <button-with-icon
                          :icon-name="null"
                          :text="form.esc ? form.esc.esc_number : $t('order.select')"
                          @onClick="redirectToSelectESC"
                        >
                        </button-with-icon>
                      </div>
                    </div>
                  </div>

                  <div class="order-col-row">
                    <div class="order-col-row-subs">
                      <span class="detail_label"> {{ $t("order.po_no") }}: </span>
                    </div>
                    <div class="order-col-row-subs po-div">
                      <text-input
                        :disabled="form.esc_table_id === null"
                        :helper-text="$t('order.please_enter_manually')"
                        :is-inline="true"
                        :model.sync="form.po_number"
                        :title="''"
                        class="font-weight-bolder"
                        input-width="50px"
                        name="order_po_no"
                      ></text-input>
                    </div>
                  </div>
                </div>
                <div v-if="type === 2" class="order-col-row">
                  <div class="order-col-row-subs">
                    <span class="detail_label"
                      >{{ $t("order.collection_number") }} :</span
                    >
                  </div>
                  <div class="select-collection-number">
                    <button-with-icon
                      :icon-name="null"
                      :text="
                        form.sample ? form.sample.collection_number : $t('order.select')
                      "
                      @onClick="redirectToSelectSample"
                    >
                    </button-with-icon>
                  </div>
                </div>
                <!-- <div class="order-col-row test">
                  <div class="order-col-row-subs">
                    <span class="detail_label"> {{ $t("order.total") }}: </span>
                  </div>
                  <div class="order-col-row-subs my-order-amount">
                    <number-input
                      :helper-text="$t('order.please_enter_manually')"
                      :is-inline="false"
                      :model.sync="form.amount"
                      :required="true"
                      input-width="125px"
                      label-class="font-weight-boldest py-0 text-xl"
                      name="order_total"
                    ></number-input>
                  </div>
                </div> -->
                <!-- <div class="detail_itm mb-3">
                  <custom-multi-select
                    :is-inline="false"
                    :item-per-row="1"
                    :max="1"
                    :model.sync="form.status_id"
                    :not-list="true"
                    :options="options.orderStatusOptions"
                    :placeholder="$t('order.please_select_status')"
                    :title="$t('order.order_status') + ':'"
                    class="font-weight-bolder"
                    name="order_status_id"
                  ></custom-multi-select>
                </div> -->
                <!-- <div class="detail_itm mb-3">
                  <number-input
                    :helper-text="$t('order.please_enter_manually')"
                    :is-inline="false"
                    :model.sync="form.amount"
                    :required="true"
                    :title="$t('order.total')"
                    input-width="125px"
                    label-class="font-weight-boldest py-0 text-xl"
                    name="order_total"
                  ></number-input>
                </div> -->
                <!--Model No-->
                <!-- <div class="detail_itm mb-3">
                  <text-input
                    :disabled="form.esc_table_id === null"
                    :helper-text="$t('please_enter_manually')"
                    :is-inline="false"
                    :model.sync="form.model"
                    :placeholder="''"
                    :title="$t('order.model_no') + ':'"
                    class="font-weight-bolder"
                    input-width="125px"
                    name="order_model_no"
                  ></text-input>
                </div> -->
                <!--Sip No-->
                <!-- <div class="detail_itm mb-3 test">
                  <span class="font-weight-boldest text-xl d-block mr-2"
                    >{{ $t("order.order_no") }} :
                  </span>
                  <span class="d-block ml-2">
                    {{ form.order_number ? form.order_number : " - " }}</span
                  >
                </div> -->
              </div>

              <!--KOLEKSIYON NUMUNESINDEN START-->
              <div class="collection-sample-master col-12 m-0 p-0" v-if="!id">
                <div class="col-12 row m-0 p-0 row-1 py-3">
                  <div class="col-6 m-0 p-0 pl-1">
                    <div class="col-12 row m-0 p-0 d-flex align-items-center">
                      <span class="detail_label">{{ $t("order.esc_no") }}:*</span>
                      <button-with-icon
                        :icon-name="null"
                        :text="form.esc ? form.esc.esc_number : 'Seçiniz'"
                        @onClick="redirectToSelectESC"
                      >
                      </button-with-icon>
                    </div>
                  </div>
                  <div class="col-6 m-0 p-0 pl-1 d-flex align-items-center">
                    <div class="col-12 row m-0 p-0 d-flex align-items-center">
                      <span class="detail_label">PO No:</span>
                      <text-input
                        :disabled="form.esc_table_id === null"
                        :model.sync="form.po_number"
                        :title="''"
                        :placeholder="'Seçiniz'"
                        name="order_po_no"
                      >
                      </text-input>
                    </div>
                  </div>
                </div>
                <div class="col-6 m-0 row p-0 row-2-if py-3"v-if="type === 2">
                    <div class="col-12 row m-0 p-0">
                      <span class="detail_label"
                        >{{ $t("order.collection_number") }} :</span
                      >
                      <button-with-icon
                        :icon-name="null"
                        :text="
                          form.sample ? form.sample.collection_number : $t('order.select')
                        "
                        @onClick="redirectToSelectSample"
                      >
                      </button-with-icon>
                    </div>
                </div>
                <div class="col-6 m-0 p-0 row m-0 p-0 total-div">
                    <div class="col-6 row m-0 p-0 total-div">
                      <span class="detail_label">{{ $t("order.total") }}:</span>
                      <number-input
                        :is-inline="false"
                        :model.sync="form.amount"
                        :required="true"
                        input-width="80px"
                        :placeholder="'Seçiniz'"
                        label-class="font-weight-boldest py-0 text-xl"
                        name="order_total"
                      >
                      </number-input>
                    </div>
                </div>
                <div class="col-12 m-0 p-0 row-2 py-3 d-flex align-items-center">
                  <div class="col-6 pl-1 m-0 p-0 d-flex align-items-center">
                    <div class="col-12 row m-0 p-0">
                      <custom-multi-select
                        :disabled="!form.esc_table_id"
                        :is-inline="false"
                        :item-per-row="1"
                        :max="1"
                        :model.sync="form.company_id"
                        :not-list="true"
                        :options="options.customerOptions"
                        :title="$t('order.customer') + ':'"
                        class="font-weight-bolder text-sm"
                        name="order_company_id"
                        @save="onCompanyChange"
                      ></custom-multi-select>
                    </div>
                  </div>
                  <div class="col-6 m-0 p-0 pl-1 m-0 p-0 d-flex align-items-center">
                    <div class="col-12 row m-0 p-0 cms-extra">
                      <custom-multi-select
                        :is-inline="false"
                        :item-per-row="1"
                        :max="1"
                        :model.sync="form.status_id"
                        :not-list="true"
                        :options="options.orderStatusOptions"
                        :placeholder="$t('order.please_select_status')"
                        :title="$t('order.order_status') + ':'"
                        class="font-weight-bolder"
                        name="order_status_id"
                      >
                      </custom-multi-select>
                    </div>
                  </div>
                </div>
                <div class="col-12 m-0 p-0 row-3 py-3 d-flex align-items-center">
                  <div class="col-6 m-0 p-0 pl-1">
                    <div class="col-12 row m-0 p-0">
                      <span class="detail_label">{{ $t("order.order_no") }}</span>
                      <span class="order-no-span">{{
                        form.order_number ? form.order_number : " - "
                      }}</span>
                    </div>
                  </div>
                  <div class="col-6 m-0 p-0 pl-1 txt-extra">
                    <div class="col-12 row m-0 p-0">
                      <span class="detail_label">{{ $t("order.model_no") }}:</span>
                      <text-input
                        :disabled="form.esc_table_id === null"
                        :helper-text="$t('please_enter_manually')"
                        :is-inline="false"
                        :model.sync="form.model"
                        :placeholder="'Seçiniz'"
                        :title="$t('order.model_no') + ':'"
                        class="font-weight-bolder"
                        name="order_model_no"
                      >
                      </text-input>
                    </div>
                  </div>
                </div>
              </div>
              <!--KOLEKSIYON NUMUNESINDEN END-->
            </div>
            <div class="card_menu">
              <div class="order_menu_d">
                <div
                  :class="{ order_menu_item_d_active: currentActiveTab === 4 }"
                  class="order_menu_item_d"
                  @click="currentActiveTab = 4"
                >
                  {{ $t("csr.self").toUpperCase() }}
                </div>

                <div
                  :class="{ order_menu_item_d_active: currentActiveTab === 1 }"
                  class="order_menu_item_d"
                  @click="isValidChangeGeneralTab()?currentActiveTab = 1:''"
                >
                  {{ $t("order.general").toUpperCase() }}
                </div>

                <div
                  :class="{ order_menu_item_d_active: currentActiveTab === 2 }"
                  class="order_menu_item_d"
                  @click="form.is_submitted ? (currentActiveTab = 2) : ''"
                >
                  {{ $t("order.suppliers_and_manufacturer").toUpperCase() }}
                </div>

                <div
                  :class="{ order_menu_item_d_active: currentActiveTab === 3 }"
                  class="order_menu_item_d"
                  @click="form.is_submitted ? (currentActiveTab = 3) : ''"
                >
                  {{ $t("order.report").toUpperCase() }}
                </div>
              </div>
            </div>
            <!--Bottom-->
            <div
              v-if="currentActiveTab === 4 && isValidToContinueOnGeneral"
              class="row py-7 mt-7 px-3 items-start"
              style="
                border: 1px solid #0b5a85;
                border-radius: 12px;
                background-color: #f3f6f9;
                min-height: 50vh !important;
              "
            >
              <div class="p-2 d-flex">
                <div class="flex-grow-1 row p-3 join-life">
                  <div class="col-12">
                    <custom-multi-select
                      :helperText="$t('general.please_select')"
                      :is-inline="false"
                      :model.sync="csr.join_life"
                      :options="joinLifeOptions"
                      :required="true"
                      :show-invalid="false"
                      :title="$t('csr.join_life_spec')"
                      class="font-weight-bolder"
                      input-max-width="100%"
                      input-width="100%"
                      name="join_life"
                      width-class="100%"
                    />
                  </div>

                  <div class="col-12">
                    <custom-multi-select
                      :helperText="$t('general.please_select')"
                      :is-inline="false"
                      :max="1"
                      :model.sync="csr.disney_licences.disney_id"
                      :not-list="true"
                      :options="yesNoOptions"
                      :required="true"
                      :show-invalid="false"
                      :title="$t('csr.disney')"
                      class="font-weight-bolder"
                      input-max-width="100%"
                      input-width="100%"
                      name="disney"
                      width-class="100%"
                    />
                  </div>
                  <div class="col-12">
                    <custom-multi-select
                      :helperText="$t('general.please_select')"
                      :is-inline="false"
                      :max="1"
                      :model.sync="csr.disney_licences.license_id"
                      :not-list="true"
                      :options="disneyLicences"
                      :required="false"
                      :show-invalid="false"
                      :title="$t('csr.licence')"
                      class="font-weight-bolder"
                      input-max-width="100%"
                      input-width="100%"
                      name="licence"
                      width-class="100%"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 text-center">
                <div class="col flex-grow-1 p-3">
                  <div class="flex-grow-1 justify-content-center row">
                    <div class="btn-w-icn d-flex justify-content-center mt-3">
                      <button-with-icon
                        :disabled="
                          !(
                            isUserGranted('Order', ['create', 'update'], false) ||
                            isUserGranted('Csr', ['create', 'update'], false)
                          )
                        "
                        :size="'lg'"
                        :text="$t('general.save').toUpperCase()"
                        icon-name="icons.waybill.approve"
                        @onClick="submitOrderCsr"
                        class="detail-bottom-btn"
                      ></button-with-icon>
                      <span
                        class="px-3 green-border-1px rounded-full d-flex justify-content-center items-center w-125px mx-2 cursor-pointer detail-bottom-btn"
                        @click="$router.push({ name: 'order.index' })"
                      >
                        <span
                          v-html="
                            getIconByKey('icons.waybill.give_up', {
                              class:
                                'detail-close-btn mr-2 w-40px h-40px object-cover d-inline-block opacity-75',
                            })
                          "
                        >
                        </span>
                        {{ $t("general.give_up").toUpperCase() }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--GENEL-->
            <div
              v-if="currentActiveTab === 1 && isValidToContinueOnGeneral"
              class="row py-7 px-3 items-start"
            >
              <!--Ad ve Sipariş tarihi-->
              <div class="genel-divider">
                <div class="genel-sub-divider">
                  <text-input
                    v-if="form && form.user && form.user.name"
                    :disabled="true"
                    :is-inline="false"
                    :model="form.user.name"
                    :required="false"
                    :title="$t('order.customer_representative')"
                    class="font-weight-boldest"
                  ></text-input>
                  <text-input
                    v-else
                    :disabled="true"
                    :is-inline="false"
                    :model="authUser.name"
                    :required="false"
                    :title="$t('order.customer_representative')"
                    class="font-weight-boldest"
                  ></text-input>
                </div>
                <div class="col-12 text-right" v-if="id!=null">
                <button
                    class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer mr-2" v-if="!isDisableCalculateCosts"
                    @click="calculateCost">
                  {{ $t('order.calculate_cost') }}
                </button>
              </div>

                <div class="genel-sub-divider">
                  <date-picker-input
                    :disabled="true"
                    :is-inline="false"
                    :model="orderDate"
                    :required="false"
                    :title="$t('order.order_date')"
                    class="font-weight-boldest"
                  ></date-picker-input>
                </div>
              </div>

              <!--Satış ücreti-->
              <div class="genel-divider">
                <div class="genel-sub-divider"  v-if="isVisibleSalePrice">
                  <number-input
                    :helper-text="$t('order.please_enter_sale_price')"
                    :is-inline="false"
                    :model.sync="form.sale_price"
                    :required="false"
                    :step="0.01"
                    :title="$t('order.sale_price')"
                    class="font-weight-boldest"
                    icon-name="icons.sample_instruction.currency"
                  ></number-input>
                </div>
                <div class="genel-sub-divider">
                  <custom-multi-select
                    :is-inline="false"
                    :max="1"
                    :model.sync="form.currency_unit_id"
                    :not-list="true"
                    :options="options.currencyOptions"
                    :placeholder="$t('order.unit')"
                    :required="false"
                    :title="$t('order.unit')"
                    name="order_unit_id"
                    class="font-weight-boldest my-custom-input"
                  ></custom-multi-select>
                </div>
              </div>

              <!--QC & Koli-->
              <div class="genel-divider">
                <div class="genel-sub-divider">
                  <custom-multi-select
                    :is-inline="false"
                    :is-right="true"
                    :item-per-row="1"
                    :max="1"
                    :model.sync="form.qc_user_id"
                    :not-list="true"
                    :options="options.qcUserOptions"
                    :required="false"
                    :title="$t('order.qc').toUpperCase()"
                    class="font-weight-boldest"
                    input-max-width="200px"
                    input-width="150px"
                    name="order_qc_staff"
                    width="150px"
                  ></custom-multi-select>
                </div>
                <div class="genel-sub-divider">
                  <number-input
                    :helper-text="$t('order.please_enter')"
                    :is-inline="false"
                    :model.sync="form.amount_per_parcel"
                    :required="false"
                    :step="1"
                    :title="$t('order.amount_per_parcel')"
                    class="font-weight-boldest"
                  ></number-input>
                </div>
              </div>

              <!--Ihracat Tarihi-->
              <span class="font-weight-boldest mb-2">{{ $t("order.export_date") }}</span>
              <div
                v-for="(export_date, index) in form.order_export_dates"
                style="position: relative"
                class="genel-divider"
                :key="index"
              >
                <div class="genel-sub-divider">
                  <date-picker-input
                    :is-inline="false"
                    :model.sync="export_date.export_date"
                    :title="$t('order.date')"
                  ></date-picker-input>
                </div>
                <div class="genel-sub-divider">
                  <number-input
                    :is-inline="false"
                    :is-valid="isOrderExportDateAmountValid"
                    :model.sync="export_date.amount"
                    :placeholder="'Miktar'"
                    :title="$t('order.amount')"
                  ></number-input>
                </div>
                <div
                  v-if="index > 0"
                  class="p-0 m-0 absolute"
                  style="bottom: 2%; right: 1%"
                >
                  <span
                    class="cursor-pointer d-inline"
                    @click="deleteExportDateInfo(index)"
                    v-html="
                      getIconByKey('icons.sample.delete_red', {
                        class: 'w-20px h-20px d-inline-block object-cover',
                      })
                    "
                  ></span>
                </div>
                <div class="col-1 pb-3 d-none">
                  <span
                    v-html="
                      getIconByKey('icons.sample.enter-product-name', {
                        class: 'w-15px h-15px  d-inline-block object-fill cursor-pointer',
                      })
                    "
                  >
                  </span>
                </div>
                <span
                  style="position: absolute; top: 2%; right: 1%"
                  class="cursor-pointer"
                  @click="addExportDateInfo"
                  v-html="
                    getIconByKey('icons.model_house.add', {
                      class: 'w-20px h-20px d-inline-block object-cover',
                    })
                  "
                ></span>
              </div>

              <!--Açıklama-->
              <div class="font-weight-boldest mb-2">
                {{ $t("order.description") }}
              </div>
              <div class="genel-vertical-divider">
                <div class="genel-vertical-sub-divider">
                  <textarea
                    id=""
                    v-model="form.description"
                    :placeholder="$t('order.please_enter_description')"
                    class="w-100 outline-none"
                    name="order_description"
                    rows="3"
                  >
                  </textarea>
                  <div class="w-100 text-right">
                    {{ $t("order.max") }}
                    {{ form.description !== null ? 300 - form.description.length : 300 }}
                  </div>
                </div>
              </div>

              <!--Talimat Açıklamaları-->
              <div class="font-weight-boldest mb-2">
                {{ $t("order.description_for_instructions") }}
              </div>
              <div class="genel-vertical-divider">
                <div class="genel-vertical-sub-divider">
                  <textarea
                    v-model="form.instruction_description"
                    :placeholder="$t('order.please_enter_description')"
                    class="w-100 outline-none"
                    name="instruction_description"
                    rows="3"
                  >
                  </textarea>
                </div>
              </div>

              <!--Additional Fields Starts From Here-->
              <div v-if="isValidToContinueOnFabric" class="additional-global">
                <div class="d-flex justify-content-between font-weight-boldest mb-2">
                  <span>{{ $t("order.fabric") }}</span>
                </div>
                <!--Termin Başlangıç-->
                <div class="termin-master">
                  <span
                    class="termin-add cursor-pointer"
                    @click="addNewOrderFabric"
                    v-html="getIconByKey('icons.model_house.add', {})"
                  ></span>
                  <div
                    v-for="(order_fabric, index) in form.order_fabrics"
                    class="termin-divider"
                    :key="index"
                  >
                    <div class="termin-genel-divider">
                      <div class="termin-genel-sub-divider">
                        <date-picker-input
                          :is-inline="true"
                          :model.sync="order_fabric.deadline_start"
                          :title="$t('order.deadline_start')"
                        ></date-picker-input>
                      </div>
                      <div class="termin-genel-sub-divider">
                        <date-picker-input
                          :is-inline="true"
                          :model.sync="order_fabric.deadline_end"
                          :title="$t('order.deadline_end')"
                        ></date-picker-input>
                      </div>
                    </div>
                    <!--Kompozisyon-->
                    <div class="w-100 composition-div">
                      <custom-multi-select
                        :height="'200px'"
                        :is-inline="true"
                        :item-per-row="1"
                        :max-show="1"
                        :model.sync="order_fabric.selected_order_fabric_compositions"
                        :name="`order_selected_order_fabric_compositions-${index}`"
                        :options="options.fabricCompositionOptions"
                        :required="false"
                        :title="$t('order.compositions')"
                        @save="onOrderFabricCompositionChange"
                      ></custom-multi-select>
                    </div>

                    <div class="col-1 pb-3 d-none"></div>
                    <template v-if="order_fabric.order_fabric_compositions.length > 0">
                      <div class="col-sm-12 p-0 m-0" style="overflow-x: auto">
                        <div class="col-12 row mx-0">
                          <div class="flex-grow-1 mx-0 pb-0">
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th
                                      v-for="(
                                        fabric_composition, key, index
                                      ) in order_fabric.order_fabric_compositions"
                                      :key="index"
                                      class="text-center"
                                      style="
                                        border-top: 1px solid #78cdea;
                                        border-bottom: 1px solid #78cdea;
                                      "
                                    >
                                      <div
                                        class="d-flex justify-content-center items-center"
                                      >
                                        <span
                                          :for="`fabric_composition_amount-${key}`"
                                          class="d-inline-block px-5"
                                        >
                                          {{
                                            options.fabricCompositionOptions[
                                              fabric_composition.fabric_composition_id
                                            ]
                                          }}</span
                                        >
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="h-100">
                                    <td
                                      v-for="(
                                        fabric_composition, key, index
                                      ) in order_fabric.order_fabric_compositions"
                                      :key="index"
                                      class=""
                                      style="border-right: 1px solid #78cdea"
                                    >
                                      <div class="d-flex justify-content-center">
                                        <input
                                          :id="`fabric_composition_amount-${key}`"
                                          v-model="fabric_composition.amount"
                                          :class="{
                                            'is-invalid':
                                              Number(form.amount) < sizeAndColorTotal,
                                          }"
                                          class="form-control form-control-sm"
                                          step="1"
                                          type="number"
                                          style="
                                            border-color: #78cdea;
                                            background-color: #f3f6f9;
                                          "
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <template v-if="isValidToContinueOnFabric">
                <div class="cins-master">
                  <table class="table w-100 mb-0">
                    <thead class="w-100">
                      <tr>
                        <th class="cins-th">
                          <div class="d-flex justify-content-center items-center">
                            {{ $t("order.genus") }}
                            <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th class="cins-th">
                          <div class="d-flex justify-content-center items-center">
                            {{ $t("order.width") }}(cm)
                            <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th class="cins-th">
                          <div class="d-flex justify-content-center items-center">
                            {{ $t("order.weight") }}
                            <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th class="cins-th">
                          <div class="d-flex justify-content-center items-center">
                            {{ $t("order.unit") }}
                            <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th class="cins-th">
                          <div class="d-flex justify-content-center items-center">
                            {{ $t("order.pastal") }}
                            <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th class="cins-th">
                          <div class="d-flex justify-content-center items-center">
                            <span>Birim Fiyat</span>
                            <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th class="cins-th">
                          <div class="d-flex justify-content-center items-center">
                            {{ $t("order.planned") }}
                            <info-tooltip
                              :helper-text="$t('order.please_enter_manually')"
                            ></info-tooltip>
                          </div>
                        </th>
                        <th class="cins-th">
                          <div class="d-flex justify-content-center items-center">
                            {{ $t("order.actual_person") }}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(order_fabric, index) in form.order_fabrics"
                        :key="index"
                      >
                        <td class="items-start">
                          <input
                            v-model="order_fabric.type"
                            class="form-control form-control-sm d-inline-block"
                            type="text"
                            style="background-color: #f3f6f9; border-color: #78cdea"
                          />
                        </td>
                        <td class="">
                          <input
                            v-model="order_fabric.width"
                            class="form-control form-control-sm d-inline-block"
                            step="1"
                            type="number"
                            style="background-color: #f3f6f9; border-color: #78cdea"
                          />
                        </td>
                        <td class="">
                          <input
                            v-model="order_fabric.weight"
                            class="form-control form-control-sm"
                            step="1"
                            type="number"
                            style="background-color: #f3f6f9; border-color: #78cdea"
                          />
                        </td>
                        <td class="">
                          <custom-multi-select
                            :input-width="`auto`"
                            :is-inline="false"
                            :item-per-row="1"
                            :max="1"
                            :model.sync="order_fabric.unit_id"
                            :name="`order_fabric_unit_id_${index}`"
                            :not-list="true"
                            :options="options.unitOptions2"
                            :placeholder="$t('order.please_enter_manually')"
                            :required="false"
                            :title="null"
                            style="margin-top: 6px !important"
                          ></custom-multi-select>
                        </td>
                        <td class="">
                          <input
                            :disabled="isDisablePriceEdit"
                            v-model="order_fabric.pastal"
                            class="form-control form-control-sm"
                            type="number"
                            style="background-color: #f3f6f9; border-color: #78cdea"
                          />
                        </td>
                        <td class="">
                          <input
                            :disabled="isDisablePriceEdit"
                            v-model="order_fabric.unit_price"
                            class="form-control form-control-sm"
                            type="number"
                            style="background-color: #f3f6f9; border-color: #78cdea"
                          />
                        </td>
                        <td class="">
                          <input
                            :disabled="true"
                            :value="
                              Number(order_fabric.pastal) *
                              Number(order_fabric.unit_price)
                            "
                            class="form-control form-control-sm"
                            step="0.01"
                            type="number"
                            style="background-color: #f3f6f9; border-color: #78cdea"
                          />
                        </td>
                        <td
                          class="d-flex"
                          style="
                            border-right: none !important;
                            align-items: center;
                            justify-content: center;
                          "
                        >
                          <span class="mr-2"> {{ order_fabric.actual }} </span>
                          <a
                            class=""
                            style="margin-right: 4px; margin-top: 4px"
                            href="#"
                            @click.prevent="
                              showWaybills(order_fabric.id, 1, order_fabric.type)
                            "
                          >
                            <i
                              v-if="id"
                              class="fa fa-eye"
                              style="width: 20px; height: 20px"
                            ></i>
                          </a>
                          <div
                            v-if="!order_fabric.actual"
                            class="w40 items-end d-flex justify-content-end"
                          >
                            <span
                              class="cursor-pointer d-inline"
                              @click="deleteNewOrderFabric(index)"
                              v-html="
                                getIconByKey('icons.sample.delete_red', {
                                  class: 'w-20px h-20px d-inline-block object-cover',
                                })
                              "
                            ></span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="w-100 row justify-content-end p-0 m-0">
                  <div
                    class="mx-0 mt-3 px-2 text-sm font-weight-boldest py-4"
                    style="
                      background-color: #f3f6f9;
                      border: 1px solid #0b5a85;
                      border-radius: 12px;
                      color: #101010;
                    "
                  >
                    <span class="">{{ $t("order.total").toUpperCase() }} : </span>
                    <span>{{ fabricTotalAmount }}</span>
                  </div>
                </div>
              </template>

              <!--MASRAFLAR...-->
              <template v-if="isValidToContinueOnExpenses">
                <div class="w-100 py-5 mt-2 items-center masraf-master">
                  <div class="col-sm-12 row mx-0 justify-content-between masraf-body">
                    <div class="w-100 mx-0 mb-5 mt-3" style="position: relative">
                      <div
                        class="text-xs font-weight-bold text-center masraf-m-items"
                        style="position: absolute"
                      >
                        <span class="masraf-top-menu-items">Masraflar</span>
                      </div>
                      <div
                        class="text-xs font-weight-bold text-center masraf-m-items"
                        style="position: absolute; left: 17%"
                      >
                        <span class="masraf-top-menu-items">Planlanan T.</span>
                      </div>
                      <div
                        class="text-xs font-weight-bold text-center masraf-m-items"
                        style="position: absolute; left: 37%"
                      >
                        <span class="masraf-top-menu-items">Gerçekleşen T.</span>
                      </div>
                      <div
                        class="text-xs font-weight-bold text-center masraf-m-items"
                        style="position: absolute; left: 61%"
                      >
                        <span class="masraf-top-menu-items">{{
                          $t("order.amount")
                        }}</span>
                      </div>
                      <div
                        class="text-xs font-weight-bold text-center masraf-m-items"
                        style="position: absolute; left: 82%"
                      >
                        <span class="masraf-top-menu-items">{{ $t("order.unit") }}</span>
                      </div>
                    </div>
                    <div style="border-top: 1px solid #0b5a85; width: 100%"></div>
                    <!--Masraf Table Content-->
                    <template v-for="(expense, index) in form.order_expenses">
                      <div
                        :key="index"
                        v-if="expense.is_default === 1"
                        class="col-12 row mx-0 justify-content-between py-2"
                        style="border-bottom: 1px solid rgba(100, 100, 100, 0.25)"
                      >
                        <div
                          class="text-xs font-weight-bold"
                          style="
                            /* border:1px solid green; */
                            display: flex;
                            flex-wrap: wrap;
                            max-width: 33px !important;
                            min-width: 33px !important;
                          "
                        >
                          <i
                            class="fa fa-eye"
                            role="button"
                            @click.prevent="
                              showWaybills(expense.expense.id, (type = 2), expense.name)
                            "
                            style="width: 75%"
                          >
                          </i>
                          <span style="font-size: 8px !important">{{
                            expense.name
                          }}</span>
                        </div>
                        <div
                          class="text-xs font-weight-bold"
                          style="/* border:1px solid red; */"
                        >
                          <input
                            :disabled="isDisablePriceEdit"
                            v-model="expense.planned"
                            :placeholder="$t('order.please_enter_manually')"
                            class="form-control form-control-xs font-weight-bold masraf-txt-input"
                            type="text"
                          />
                        </div>
                        <div
                          class="text-xs font-weight-bold"
                          style="/* border: 1px solid blue; */"
                        >
                          <input
                            v-model="expense.actual"
                            :class="{
                              'text-primary':
                                Number(expense.actual) <= Number(expense.planned),
                              'text-danger':
                                Number(expense.actual) > Number(expense.planned),
                            }"
                            :disabled="true"
                            :placeholder="''"
                            class="form-control form-control-xs font-weight-bold masraf-txt-input"
                            type="text"
                          />
                        </div>
                        <div
                          class="text-xs font-weight-bold"
                          style="/* border: 1px solid violet; */"
                        >
                          <input
                            v-model="expense.amount"
                            :placeholder="$t('order.please_enter_manually')"
                            class="form-control form-control-xs text-center"
                            style="
                              width: 40px !important;
                              font-size: 9px !important;
                              border-color: #78cdea;
                              background-color: #f3f6f9;
                            "
                            type="text"
                          />
                        </div>
                        <div
                          class="text-xs font-weight-bold"
                          style="/* border: 1px solid yellow; */"
                        >
                          <select
                            v-model="expense.unit_id"
                            class="form-control form-control-xs"
                            style="
                              width: 55px !important;
                              border-color: #78cdea;
                              background-color: #f3f6f9;
                              font-size: 9px !important;
                            "
                            name=""
                          >
                            <option value="">
                              {{ $t("general.please_select_option") }}
                            </option>
                            <option
                              v-for="(unit, unit_key) in options.unitOptions"
                              :value="unit_key"
                              :key="unit_key"
                              style="background: #f3f6f9 !important"
                            >
                              {{ unit }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </template>
                  </div>

                  <!--Selected Masraf Table-->
                  <div
                    class="col-sm-12 row mx-0 mt-5 justify-content-between masraf-body relative"
                  >
                    <!--Selected Masraf Plus Icon-->
                    <span class="d-inline absolute" style="right: 0%; top: 0%">
                      <CustomMultiSelect
                        :is-right="true"
                        :model.sync="form.selected_expenses"
                        :name="$t('esc.new_please_select')"
                        :options="options.expensesOptions"
                        :required="true"
                        :title="null"
                        class="w-100"
                        object-key="name"
                        width="300px"
                        @save="onExpensesSave"
                      >
                        <template slot="inputDesign">
                          <span
                            class="items-center"
                            v-html="
                              getIconByKey('icons.waybill.waybill_entry', {
                                class:
                                  'w-20px h-20px  object-fill flex-grow-1 cursor-pointer',
                              })
                            "
                          >
                          </span>
                        </template>
                      </CustomMultiSelect>
                    </span>
                    <!--Selected Masraf Headers-->
                    <div class="col-sm-12 row mb-5 mt-3" style="position: relative">
                      <div
                        class="text-xs font-weight-bold text-center masraf-m-items-s"
                        style="position: absolute"
                      >
                        <span class="masraf-top-menu-items">Masraflar</span>
                      </div>
                      <div
                        class="text-xs font-weight-bold text-center masraf-m-items-s"
                        style="position: absolute; left: 22%"
                      >
                        <span class="masraf-top-menu-items">Planlanan T.</span>
                      </div>
                      <div
                        class="text-xs font-weight-bold text-center masraf-m-items-s"
                        style="position: absolute; left: 42%"
                      >
                        <span class="masraf-top-menu-items">Gerçekleşen T.</span>
                      </div>
                      <div
                        class="text-xs font-weight-bold text-center masraf-m-items-s"
                        style="position: absolute; left: 65%"
                      >
                        <span class="masraf-top-menu-items">{{
                          $t("order.amount")
                        }}</span>
                      </div>
                      <div
                        class="text-xs font-weight-bold text-center masraf-m-items-s"
                        style="position: absolute; left: 86%"
                      >
                        <span class="masraf-top-menu-items">{{ $t("order.unit") }} </span>
                      </div>
                    </div>
                    <div style="border-top: 1px solid #0b5a85; width: 100%"></div>
                    <!--Selected Masraf Content-->
                    <template v-for="(expense, index) in form.order_expenses">
                      <div
                        :key="index"
                        v-if="expense.is_default === 0"
                        class="col-12 row mx-0 justify-content-between py-2"
                        style="border-bottom: 1px solid rgba(100, 100, 100, 0.25)"
                      >
                        <div
                          class="text-xs font-weight-bold"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            max-width: 33px !important;
                            min-width: 33px !important;
                          "
                        >
                          <i
                            class="fa fa-eye"
                            role="button"
                            @click.prevent="
                              showWaybills(expense.expense.id, (type = 2), expense.name)
                            "
                            style="width: 75%"
                          >
                          </i>
                          <span>{{ expense.name }}</span>
                        </div>
                        <div class="text-xs font-weight-bold" style="">
                          <input
                            :disabled="isDisablePriceEdit"
                            v-model="expense.planned"
                            :placeholder="$t('order.please_enter_manually')"
                            class="form-control form-control-xs font-weight-bold masraf-txt-input"
                            type="text"
                          />
                        </div>
                        <div class="text-xs font-weight-bold" style="">
                          <input
                            v-model="expense.actual"
                            :class="{
                              'text-primary':
                                Number(expense.actual) <= Number(expense.planned),
                              'text-danger':
                                Number(expense.actual) > Number(expense.planned),
                            }"
                            :disabled="true"
                            :placeholder="''"
                            class="form-control form-control-xs font-weight-bold masraf-txt-input"
                            type="text"
                          />
                        </div>
                        <div class="text-xs font-weight-bold" style="">
                          <input
                            v-model="expense.amount"
                            :placeholder="$t('order.please_enter_manually')"
                            class="form-control form-control-xs text-center"
                            style="
                              width: 40px !important;
                              font-size: 9px !important;
                              border-color: #78cdea;
                              background-color: #f3f6f9;
                            "
                            type="text"
                          />
                        </div>
                        <div
                          class="text-xs d-flex justify-content-end font-weight-bold"
                          style="position: relative"
                        >
                          <select
                            v-model="expense.unit_id"
                            class="form-control form-control-xs"
                            style="
                              width: 55px !important;
                              border-color: #78cdea;
                              background-color: #f3f6f9;
                              font-size: 9px !important;
                            "
                            name=""
                          >
                            <option value="">
                              {{ $t("general.please_select_option") }}
                            </option>
                            <option
                              v-for="(unit, unit_key) in options.unitOptions"
                              :value="unit_key"
                              :key="unit_key"
                            >
                              {{ unit }}
                            </option>
                          </select>
                          <span
                            v-if="expense.is_default === 0"
                            style="position: absolute; right: -38%"
                            class="items-center d-inline-block"
                            @click="DeleteExpensesSave(index)"
                            v-html="
                              getIconByKey('icons.sample.delete_red', {
                                class:
                                  'w-20px h-20px  object-fill flex-grow-1 cursor-pointer',
                              })
                            "
                          >
                          </span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>

                <!--Toplam-->
                <div class="w-100 row justify-content-end p-0 m-0 mb-2">
                  <div
                    class="mx-0 mt-0 px-2 text-sm font-weight-boldest py-4"
                    style="
                      background-color: #f3f6f9;
                      border: 1px solid #0b5a85;
                      border-radius: 12px;
                      color: #101010;
                    "
                  >
                    <span class="">{{ $t("order.total").toUpperCase() }} : </span>
                    <span>{{ expenseTotalAmount }}</span>
                  </div>
                </div>
                <!--Genel Toplam-->
                <div class="w-100 row justify-content-end p-0 m-0">
                  <div
                    class="mx-0 mt-3 px-2 text-sm font-weight-boldest py-4"
                    style="
                      background-color: #f3f6f9;
                      border: 1px solid #0b5a85;
                      border-radius: 12px;
                      color: #78cdea;
                    "
                  >
                    <span class="">{{ $t("order.general_total").toUpperCase() }} : </span>
                    <span>
                      {{
                        (Number(fabricTotalAmount) + Number(expenseTotalAmount)).toFixed(
                          2
                        )
                      }}</span
                    >
                  </div>
                </div>
              </template>

              <!--Renkler-->
              <template v-if="isValidToContinueOnSizeAndColor">
                <div class="col-12 text-lg font-weight-boldest mt-5">
                  {{ $t("order.size_and_color") }}
                </div>
                <div class="col-12 text-lg d-flex justify-content-end">
                  <span
                    class="cursor-pointer"
                    @click="addNewSizeAndColor"
                    v-html="
                      getIconByKey('icons.waybill.waybill_entry', {
                        class: 'w-35px h-35px d-inline-block mr-3',
                      })
                    "
                  ></span>
                  <span
                    class="cursor-pointer"
                    @click="deleteNewSizeAndColor"
                    v-html="
                      getIconByKey('icons.sample.delete_red', {
                        class: 'w-20px h-20px d-inline-block mr-3 ',
                      })
                    "
                  ></span>
                </div>

                <div class="w-100 beden-master" style="overflow-x: hidden">
                  <!--Header-->
                  <div class="col-12 row mx-0" style="border-bottom: 1px solid #78cdea">
                    <div class="flex-grow-1 text-center text-lg font-weight-bold py-1">
                      {{ $t("order.sizes").toUpperCase() }}
                    </div>
                  </div>

                  <div
                    v-for="(size_and_color, index) in form.order_size_and_colors"
                    :key="index"
                    class="w-100 d-flex flex-column navy-blue-border-bottom-1px"
                  >
                    <div class="w-100 d-flex flex-column py-3 relative">
                      <!--Plus Icon-->
                      <div
                        v-if="index === form.order_size_and_colors.length - 1"
                        class="col-12 d-flex justify-content-end align-items-center mt-5"
                      >
                        <span
                          class="cursor-pointer"
                          @click="addNewSizeAndColor"
                          v-html="
                            getIconByKey('icons.waybill.waybill_entry', {
                              class: 'w-35px h-35px d-inline-block mr-3',
                            })
                          "
                        ></span>
                        <span
                          v-if="form.order_size_and_colors.length>1"
                          class="cursor-pointer"
                          @click="deleteNewSizeAndColor(index)"
                          v-html="
                            getIconByKey('icons.sample.delete_red', {
                              class: 'w-20px h-20px d-inline-block mr-3 ',
                            })
                          "
                        ></span>
                      </div>
                      <div
                        class="d-flex w-100 px-2"
                        style="justify-content: space-between"
                      >
                        <div class="w-48 text-sm font-weight-bold mr-2">
                          <div>{{ $t("order.color_name") }}</div>
                          <input
                            v-model="size_and_color.color_name"
                            :placeholder="$t('order.color_name')"
                            class="form-control form-control-sm"
                            style="
                              width: 100%;
                              font-size: 10px !important;
                              border-color: #78cdea;
                              background-color: #f3f6f9;
                            "
                            step="1"
                            type="text"
                          />
                        </div>
                        <div class="w-48 text-sm font-weight-bold">
                          <div>{{ $t("order.color_number") }}</div>
                          <input
                            v-model="size_and_color.color_number"
                            :placeholder="$t('order.color_number')"
                            class="form-control form-control-sm"
                            style="
                              width: 100%;
                              font-size: 10px !important;
                              border-color: #78cdea;
                              background-color: #f3f6f9;
                            "
                            step="1"
                            type="number"
                          />
                        </div>
                        <div class="col-12 d-flex justify-content-center mt-5">
                          <span
                            class="cursor-pointer"
                            v-if="size_and_color.instruction_check"
                            @click="resetSizeAndColor(index)"
                            v-html="
                              getIconByKey('icons.order.reset-item', {
                                class: 'w-20px h-20px d-inline-block mr-3',
                              })
                            "
                          ></span>
                          <span
                            v-if="
                              form.order_size_and_colors.length > 1 &&
                              size_and_color.instruction_check
                            "
                            class="cursor-pointer"
                            @click="deleteNewSizeAndColor(index)"
                            v-html="
                              getIconByKey('icons.sample.delete_red', {
                                class: 'w-20px h-20px d-inline-block mr-3 ',
                              })
                            "
                          ></span>
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1 mx-0">
                      <div class="table-responsive h-100">
                        <table class="table h-100">
                          <thead>
                            <tr>
                              <th
                                v-for="(value, key) in size_and_color.body_sizes"
                                :key="index + '_' + key"
                                class="text-center"
                                style="border-bottom: 1px solid #78cdea"
                              >
                                <div
                                  class="w-100 d-flex justify-content-center items-center"
                                >
                                  <span class="d-inline-block px-5">{{
                                    value.name
                                  }}</span>
                                  <span
                                    v-if="
                                      Object.keys(size_and_color.body_sizes).length > 1 && size_and_color.instruction_check"
                                    "
                                    class="float-right cursor-pointer"
                                    @click="removeSizeFromList(index, key)"
                                    v-html="
                                      getIconByKey('icons.waybill.waybill_exit', {})
                                    "
                                  ></span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                v-for="(value, key) in size_and_color.body_sizes"
                                :key="key"
                                class=""
                                style="border-bottom: 1px solid #78cdea"
                              >
                                <div class="d-flex justify-content-center">
                                  <input
                                    v-model="value.amount"
                                    :class="{
                                      'is-invalid':
                                        Number(form.amount) < sizeAndColorTotal,
                                    }"
                                    class="form-control form-control-sm"
                                    type="number"
                                    style="
                                      background-color: #f3f6f9;
                                      border-color: #78cdea;
                                    "
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      class="w-100 d-flex"
                      style="justify-content: space-between; padding: 0 8px 8px 8px"
                    >
                      <div class="w-40 d-flex flex-column">
                        {{ $t("order.total").toUpperCase() }}
                        <span class="text-start text-sm" style="font-weight: bolder">
                          {{ sizeAndColorTotalByIndex(index) }}
                        </span>
                      </div>
                      <div class="w-40 d-flex flex-column">
                        {{ $t("order.export_total").toUpperCase() }}
                        <span class="text-start text-sm" style="font-weight: bolder">
                          {{
                            size_and_color.export_total
                              ? Number(size_and_color.export_total)
                              : 0
                          }}
                        </span>
                      </div>
                      <div class="w-20 mx-0 guncelle-btn-div">
                        <template
                          v-if="
                            index === form.order_size_and_colors.length - 1 &&
                            isValidToSubmit
                          "
                        >
                          <button-with-icon
                            v-if="!id"
                            :disabled="
                              is_submitting || !isUserGranted('Order', ['create'], false)
                            "
                            :icon-name="null"
                            :text="$t('order.save').toUpperCase()"
                            style="
                              border-color: #78cdea !important;
                              font-size: 10px !important;
                              font-weight: bolder;
                            "
                            @onClick="submitForm"
                          ></button-with-icon>
                          <button-with-icon
                            v-else
                            :disabled="
                              is_submitting || !isUserGranted('Order', ['update'], false)
                            "
                            :icon-name="null"
                            :text="$t('order.update').toUpperCase()"
                            style="
                              border-color: #78cdea !important;
                              font-size: 10px !important;
                              font-weight: bolder;
                            "
                            @onClick="updateOrder"
                          ></button-with-icon>
                        </template>
                      </div>
                    </div>
                  </div>

                  <!--KUMAŞ IHTIYACLARI-->
                  <template v-if="id">
                    <div
                      class="col-12 d-flex justify-content-between text-lg font-weight-boldest mt-5"
                    >
                      <span>{{ $t("order.fabric_requirements").toUpperCase() }}</span>
                    </div>

                    <div class="w-100 mb-1">
                      <table class="table w-100 border-15px mb-0">
                        <thead class="w-100">
                          <tr>
                            <th
                              class=""
                              style="
                                border-right: 1px solid #78cdea;
                                border-bottom: 1px solid #78cdea;
                              "
                            >
                              <div
                                class="d-flex justify-content-center items-center text-sm"
                              >
                                {{ $t("order.fabric") }}
                              </div>
                            </th>
                            <th
                              class=""
                              style="
                                border-right: 1px solid #78cdea;
                                border-bottom: 1px solid #78cdea;
                              "
                            >
                              <div
                                class="d-flex justify-content-center items-center text-sm"
                              >
                                {{ $t("order.fabric_color") }}
                              </div>
                            </th>
                            <th
                              class=""
                              style="
                                border-right: 1px solid #78cdea;
                                border-bottom: 1px solid #78cdea;
                              "
                            >
                              <div
                                class="d-flex justify-content-center items-center text-sm"
                              >
                                {{ $t("order.fabric_requirement_order") }}
                              </div>
                            </th>
                            <th
                              class=""
                              style="
                                border-right: 1px solid #78cdea;
                                border-bottom: 1px solid #78cdea;
                              "
                            >
                              <div
                                class="d-flex justify-content-center items-center text-sm"
                              >
                                {{ $t("order.fabric_requirement_extra") }}
                              </div>
                            </th>
                            <th class="" style="border-bottom: 1px solid #78cdea">
                              <div
                                class="d-flex justify-content-center items-center text-sm"
                              >
                                {{ $t("order.fabric_requirement_total") }}
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="navy-blue-bottom-1px">
                          <tr
                            v-for="order_fabric_requirement in form.order_fabric_requirements"
                          >
                            <td
                              class="items-start"
                              style="border-right: 1px solid #78cdea; font-size: 10px"
                            >
                              <a
                                href="#"
                                @click.prevent="
                                  showWaybills(
                                    order_fabric_requirement,
                                    3,
                                    order_fabric_requirement.order_fabric.type +
                                      '(' +
                                      order_fabric_requirement.order_size_and_color
                                        .color_name +
                                      '-' +
                                      order_fabric_requirement.order_size_and_color
                                        .color_number +
                                      ')'
                                  )
                                "
                                ><i class="fa fa-eye"></i
                              ></a>

                              <span>
                                {{ order_fabric_requirement.order_fabric.type }}</span
                              >
                            </td>
                            <td
                              class=""
                              style="border-right: 1px solid #78cdea; font-size: 10px"
                            >
                              <span>
                                {{
                                  order_fabric_requirement.order_size_and_color.color_name
                                }}
                                -
                                {{
                                  order_fabric_requirement.order_size_and_color
                                    .color_number
                                }}</span
                              >
                            </td>
                            <td
                              class=""
                              style="border-right: 1px solid #78cdea; font-size: 10px"
                            >
                              <span>{{ order_fabric_requirement.order_amount }}</span>
                            </td>
                            <td
                              class=""
                              style="border-right: 1px solid #78cdea; font-size: 10px"
                            >
                              <span>
                                <input
                                  v-model="order_fabric_requirement.extra_amount" :disabled="isDisableUpdateExtraAmount"
                                  class="form-control form-control-xs text-center"
                                  type="number"
                                  style="
                                    border-color: #78cdea;
                                    background-color: #f3f6f9;
                                    width: 50px !important;
                                  "
                                />
                              </span>
                            </td>
                            <td class="" style="font-size: 10px">
                              <span>{{ order_fabric_requirement.amount }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                </div>
              </template>

              <template v-if="form.is_submitted">
                <div
                  v-if="currentActiveTab === 1"
                  class="w-100 d-flex justify-content-center items-center mt-5"
                >
                  <div
                    class="d-flex items-center cursor-pointer"
                    @click="currentActiveTab = 2"
                  >
                    <span class="text-2xl font-weight-boldest text-primary">{{
                      $t("order.suppliers_and_manufacturer").toUpperCase()
                    }}</span>
                    <span
                      v-html="
                        getIconByKey('icons.order.continue', {
                          class: 'w-40px h-40px d-inline-block ml-5',
                        })
                      "
                    ></span>
                  </div>
                </div>
              </template>
            </div>
            <!--GENEL END-->

            <!--Genel End-->

            <!--TEDARIK START-->
            <div v-if="currentActiveTab === 2" class="row py-7 tedarik-master">
              <div class="w-100 mx-0">
                <div class="w-100 mb-5">
                  <div class="d-flex px-10">
                    <!--Tedarik Top Buttons-->
                    <div
                      :class="{ 'custom-tab-box': activeTab === 1 }"
                      class="w-200px text-center px-3 py-3 text-sm font-weight-bold cursor-pointer tedarik-top-btns mr-1"
                      @click="activeTab = 1"
                    >
                      {{ $t("order.expenses").toUpperCase() }}
                    </div>
                    <div
                      :class="{ 'custom-tab-box': activeTab === 2 }"
                      class="w-200px text-center px-3 py-3 text-sm font-weight-bold cursor-pointer tedarik-top-btns ml-1"
                      @click="activeTab = 2"
                    >
                      {{ $t("order.fabrics").toUpperCase() }}
                    </div>
                  </div>
                </div>
                <!--TEDARIK MASRAFLAR SECTION START-->
                <template v-if="+activeTab === 1">
                  <div class="w-100 row mx-0 px-10 mb-5 masraf-badge-master">
                    <label
                      for="instruction-type"
                      style="font-size: 12px; font-weight: bolder; margin: 0; padding: 0"
                      >Talimat Tipi Seçiniz:*</label
                    >
                    <select
                      name="instruction-type"
                      id="instruction-type"
                      @change="changeSelectedExpense($event)"
                      style="
                        width: 130px;
                        border: 1px solid #78cdea;
                        border-radius: 12px;
                        padding: 3px 9px;
                        margin-left: 16px;
                      "
                    >
                      <option
                        v-for="(expense_type, index) in form.order_expenses"
                        :value="index"
                        :key="index"
                      >
                        {{ expense_type.name }}
                      </option>
                    </select>
                    <!-- <div v-for="(expense_type, index) in form.order_expenses"
                      class=""
                      :key="index">
                      <span
                        :class="{'tedarik-badge-selected': Number(expense_data.selectedExpenseID) === Number(expense_type.expense_id),}"
                        class="text-xs font-weight-bold cursor-pointer"
                        style="padding:4px 8px!important"></span>
                    </div> -->
                  </div>
                  <template
                    v-if="
                      expense_data.selectedExpenseID &&
                      expense_details[expense_data.selectedExpenseID]
                    "
                  >
                    <template
                      v-for="(expenseDetail, expend_detail_index) in expense_details[
                        expense_data.selectedExpenseID
                      ].order_expense_details"
                    >
                      <div
                        :class="{ 'mt-10': expend_detail_index > 0 }"
                        :ref="`expense_${expenseDetail.id}`" :id="`expense_${expenseDetail.id}`"
                        class="w-100 mx-0 px-3"
                      >
                        <div
                          class="w-100 mb-5"
                          style="
                            overflow-x: auto;
                            border: 1px solid #0b5a85;
                            border-radius: 12px;
                          "
                        >
                          <table class="table mb-0" style="min-height: 150px !important">
                            <tr>
                              <th class="tbl-border-bottom-right text-sm text-center">
                                {{ $t("order.instruction_type") }}
                              </th>
                              <th class="tbl-border-bottom-right text-sm text-center">
                                {{ $t("order.expense") }}
                              </th>
                              <th class="tbl-border-bottom-right text-sm text-center">
                                {{ $t("order.supplier") }}
                              </th>
                              <th class="tbl-border-bottom-right text-sm text-center">
                                {{ $t("order.dispatch_location") }}
                              </th>
                              <th class="tbl-border-bottom-right text-sm text-center">
                                {{ $t("order.currency_unit") }}
                              </th>
                              <th class="tbl-border-bottom-right text-sm text-center">
                                {{ $t("order.date") }}
                              </th>
                              <th class="tbl-border-bottom-right text-sm text-center">
                                {{ $t("order.who_took_the_order") }}
                              </th>
                              <th class="tbl-border-bottom-right text-sm text-center">
                                {{ $t("order.payment") }}
                              </th>
                              <th class="tbl-border-bottom-right text-sm text-center">
                                {{ $t("order.start_date") }}
                              </th>
                              <th
                                class="text-sm text-center"
                                style="border-bottom: 1px solid #78cdea !important"
                              >
                                {{ $t("order.end_date") }}
                              </th>
                            </tr>
                            <tbody class="masraf-content-tbl">
                              <!--Masraf TBL COntent-->
                              <tr>
                                <td class="tbl-border-right text-sm text-center">
                                  {{ $t("order.expense") }}
                                  {{
                                    expense_data.selectedExpenseID in
                                    options.expensesOptions
                                      ? options.expensesOptions[
                                          expense_data.selectedExpenseID
                                        ].name
                                      : options.optionalExpenseOptions[
                                          expense_data.selectedExpenseID
                                        ].name
                                  }}
                                </td>
                                <td class="tbl-border-right text-sm text-center">
                                  {{
                                    expense_data.selectedExpenseID in
                                    options.expensesOptions
                                      ? options.expensesOptions[
                                          expense_data.selectedExpenseID
                                        ].name
                                      : options.optionalExpenseOptions[
                                          expense_data.selectedExpenseID
                                        ].name
                                  }}
                                </td>
                                <td class="tbl-border-right text-sm text-center">
                                  <custom-multi-select
                                    :item-per-row="1"
                                    :max="1"
                                    :max-show="1"
                                    :model.sync="expenseDetail.supplier_company_id"
                                    :not-list="true"
                                    :options="options.supplierCompanyOptions"
                                    :required="false"
                                    :title="null"
                                    name="supplier_company"
                                  ></custom-multi-select>
                                </td>
                                <td class="tbl-border-right text-sm text-center">
                                  <custom-multi-select
                                    :item-per-row="1"
                                    :max="1"
                                    :max-show="1"
                                    :model.sync="expenseDetail.dispatch_location_id"
                                    :not-list="true"
                                    :options="options.dispatchLocationOptions"
                                    :required="false"
                                    :title="null"
                                    name="dispatch_location"
                                  ></custom-multi-select>
                                </td>
                                <td class="tbl-border-right text-sm text-center relative">
                                  <div>
                                    <custom-multi-select
                                      :max="1"
                                      :max-show="1"
                                      :model.sync="expenseDetail.currency_unit_id"
                                      :name="`expense_detail_currency-${expend_detail_index}`"
                                      :not-list="true"
                                      :options="options.currencyOptions"
                                      :required="false"
                                      :title="null"
                                      @save="onExpenseDetailCurrencyChange"
                                    ></custom-multi-select>
                                  </div>
                                  <div
                                    class="absolute"
                                    style="
                                      bottom: 10%;
                                      left: 12%;
                                      width: 60px !important;
                                      display: flex;
                                    "
                                  >
                                    <b>{{ $t("general.exchange_rate") }}:</b>
                                    <span style="margin-left: 4px">{{
                                      expenseDetail.exchange_rate
                                    }}</span>
                                  </div>
                                </td>
                                <td class="tbl-border-right text-sm text-center">
                                  {{
                                    expenseDetail.date ||
                                    today | laravelToMomentTimeFormat
                                  }}
                                </td>
                                <td
                                  class="tbl-border-right text-sm text-center relative"
                                  style="min-width: 110px !important"
                                >
                                  <text-input
                                    :model.sync="expenseDetail.to_who"
                                    :placeholder="$t('order.who_took_the_order')"
                                    :required="false"
                                    style="position: absolute; top: 35%"
                                    name="to_who"
                                  >
                                  </text-input>
                                </td>
                                <td class="tbl-border-right text-sm text-center">
                                  <custom-multi-select
                                    :max="1"
                                    :model.sync="expenseDetail.expiry_id"
                                    :not-list="true"
                                    :options="options.expirationTypeOptions"
                                    :required="false"
                                    :show-invalid="true"
                                    :title="null"
                                    name="expiration_type_options"
                                    object-key="name"
                                  ></custom-multi-select>
                                </td>
                                <td class="tbl-border-right text-sm text-center">
                                  <date-picker-input
                                    :model.sync="expenseDetail.start_date"
                                    :required="false"
                                    :title="null"
                                    input-width="100px"
                                  ></date-picker-input>
                                </td>
                                <td>
                                  <date-picker-input
                                    :model.sync="expenseDetail.end_date"
                                    :required="false"
                                    :title="null"
                                    input-width="100px"
                                  ></date-picker-input>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <!--MASRAF ALT TABLE START-->
                      <div
                        class="w-100 row mx-0 px-0 masraf-alt"
                        style="
                          border-top: 1px solid #0b5a85;
                          border-bottom: 1px solid #0b5a85;
                          border-radius: 12px;
                          margin-top: 24px !important;
                        "
                      >
                        <div class="col-12 row mx-0 navy-blue-border-bottom-1px">
                          <div
                            v-if="Number(expenseDetail.expense_type_id) === 2"
                            :class="
                              Number(expense_data.selectedExpenseID) === 17
                                ? 'col-12 px-0'
                                : 'col-12 px-0'
                            "
                            class="row mx-0"
                          >
                            <div class="col-12 text-sm font-weight-bolder mt-3 px-0 pb-1">
                              {{ $t("order.description") }}
                            </div>
                            <div
                              class="col-12 pb-3 px-0 mx-0"
                              style="border-bottom: 1px solid #0b5a85"
                            >
                              <textarea
                                v-model="expenseDetail.description"
                                class="w-100 form-control px-0"
                                name=""
                                rows="3"
                                style="
                                  border-color: #78cdea;
                                  resize: none;
                                  text-indent: 8px;
                                "
                              >
                              </textarea>
                            </div>
                          </div>
                          <!--Check poin 1-->

                          <div style="display: flex">
                            <!--Alternatif Renk Seç Div-->
                            <div
                              :class="{
                                '': +expenseDetail.expense_type_id !== 2,
                                '': +expenseDetail.expense_type_id === 2,
                              }"
                              class="row col-6 mx-0 py-3"
                            >
                              <div class="col-12 text-sm font-weight-bold">
                                <div class="col-12 mx-0">
                                  <div
                                    class="col-12 text-sm font-weight-bold py-1 mx-0 px-0"
                                  >
                                    <div class="mb-2 font-weight-bolder">
                                      {{ $t("order.color_name") }}
                                    </div>
                                    <custom-multi-select
                                      :has-bottom-margin="false"
                                      :is-inline="false"
                                      :item-per-row="1"
                                      :max="1"
                                      :model.sync="expenseDetail.order_size_and_color_id"
                                      :name="`expense_detail_unit_id_${expend_detail_index}`"
                                      :not-list="true"
                                      :options="sizeAndColorOptions"
                                      :placeholder="$t('order.color_name')"
                                      :required="false"
                                      :title="null"
                                      input-width="125px"
                                      width="125px"
                                      @onSelect="
                                        onChangeSizeAndColor($event, expenseDetail)
                                      "
                                      @save="expenseDetail.body_sizes = null"
                                    ></custom-multi-select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--Toplam-->
                            <div class="total-div" style="margin-top: 12px">
                              <div
                                v-if="+expenseDetail.expense_type_id !== 2"
                                class="col-12 row mx-0 px-0"
                              >
                                <div class="col-12 text-sm font-weight-bold">
                                  <div class="mb-3 text-nowrap">
                                    {{ $t("order.total").toUpperCase() }}
                                  </div>
                                  <div class="text-center text-sm font-weight-boldest">
                                    {{ totalSizeAndColor(expenseDetail) }}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--Miktar Div-->
                            <div
                              v-if="Number(expenseDetail.expense_type_id) === 2"
                              class="row col-6 mx-0 py-3"
                              style="display: flex"
                            >
                              <div class="col-12 text-sm font-weight-bold">
                                <div class="mb-3">{{ $t("order.amount") }}</div>
                                <input
                                  v-model="expenseDetail.total"
                                  class="form-control form-control-sm"
                                  step="1"
                                  style="background-color: #f3f6f9; border-color: #78cdea"
                                  type="number"
                                />
                              </div>
                            </div>
                            <!--Snap-->
                            <div
                              v-if="Number(expense_data.selectedExpenseID) === 17"
                              class="col-12 row mx-0 py-3 text-center p-0"
                            >
                              <div class="col-12 text-lg font-weight-bold">
                                <div class="mb-3">{{ $t("order.snap") }}</div>
                                <custom-multi-select
                                  :item-per-row="1"
                                  :required="false"
                                  :max="1"
                                  :model.sync="expenseDetail.snap_type_id"
                                  :name="'snap_type_id'"
                                  :not-list="true"
                                  :options="options.snapTypeOptions"
                                  :title="null"
                                ></custom-multi-select>
                              </div>
                            </div>
                            <div
                              v-if="+expenseDetail.expense_type_id !== 2"
                              :class="
                                !expenseDetail.body_sizes
                                  ? 'd-flex justify-content-center align-items-center text-center'
                                  : ''
                              "
                              class="col-6 mx-0"
                              style="
                                min-width: 150px !important;
                                max-width: calc(100% - 725px);
                              "
                            ></div>
                          </div>
                          <!--EK Renkler-->
                          <div class="responsive-colors" style="overflow-x: auto">
                            <div class="table-responsive">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th
                                      v-for="(value, key) in expenseDetail.body_sizes"
                                      :key="'size_and_color_body_size_name' + key"
                                      class="text-center"
                                      style="
                                        border-bottom: 1px solid #78cdea;
                                        border-right: 1px solid #78cdea;
                                      "
                                    >
                                      <div
                                        class="w-100 d-flex justify-content-center items-center"
                                      >
                                        <span class="d-inline-block px-5"
                                          >{{ value.name }}
                                          <span
                                            v-if="defaultAmount(expenseDetail, value, 2)"
                                            >({{
                                              defaultAmount(expenseDetail, value, 2)
                                            }})</span
                                          ></span
                                        >
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr class="h-100">
                                    <td
                                      v-for="(value, key) in expenseDetail.body_sizes"
                                      :key="'size_and_color_body_size_name' + key"
                                      class="navy-blue-border-right-1px"
                                    >
                                      <div class="d-flex justify-content-center">
                                        <input
                                          v-model="value.amount"
                                          class="form-control form-control-sm min-width-50"
                                          type="text"
                                          style="
                                            background-color: #f3f6f9;
                                            border-color: #78cdea;
                                          "
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <!--Birim Fiyat Iskonto KDV START-->
                          <div class="birim-fiyat-iskonto-kdv">
                            <div class="col-6 row mx-0 text-center p-0">
                              <div
                                class="col-12 text-sm font-weight-bold py-1 px-1"
                                style="border-right: 1px solid #78cdea"
                              >
                                <div class="mb-3">{{ $t("order.unit") }}</div>
                                <custom-multi-select
                                  :has-bottom-margin="false"
                                  :is-inline="false"
                                  :item-per-row="1"
                                  :max="1"
                                  :model.sync="expenseDetail.unit_id"
                                  :name="`expense_detail_unit_id_${expend_detail_index}`"
                                  :not-list="true"
                                  :options="options.unitOptions"
                                  :placeholder="$t('order.unit')"
                                  :required="false"
                                  :title="null"
                                  input-width="125px"
                                  width="125px"
                                ></custom-multi-select>
                              </div>
                            </div>
                            <div class="col-6 row mx-0 text-center p-0">
                              <div
                                class="col-12 text-sm font-weight-bold py-1 px-1"
                                style="
                                  border-right: 1px solid #78cdea;
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                "
                              >
                                <div class="mb-3">{{ $t("order.price") }}</div>
                                <input
                                  v-model="expenseDetail.price"
                                  :placeholder="$t('order.please_enter_manually')"
                                  class="form-control form-control-sm"
                                  step="1"
                                  type="number"
                                  style="
                                    width: 125px !important;
                                    background-color: #f3f6f9;
                                    border-color: #78cdea;
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-6 row mx-0 text-center p-0">
                              <div
                                class="col-12 text-sm font-weight-bold py-1 px-1"
                                style="
                                  border-right: 1px solid #78cdea;
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                "
                              >
                                <div class="mb-3">
                                  {{ $t("order.discount") }}
                                </div>
                                <input
                                  v-model="expenseDetail.discount"
                                  :placeholder="'Iskonto giriniz'"
                                  class="form-control form-control-sm"
                                  step="1"
                                  type="number"
                                  style="
                                    width: 125px !important;
                                    background-color: #f3f6f9;
                                    border-color: #78cdea;
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-6 row mx-0 text-center p-0">
                              <div
                                class="col-12 text-sm font-weight-bold py-1 px-1"
                                style="
                                  border-right: 1px solid #78cdea;
                                  display: flex;
                                  flex-direction: column;
                                  align-items: center;
                                "
                              >
                                <div class="mb-3">
                                  {{ $t("order.kdv_rate") }}
                                </div>
                                <custom-multi-select
                                  :has-bottom-margin="false"
                                  :is-inline="false"
                                  :item-per-row="1"
                                  :max="1"
                                  :model.sync="expenseDetail.vat_rate_id"
                                  :not-list="true"
                                  :options="vatRateOptions"
                                  :required="false"
                                  :title="null"
                                  input-width="125px"
                                  name="vat_rate"
                                  width="125px"
                                ></custom-multi-select>
                              </div>
                            </div>
                          </div>
                          <!--Birim Fiyat Iskonto KDV END-->
                        </div>
                        <template v-if="Number(expenseDetail.expense_type_id) !== 2">
                          <div class="w-100 row mx-0">
                            <div
                              :class="{
                                'col-12': Number(expense_data.selectedExpenseID) !== 17,
                                'col-8': Number(expense_data.selectedExpenseID) === 17,
                              }"
                              class="px-0"
                            >
                              <div class="col-12 text-lx font-weight-bold">
                                {{ $t("order.description") }}
                              </div>
                              <div class="col-12 pb-3 navy-blue-border-bottom-1px">
                                <textarea
                                  v-model="expenseDetail.description"
                                  class="w-100 form-control"
                                  name=""
                                  rows="3"
                                  style="
                                    resize: none !important;
                                    border-color: #78cdea;
                                    text-indent: 8px;
                                  "
                                ></textarea>
                              </div>
                            </div>
                            <div
                              v-if="Number(expense_data.selectedExpenseID) === 17"
                              class="col-4 row mx-0 px-0"
                            >
                              <div class="col-12 text-center text-lx font-weight-bold">
                                {{ $t("order.snap") }}
                              </div>
                              <div
                                class="col-12 pb-3 navy-blue-border-bottom-1px d-flex justify-content-center"
                              >
                                <div class="w-200px">
                                  <custom-multi-select
                                    :item-per-row="1"
                                    :max="1"
                                    :model.sync="expenseDetail.snap_type_id"
                                    :name="'snap_type_id'"
                                    :not-list="true"
                                    :options="options.snapTypeOptions"
                                    :title="null"
                                  ></custom-multi-select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                        <!--Üretim Hedefleri-->
                        <template>
                          <div
                            class="col-12 text-center text-md font-weight-bold py-2"
                            style="border-bottom: 1px solid #0b5a85"
                          >
                            {{ $t("order.order_production_goals") }}
                            <span
                              class="float-right"
                              @click="addNewTarget(expend_detail_index)"
                              v-html="
                                getIconByKey('icons.waybill.waybill_entry', {
                                  class: 'w-35px h-35px d-inline-block object-cover',
                                })
                              "
                            ></span>
                          </div>

                          <div
                            v-for="(
                              target, target_index
                            ) in expenseDetail.order_expense_detail_targets"
                            :key="'targets_' + expend_detail_index + target_index"
                            class="col-6 row mx-0 px-0 date-goals-div"
                          >
                            <div
                              :class="{}"
                              class="col-xl-12 col-lg-12 col-md-12 col-sm-12 row mx-0 justify-content-center text-center py-2"
                            >
                              <div class="w-150px">
                                {{ $t("order.date") }} /
                                {{ $t("order.target") }}
                                <date-picker-input
                                  :model.sync="target.date"
                                  :title="null"
                                  name="order_expense_detail_targets_date"
                                ></date-picker-input>
                                <number-input
                                  :model.sync="target.target"
                                  :required="false"
                                  :title="null"
                                  name="order_expense_detail_targets_target"
                                ></number-input>
                              </div>
                              <span
                                v-if="target_index > 0&&isVisibleDeleteTarget(target.date)"
                                class="float-right"
                                @click="
                                  deleteNewTarget(expend_detail_index, target_index)
                                "
                                v-html="
                                  getIconByKey('icons.sample.delete_red', {
                                    class: 'w-20px h-20px d-inline-block object-cover',
                                  })
                                "
                              ></span>
                            </div>
                          </div>
                        </template>
                      </div>

                      <template v-if="expenseDetail">
                        <div
                          v-if="expenseDetail.is_editing"
                          class="w-100 d-flex justify-content-center py-3 my-10"
                        >
                          <button-with-icon
                            :disabled="
                              !(
                                isUserGranted('OrderExpenseDetail', ['create'], false) ||
                                isUserGranted('OrderExpenseDetail', ['update'], false)
                              )
                            "
                            :icon-name="null"
                            :text="$t('order.create_instruction').toUpperCase()"
                            class="mx-2 text-primary"
                            size="xl"
                            @onClick="createOrUpdateInstruction(expend_detail_index)"
                          ></button-with-icon>
                        </div>
                        <div
                          v-if="expenseDetail.is_submitted && !expenseDetail.is_editing"
                          class="w-100 d-flex justify-content-center py-3 expense-bottom-btns"
                          style="flex-wrap: wrap"
                        >
                          <button-with-icon
                            :icon-name="null"
                            :text="$t('order.download_as_pdf').toUpperCase()"
                            class="mx-2"
                            size="xs"
                            @onClick="openInNewTab(expenseDetail.order_pdf_link)"
                          ></button-with-icon>
                          <button-with-icon
                            :icon-name="null"
                            :text="$t('order.download_as_excel').toUpperCase()"
                            class="mx-2"
                            size="xs"
                            @onClick="openInNewTab(expenseDetail.order_excel_link)"
                          ></button-with-icon>
                          <button-with-icon
                            :icon-name="null"
                            :text="$t('order.edit').toUpperCase()"
                            class="mx-2"
                            size="xs"
                            @onClick="
                              changeToEditMode(expenseDetail, expend_detail_index)
                            "
                          ></button-with-icon>
                          <button-with-icon
                            :icon-name="null"
                            :text="$t('order.cancel_instruction').toUpperCase()"
                            class="mx-2"
                            size="xs"
                            @onClick="
                              cancelExpenseDetail(expenseDetail, expend_detail_index)
                            "
                          ></button-with-icon>
                          <button-with-icon
                            :icon-name="null"
                            :text="$t('order.send').toUpperCase()"
                            class="mx-2"
                            size="xs"
                            @onClick="
                              sendExpenseDetail(expenseDetail, expend_detail_index)
                            "
                          ></button-with-icon>
                        </div>
                      </template>
                    </template>
                    <div class="w-100 px-5 py-2">
                      <span
                        class="cursor-pointer"
                        @click="addNewExpenseDetail"
                        v-html="
                          getIconByKey('icons.waybill.waybill_entry', {
                            class: 'w-45px h-45px d-inline-block object-cover',
                          })
                        "
                      ></span>
                      <span
                        v-if="
                          expense_details[expense_data.selectedExpenseID]
                            .order_expense_details.length > 1
                        "
                        class="cursor-pointer"
                        @click="
                          deleteNewExpenseDetail(
                            expense_details[expense_data.selectedExpenseID]
                              .order_expense_details
                          )
                        "
                        v-html="
                          getIconByKey('icons.sample.delete_red', {
                            class: 'w-20px h-20px d-inline-block object-cover',
                          })
                        "
                      ></span>
                    </div>
                  </template>
                </template>
                <template v-if="+activeTab === 2 && form.fabric_supply_type_id !== -1">
                  <order-fabric
                    :options="options"
                    :order="form"
                    :order-id="form.id"
                  ></order-fabric>
                </template>
              </div>
            </div>

            <div v-if="currentActiveTab === 3" class="row py-7">
              <order-reports
              @selectReport="selectReport"
              >

              </order-reports>

              <order-forms
              :body-sizes="defaultBodySizes"
              :order="form"
              :csr="csr"
              :form-stop-options="options.formStopOptions"
              >
                
              </order-forms>
              <order-files
              :order-id="form.id"
              >
                
              </order-files>
            <!-- <div class="col-12 text-center">
              <button
                  class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer mr-2"
                  @click="onDownloadButtonClick">
                {{ $t('order.show_report') }}
              </button>

              <button
                  class="btn btn-sm btn-outline-primary btn-pill p-2 font-weight-bold cursor-pointer ml-2"
                  @click="downloadPdf">
                {{ $t('order.download_report') }}
              </button>
            </div> -->
          </div>
          </div>
        </template>
      </dashboard-box>
    </div>

    <CoolLightBox :index="imageIndex" :items="imageLinks" @close="imageIndex = null">
    </CoolLightBox>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations, mapState } from "vuex";
import {
  CREATE_ITEM,
  DELETE_ITEM_BY_ID,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  LOADING,
  GET_PDF,
  SET_LOADING,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/REST.module";
import NumberInput from "@/assets/components/inputs/NumberInput";
import {
  EXPENSE_DETAIL,
  FORM,
  RESET_FORM,
  SET_EXPENSE_DETAIL,
  SET_FORM,
} from "@/core/services/store/order/edit-order.module";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import TextInput from "@/assets/components/inputs/TextInput";
import _ from "lodash";
import csrData from "@/view/pages/csr/csr-data.js";

import moment from "moment";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import NumberInputWithUnit from "@/assets/components/inputs/NumberInputWithUnit";
import {
  LARAVEL_DATE_FORMAT,
  LARAVEL_DATE_TIME_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";
import QRCode from "@/assets/components/widget/QRCode";
import Tooltip from "@/view/pages/vue-bootstrap/Tooltip";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import OrderFabric from "@/view/pages/order/layout/OrderFabric";
import OrderReports from "@/view/pages/order/layout/OrderReports";
import OrderForms from "@/view/pages/order/layout/OrderForms";
import OrderFiles from "@/view/pages/order/layout/OrderFiles";
import OrderPdfReport from "@/view/pages/order/pdf/OrderPdfReport";
import InstructionsModal from "@/view/pages/shared/instructionsModal";
import $ from "jquery";
import pdf from "vue-pdf";

import moduleUser, {
  GENERAL_SETTING,
  MODULE_NAME as MODULE_USER,
} from "@/core/services/store/user.module";
import store from "@/core/services/store";


const _MODULE_USER = MODULE_USER;

export default {
  name: "CreateNewOrder",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_USER, moduleUser);
  },
  components: {
    InstructionsModal,
    OrderFabric,
    InfoTooltip,
    Tooltip,
    QRCode,
    NumberInputWithUnit,
    DatePickerInput,
    ButtonWithIcon,
    TextInput,
    CustomMultiSelect,
    NumberInput,
    DashboardBox,
    OrderReports,
    OrderForms,
    OrderFiles,
    pdf,
    OrderPdfReport
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    isVisibleSalePrice(){
      if(!this.form.id){
        return true;
      }
      if(this.isUserGranted('Order', ['canSeeSalePrice'],false,true)){
        return true;
      }
      return false;
    },
    isDisableUpdateExtraAmount(){
      return this.isUserGranted('Order', ['updateExtraAmount'], false, true) ? false : true;
    },
    isDisableCalculateCosts(){
      return this.isUserGranted('Order', ['calculateCosts'], false, true)? false : true;
    },
    isDisablePriceEdit() {
      return this.isUserGranted("Order", ["updatePrice"], false, true) ? false : true;
    },
    defaultAmount() {
      return function (data, value, key) {
        if (key === 2) {
          let item = this.defaultTotals.find((item) => item.id === data.id);
          return item
            ? item.body_sizes.find((item) => item.body_size_id === value.body_size_id)
                .amount
            : 0;
        }
      };
    },
    sizeAndColorOptions() {
      let self = this;
      let items = this.form.order_size_and_colors;

      if (items) {
        return self.convertArrayToObjectByKey(items, "id", "color_name");
      }

      return {};
    },
    vatRateOptions() {
      let options = this.options.VATRateOptionsRaw || {};
      let data = options;

      let activeTab = this.activeTab;
      if (+activeTab === 1) {
        let expenseTypeId = this.expense_data.typeId;
        if (options && expenseTypeId) {
          data = data.filter((item) => +item.type_id === expenseTypeId);
        }
      } else if (+activeTab === 2) {
        data = options.filter((item) => +item.type_id === 4);
      }
      return this.convertArrayToObjectByKey(data, "id", "vat.rate");
    },
    ...mapState({
      form: (state) => state.editOrder[FORM],
      expense_details: (state) => state.editOrder[EXPENSE_DETAIL],
    }),
    totalSizeAndColor() {
      return function (item) {
        let items = _.values(item.body_sizes);
        let amount = 0;
        items.forEach((subItem) => {
          amount += Number(subItem.amount);
        });

        return amount;
      };
    },
    expenseCoefficient() {
      let fabricRequests = 10;

      if (this.settings) {
        let settings = _.first(this.settings);
        if (settings) {
          fabricRequests = settings.fabric_request.expense_coefficient;
        }
      }
      return fabricRequests;
    },
    settings() {
      return this.$store.getters[_MODULE_USER + "/" + GENERAL_SETTING];
    },
    yesNoOptions() {
      let data = csrData.yesNo;
      return this.convertArrayToObjectByKey(data, "id", "name");
    },
    disneyLicences() {
      let data = csrData.disneyLicences;
      return this.convertArrayToObjectByKey(data, "id", "name");
    },
    joinLifeOptions() {
      let data = csrData.joinLife;
      return this.convertArrayToObjectByKey(data, "id", "name");
    },
    finalTotal() {
      let total = 0;
      this.form.order_fabrics.forEach((fabric) => {
        total += Number(fabric.pastal);
      });

      return total * Number(this.form.amount);
    },

    currentOrderExpenseDetail: {
      get() {
        if (this.expense_data.selectedExpenseID === null) return null;
        if (this.expense_data.selectedExpenseID in this.expense_details) {
          return this.expense_details[this.expense_data.selectedExpenseID];
        }
        return null;
      },
      set(value) {
        if (this.expense_data.selectedExpenseID === null) return null;
        if (!(this.expense_data.selectedExpenseID in this.expense_details)) {
          return null;
        }
        this.$set(this.expense_details, this.expense_data.selectedExpenseID, {
          ...value,
        });
      },
    },
    fabricTotalAmount() {
      let total = 0;
      for (let i = 0; i < this.form.order_fabrics.length; i++) {
        total +=
          Number(this.form.order_fabrics[i].pastal) *
          Number(this.form.order_fabrics[i].unit_price);
      }
      let rate = 1;
      /*      if (this.form.currency_unit_id in this.options.currencyOptions) {
              rate = Number(this.options.currencyRateOptions[this.options.currencyOptions[this.form.currency_unit_id]].rate);
            }*/
      return (isNaN(total * rate) ? 0 : total * rate).toFixed(2);
    },
    expenseTotalAmount() {
      let total = 0;
      for (let i = 0; i < this.form.order_expenses.length; i++) {
        total +=
          Number(this.form.order_expenses[i].amount) *
          Number(this.form.order_expenses[i].planned);
      }
      let rate = 1;
      /*      if (this.form.currency_unit_id in this.options.currencyOptions) {
              rate = Number(this.options.currencyRateOptions[this.options.currencyOptions[this.form.currency_unit_id]].rate);
            }*/
      return (isNaN(total * rate) ? 0 : total * rate).toFixed(2);
    },
    sizeAndColorTotal() {
      let total = 0;
      this.form.order_size_and_colors.forEach((value, index) => {
        for (let key in value.body_sizes) {
          total += Number(value.body_sizes[key].amount);
        }
      });
      return total;
    },
    sizeAndColorTotalByIndex() {
      let size_and_colors = this.form.order_size_and_colors;
      return function (index) {
        let current = size_and_colors[index],
          total = 0;
        for (let key in current.body_sizes) {
          total += Number(current.body_sizes[key].amount);
        }
        return total;
      };
    },
    isOrderExportDateAmountValid() {
      if (Number(this.form.amount) === 0) return false;
      let total = 0;
      for (let i = 0; i < this.form.order_export_dates.length; i++) {
        total += Number(this.form.order_export_dates[i].amount);
      }
      if (total !== Number(this.form.amount)) return false;
      return true;
    },
    isValidToContinueOnGeneral() {
      if (this.form.esc_table_id === null) return false;
      if (this.form.model === null || this.form.model === "") return false;
      if (Number(this.form.company_id) === 0) return false;
      if (Number(this.form.status_id) === 0) return false;
      if (this.form.po_number === null || this.form.po_number === "") return false;
      if (this.form.image_link === null && this.form.image) return false;
      if (Number(this.form.amount) === 0) return false;

      return true;
    },
    isValidToContinueOnFabric() {
      if (!this.isValidToContinueOnGeneral) return false;
      if (Number(this.form.sale_price) === 0) return false;
      if (Number(this.form.currency_unit_id) === 0) return false
      if (this.form.qc_user_id === null) return false;
      
      let total = 0;
      for (let i = 0; i < this.form.order_export_dates.length; i++) {
        if (this.form.order_export_dates[i].export_date === null) return false;
        if (Number(this.form.order_export_dates[i].amount) === 0) return false;
        total += Number(this.form.order_export_dates[i].amount);
      }
      if (total !== Number(this.form.amount)) return false;
      if (this.form.description === null || this.form.description === "") return false;
      if (this.form.amount_per_parcel === null) return false;
      return true;
    },
    isValidToContinueOnExpenses() {
      if (!this.isValidToContinueOnFabric) return false;
      for (let i = 0; i < this.form.order_fabrics.length; i++) {
        let current = this.form.order_fabrics[i];
        if (current.type === null || current.type === "") return false;
        if (!current.weight) return false;
        if (!current.width) return false;
        if (Number(current.pastal) === 0) return false;
        if (Number(current.unit_id) === 0) return false;
        if (Number(current.unit_price) === 0) return false;
        let total = 0;
        if (current.order_fabric_compositions.length === 0) return false;
        for (let j = 0; j < current.order_fabric_compositions.length; j++) {
          let temp = current.order_fabric_compositions[j];
          if (Number(temp.fabric_composition_id) === 0) return false;
          if (Number(temp.amount) === 0) return false;
          //if (Number(temp.amount) === 0) return false; // 6 Nisan Tarihinde Neslihan hanımın Pamuğu çıkarıp yerine başka bir şey ekleyememesinden dolayı 0 ibaresi kaldırdı çıkarmak istedikleri yer 0 yazacaklar

          total += Number(temp.amount);
        }
        if (total !== 100) return false;
      }
      return true;
    },
    isValidToContinueOnSizeAndColor() {
      if (!this.isValidToContinueOnExpenses) return false;
      for (let i = 0; i < this.form.order_expenses.length; i++) {
        let current = this.form.order_expenses[i];
        if (Number(current.planned) === 0) return false;
        if (Number(current.amount) === 0) return false;
        if (Number(current.unit_id) === 0) return false;
        if (Number(current.currency_unit_id) === 0) return false;
      }
      return true;
    },
    isValidToSubmit() {
      if (!this.isValidToContinueOnSizeAndColor) return false;
      let total = 0;

      for (let i = 0; i < this.form.order_size_and_colors.length; i++) {
        const size = this.form.order_size_and_colors[i],
          tempTotal = Number(this.sizeAndColorTotalByIndex(i));
        if (tempTotal === 0) return false;
        total += tempTotal;
        if (size.color_number === null || size.color_number === "") return false;
        if (size.color_name === null || size.color_name === "") return false;
      }
      if (Number(total) !== Number(this.form.amount)) return false;
      return true;
    },
  },
  data() {
    return {
      pdfs: [],
      defaultBodySizes: null,

      src: null,
      numPages: undefined,
      currentPDF: {
        blob: null,
      },

      imageLinks: [],
      orderReportData:[],
      imageIndex: null,

      csr: {
        disney_licences: {
          disney_id: null,
          license_id: null,
        },
        join_life: [],
      },
      instructionTypes: null,
      instructions: null,
      expenses: [],
      orderAllResult: [],
      is_submitting: false,
      defaultTotals: [],
      expense_data: {
        orderExpenseId: null,
        selectedExpenseID: null,
        typeId: null,
        instructionTypeId: null,
      },

      today: moment(),
      orderDate: moment(),

      // Control Variables
      currentActiveTab: 4,
      defaultSizeJSON: {},

      id: this.$route.params.id,
      type: Number(this.$route.params.type),
      esc_id: Number(this.$route.query.esc_id),
      sample_id: Number(this.$route.query.sample_id),
      activeTab: 1,

      instructionTypeId: null,
      csrSupplierCompanies: {},
      options: {
        defaultBodySizes:[],
        customerOptions: [],
        orderStatusOptions: [],
        currencyOptions: {},
        currencyRawOptions: [],
        qcUserOptions: [],
        fabricCompositionOptions: [],
        expensesOptions: [],
        optionalExpenseOptions: [],
        unitOptions: [],
        unitOptions2: [],
        unitOptions3: [],
        bodySizeOptions: [],
        supplierCompanyOptions: [],
        dispatchLocationOptions: [],
        snapTypeOptions: [],
        VATRateOptions: [],
        VATRateOptionsRaw: [],
        currencyRateOptions: [],
        fabricYarnTypeOptions: [],
        testMethodOptions: [],
        fabricKnitTypeOptions: [],
        fabricTypeOptions: [],
        fabricProcessTypeOptions: [],
        fabricAdditionOptions: [],
        fabricPrintTypeOptions: [],
        productProcesses: [],
        formStopOptions:[],
      },
      expenseIncomingData: [],
      tempFormOrderExpensesForQrScanner:[]
    };
  },
  methods: {
    selectReport(selectReport){
      this.selectReportId=selectReport;
      if(selectReport==1){
          this.currentPDF = this.form
        let self = this;
        self.$store.commit(SET_LOADING, true);
        this.$store.dispatch(GET_ITEMS, {
          url: "api/orders/order-pdf-data",
          filters:{
            order:this.form.id
          }
        }).then(result => {
          if(result.status) {
            this.orderReportData=result.data;
            self.$store.commit(SET_LOADING, false);
            self.$refs['display-order-pdf'].show()
          }
        });
      }
    },
    capitalizeStr(str) {
      const modifiedStr = str.toLowerCase();
      let arr = modifiedStr.split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const strCapitalized = arr.join(" ");
      return strCapitalized;
    },
    getUpdatedDate() {
      return this.form.updated_at
        ? moment(this.form.updated_at, LARAVEL_DATE_FORMAT).format(
            MOMENT_SYSTEM_DATE_FORMAT
          )
        : "-";
    },
    getExportDate() {
      return this.form.order_export_dates[0]
        ? moment(this.form.order_export_dates[0].export_date, LARAVEL_DATE_FORMAT).format(
            MOMENT_SYSTEM_DATE_FORMAT
          )
        : "-";
    },
    getExportDate2() {
      return this.form.order_export_dates[1]
        ? moment(this.form.order_export_dates[1].export_date, LARAVEL_DATE_FORMAT).format(
            MOMENT_SYSTEM_DATE_FORMAT
          )
        : "-";
    },
    getOrderFabrics() {
      if (this.form.order_fabrics && this.form.order_fabrics[0]) {
        let start_date = moment(
          this.form.order_fabrics[0].deadline_start,
          LARAVEL_DATE_FORMAT
        ).format(MOMENT_SYSTEM_DATE_FORMAT);
        let end_date = moment(
          this.form.order_fabrics[0].deadline_end,
          LARAVEL_DATE_FORMAT
        ).format(MOMENT_SYSTEM_DATE_FORMAT);
        let order_fabric = start_date + " - " + end_date;
        return order_fabric;
      }
      return "-";
    },
    getOrderFabrics2() {
      if (this.form.order_fabrics && this.form.order_fabrics[1]) {
        let start_date = moment(
          this.form.order_fabrics[1].deadline_start,
          LARAVEL_DATE_FORMAT
        ).format(MOMENT_SYSTEM_DATE_FORMAT);
        let end_date = moment(
          this.form.order_fabrics[1].deadline_end,
          LARAVEL_DATE_FORMAT
        ).format(MOMENT_SYSTEM_DATE_FORMAT);
        let order_fabric_1 = start_date + " - " + end_date;
        return order_fabric_1;
      }
      return "-";
    },
    ...mapMutations({
      setForm: SET_FORM,
      setExpenseDetail: SET_EXPENSE_DETAIL,
      resetForm: RESET_FORM,
    }),
    downloadPdf() {
      this.currentPDF = this.form;
      this.openInNewTab(this.currentPDF.download_pdf);
    },
    onDownloadButtonClick() {
      try{
        this.currentPDF = this.form
        let self = this;
        self.$store.commit(SET_LOADING, true);
        this.$store.dispatch(GET_PDF, {
          url: self.currentPDF.pdf_link,
        }).then(result => {
          if(result.status) {
            self.src = URL.createObjectURL(result.data)
            self.$store.commit(SET_LOADING, false);
            self.$refs['display-order-pdf'].show()
          }
        });
      }catch(e){
        console.log(e);
      }
      
    },
    calculateCost(){
      if(!this.isUserGranted('Order', ['calculateCosts'], false,true)){
        return false;
      }
      let self = this
      self.$store.dispatch(CREATE_ITEM, {
        url: `api/orders/calculate-costs/${self.id}`,
        contents:{
          id:self.id,
          waybillable_type:'App\\Models\\Order'
        }
      }).then((result) => {
        if (result.status) {
          this.setup();
        }
      })
    },

    isValidChangeGeneralTab(){
      if (!this.csr.join_life.length) {
        this.sweetAlertError(this.$t('csr.validations.join_life'));
        return false;
      }
      return true;
    },

    getTotalValue(value, key) {
      let currentValue = this.cloneForce(value);
      return currentValue.amount;
    },
    getOriginalImage() {
      this.imageLinks = [this.form.image_link_large];
      this.imageIndex = 0;
    },
    onChangeSizeAndColor($event, data) {
      data.body_sizes = null;
      data.amount = 0;
      let orderSizeAndColors = this.form.order_size_and_colors;

      let orderSizeAndColorId = Number($event);
      let orderSizeAndColor = _.cloneDeep(
        orderSizeAndColors.find((item) => +item.id === +orderSizeAndColorId)
      );

      let entranceOrderSizeAndColors = this.expenseIncomingData;

      let incomingAmount = entranceOrderSizeAndColors[orderSizeAndColorId]
        ? entranceOrderSizeAndColors[orderSizeAndColorId].amount
        : 0;

      if (+data.expense_type_id !== 2) {
        let incomingBodySizes = entranceOrderSizeAndColors[orderSizeAndColorId]
          ? entranceOrderSizeAndColors[orderSizeAndColorId].body_sizes
          : [];

        incomingBodySizes = _.map(incomingBodySizes, (value, key) => ({
          key,
          value,
        }));
        incomingBodySizes.forEach((item) => {
          let key = item.key;
          orderSizeAndColor.body_sizes[key].amount =
            orderSizeAndColor.body_sizes[key].amount - item.value;
        });

        data.body_sizes = orderSizeAndColor.body_sizes;
      } else {
        data.total = orderSizeAndColor.total - incomingAmount;
      }

      this.defaultTotals = [];
      let tempData = _.cloneDeep(data);
      let newAmount = tempData.total + 0.05 * tempData.total;

      let bodySizes = _.values(tempData.body_sizes);

      bodySizes.map((item) => {
        item.amount = Number(item.amount) + 0.05 * Number(item.amount);
        return item;
      });

      this.defaultTotals.push({
        id: tempData.id,
        total: newAmount,
        body_sizes: bodySizes,
        order_size_and_color_id: orderSizeAndColorId,
      });
    },
    // changeSelectedExpense(expense) {
    //   this.instructionTypeId = Number(expense.expense.instructionable[0].id);
    //   this.expense_data.instructionTypeId = Number(
    //     expense.expense.instructionable[0].id
    //   );
    //   this.expense_data.selectedExpenseID = Number(expense.expense_id);
    //   this.expense_data.typeId = Number(expense.expense.type_id);
    //   this.expense_data.orderExpenseId = Number(expense.id);

    //   this.getSupplierCompanies();
    //   this.getIncomingExpense();
    // },
    changeSelectedExpense(index) {
      let expense = this.form.order_expenses[index.target.value];
      this.instructionTypeId = Number(expense.expense.instructionable[0].id);
      this.expense_data.instructionTypeId = Number(expense.expense.instructionable[0].id);
      this.expense_data.selectedExpenseID = Number(expense.expense_id);
      this.expense_data.typeId = Number(expense.expense.type_id);
      this.expense_data.orderExpenseId = Number(expense.id);

      this.getSupplierCompanies();
      this.getIncomingExpense();
    },
    getInstructionTypes() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/instruction-type",
          acceptPromise: true,
        })
        .then((result) => {
          if (result.status) {
            self.instructionTypes = result.data;
          }
        });
    },
    closeModal(name = "open_to_settings_table") {
      this.$refs[name].hide();
    },
    onCloseModal() {
      this.instructions = null;
      this.$refs["instructions-modal"].hide();
    },
    showInstructions() {
      let self = this;
      let filters = {
        instruction_type: "O-" + self.id,
      };
      self.$store.commit(SET_LOADING, true);

      self.$store
        .dispatch(GET_ITEMS, {
          url: `api/instructions`,
          filters: filters,
        })
        .then((result) => {
          self.$store.commit(SET_LOADING, false);
          if (result.status) {
            self.instructions = result.data;
            self.$refs["instructions-modal"].show();
          } else {
            self.instructions = null;
          }
        });

      self.$store
        .dispatch(GET_ITEMS, {
          url: `api/orders/${this.id}/order-expenses`,
          filters: filters,
        })
        .then((result) => {
          self.$store.commit(SET_LOADING, false);
          if (result.status) {
            self.expenses = result.data;
          } else {
            self.expenses = null;
          }
        });
    },
    showWaybills(itemId, type = 1, name = "") {
      let self = this;
      if (+type === 1) {
        this.$router.push({
          name: "waybill.detail.index",
          params: {
            id: "O-" + self.id,
            type: type,
            detailId: itemId,
            name: name,
          },
        });
      }
      if (+type === 2) {
        this.$router.push({
          name: "waybill.detail.index",
          params: {
            id: "O-" + self.id,
            type: type,
            detailId: itemId,
            name: name,
          },
        });
      }
      if (+type === 3) {
        let product_id = itemId.order_fabric.id;
        let order_size_and_color_id = itemId.order_size_and_color.id;
        this.$router.push({
          name: "waybill.detail.index",
          params: {
            id: "O-" + self.id,
            type: type,
            detailId: product_id,
            sizeAndColorId: order_size_and_color_id,
            name: name,
          },
        });
      }
    },
    sendExpenseDetail(expenseDetail, expenseDetailIndex) {
      let self = this;
      let payloads = {
        url: "api/order-expense-details/" + expenseDetail.id + "/share",
      };

      this.sweetAlertLoading();
      this.$store
        .dispatch(CREATE_ITEM, payloads)
        .then((response) => {
          self.sweetAlertClose();
          if (response.status) {
            self.sweetAlertSuccess(self.$t("general.success"));
          } else {
            let result = response.data.response;
            if (result.hasOwnProperty("data") && result.data.hasOwnProperty("message")) {
              self.sweetAlertError(result.data.message);
            } else {
              self.sweetAlertError(result.data);
            }
          }
        })
        .catch((response) => {
          self.sweetAlertClose();
          let result = response.data.response;
          if (result.hasOwnProperty("data") && result.data.hasOwnProperty("message")) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        });
    },
    cancelExpenseDetail(expenseDetail, expenseDetailIndex) {
      if (this.is_submitting) return;
      this.is_submitting = true;
      let self = this;
      this.sweetAlertConfirm(this.$t("general.are_you_sure")).then((res) => {
        if (res) {
          self.$store
            .dispatch(DELETE_ITEM_BY_ID, {
              url: `api/orders/${self.form.id}/expenses/1/details/${expenseDetail.id}`,
            })
            .then((result) => {
              if (result.status) {
                self.sweetAlertSuccess(self.$t("general.successfully_deleted"));
                if (this.currentOrderExpenseDetail.order_expense_details.length === 1) {
                  this.addNewExpenseDetail();
                }
                this.currentOrderExpenseDetail.order_expense_details.splice(
                  expenseDetailIndex,
                  1
                );
              } else {
                this.sweetAlertError(this.$t("order.cannot_delete_expense_has_waybill"));
              }

              self.is_submitting = false;
            });
        } else {
          self.is_submitting = false;
        }
      });
    },
    addNewTarget(index) {
      const i = this.currentOrderExpenseDetail.order_expense_details[index]
        .order_expense_detail_targets.length;
      let targetItem = this.currentOrderExpenseDetail.order_expense_details[index]
        .order_expense_detail_targets[i - 1];

      targetItem = moment(targetItem.date).add(1, "day");

      const dayNumber = targetItem.isoWeekday();
      if (dayNumber === 7) {
        targetItem.add(1, "days");
      }
      this.currentOrderExpenseDetail.order_expense_details[
        index
      ].order_expense_detail_targets.push({
        date: targetItem.format("YYYY-MM-DD"),
        target: null,
      });
    },
        isVisibleDeleteTarget(payload){
      let today=moment().subtract(1,'d').format('YYYY-MM-DD');
      let date=moment(payload).format('YYYY-MM-DD')
      if(today>=date){
        return false;
      }
      return true;
    },
    deleteNewTarget(index, target_index) {
      if(this.instructionTypeId==28 || this.instructionTypeId==29 || this.instructionTypeId==30){
          if( this.currentOrderExpenseDetail.order_expense_details[index].order_expense_detail_targets[target_index].id&&this.currentOrderExpenseDetail.order_expense_details[index].order_expense_detail_targets[target_index].actual>0){
          this.sweetAlertError(this.$t('order.you_cannot_delete_it_because_the_production'));
          return false;
        }
      }
      
      this.currentOrderExpenseDetail.order_expense_details[index].order_expense_detail_targets.splice(target_index, 1);
    },
    changeToEditMode(expenseDetail, expendDetailIndex) {
      expenseDetail.is_editing = true;
    },
    fixedAndUpdateOrderExpenseDetails(data) {
      let amount = 0;
      if (Number(data.expense_type_id) !== 2) {
        let items = _.values(data.body_sizes);
        items.forEach((subItem) => {
          amount = Number(amount) + Number(subItem.amount);
        });
      } else {
        amount = Number(amount) + Number(data.total);
      }
      data.total = Number(amount);
      data.amount = Number(amount);
      data.total_amount = Number(amount);

      data.start_date = moment(data.start_date).format(LARAVEL_DATE_FORMAT);
      data.end_date = moment(data.end_date).format(LARAVEL_DATE_FORMAT);
      data.end_date = moment(data.end_date).format(LARAVEL_DATE_FORMAT);
      data.body_sizes = JSON.stringify(_.cloneDeepWith(data.body_sizes));
      data.order_expense_detail_targets.forEach((subItem) => {
        subItem.date = moment(subItem.date).format(LARAVEL_DATE_FORMAT);
      });

      return data;
    },
    isValidToCreateInstruction(expenseDetailIndex) {
      if (!this.currentOrderExpenseDetail) return false;
      let current = this.currentOrderExpenseDetail.order_expense_details[
        expenseDetailIndex
      ];
      let currentExpense = this.form.order_expenses.find(
        (item) => +item.id === +current.order_expense_id
      );
      let ignoreCheckPrice=currentExpense.expense.ignore_check_price;

      if (!current || !currentExpense) return false;
      let planned = parseFloat(currentExpense.planned);

      if (Number(current.supplier_company_id) === 0) return "select_supplier_company";

      if (Number(current.dispatch_location_id) === 0) return "select_dispatch_location";
      if (Number(current.currency_unit_id) === 0) return "select_currency_unit";

      if (current.expiry_id === null) return "select_expiry";
      if (
        Number(this.expense_data.selectedExpenseID) === 17 &&
        Number(current.snap_type_id) === 0
      )
        return "select_snap_type";

      if (Number(current.expense_type_id) !== 2) {
        if (current.body_sizes === {}) return "select_body_size";
      }
      if (!current.order_size_and_color_id) return "select_size_and_color";
      if (Number(current.unit_id) === 0) return "select_unit";
      if (Number(current.price) === 0) return "enter_price";
      if (ignoreCheckPrice!=1 && Number(current.price) > planned) return 'price_not_gt_planned';
      if (Number(current.vat_rate_id) === 0) return "select_vat_rate";

      let totalAmount = 0;

      this.currentOrderExpenseDetail.order_expense_details.forEach((item) => {
        let amount = 0;
        if (Number(item.expense_type_id) !== 2) {
          let items = _.values(item.body_sizes);
          items.forEach((subItem) => {
            amount += Number(subItem.amount);
          });
          totalAmount += amount;
        } else {
          totalAmount = item.total;
        }
      });

      const orderExpenseAmount = +this.form.order_expenses.find(
        (orderExpenseItem) => +orderExpenseItem.id === +current.order_expense_id
      ).amount;
      const orderAmount = this.form.amount * orderExpenseAmount;
      const amountWithCoefficient =
        orderAmount + (orderAmount * this.expenseCoefficient) / 100;

      if (+totalAmount > amountWithCoefficient) return "amount_not_gt_order";

      let productAmount = 0;

      let amount = 0;
      if (Number(current.expense_type_id) !== 2) {
        let items = _.values(current.body_sizes);
        items.forEach((subItem) => {
          amount += Number(subItem.amount);
        });
        productAmount += amount;
      } else {
        productAmount = current.total;
      }

      totalAmount = 0;
      current.order_expense_detail_targets.forEach((target) => {
        totalAmount += parseFloat(target.target);
      });

      if (+totalAmount !== +productAmount) return "amount_not_gt_product";

      return true;
    },

    createOrUpdateInstruction(expendDetailIndex) {
      let checkValidations = this.isValidToCreateInstruction(expendDetailIndex);
      if (checkValidations !== true) {
        this.sweetAlertError(this.$t("general." + checkValidations));
        return false;
      }
      let contents = _.cloneDeepWith(this.currentOrderExpenseDetail),
        self = this;
      this.sweetAlertLoading();

      let formData = this.fixedAndUpdateOrderExpenseDetails(
        contents.order_expense_details[expendDetailIndex]
      );

      this.$store
        .dispatch(CREATE_ITEM, {
          url: `api/orders/${this.form.id}/order-expense-details`,
          contents: { order_expense_detail: formData },
        })
        .then((response) => {
          self.sweetAlertClose();
          if (response.status) {
            let item = response.data;
            self.currentOrderExpenseDetail.order_expense_details[expendDetailIndex].id =
              item.id;
            self.currentOrderExpenseDetail.order_expense_details[
              expendDetailIndex
            ].order_pdf_link = item.order_pdf_link;
            self.currentOrderExpenseDetail.order_expense_details[
              expendDetailIndex
            ].order_excel_link = item.order_excel_link;
            self.currentOrderExpenseDetail.order_expense_details[
              expendDetailIndex
            ].is_submitted = true;
            self.currentOrderExpenseDetail.order_expense_details[
              expendDetailIndex
            ].is_editing = false;

            self.sweetAlertSuccess(self.$t("general.successfully_saved"));
          } else {
            let result = response.data.response;
            if (result.hasOwnProperty("data") && result.data.hasOwnProperty("message")) {
              self.sweetAlertError(result.data.message);
            } else {
              self.sweetAlertError(result.data);
            }
          }
        });
    },
    getIncomingExpense() {
      let self = this;
      let expenseId = this.expense_data.orderExpenseId;

      let url = "api/order/" + this.id + "/order-expense/" + expenseId + "/amount";
      this.$store
        .dispatch(GET_ITEMS, {
          url: url,
          acceptPromise: true,
        })
        .then((result) => {
          if (result.status) {
            self.expenseIncomingData = result.data;

            /*
                    if (self.netIncomingFabricAmount == fabricItem.amount) {
                      self.sweetAlertClose();
                      setTimeout(() => {
                        self.sweetAlertError("general.fabric_requests_completed");
                      }, 100)
                    }*/
          }
        });
    },
    addNewExpenseDetail() {
      let data = _.cloneDeepWith(
        this.expense_details[
          this.expense_data.selectedExpenseID
        ].order_expense_details.slice(-1)[0]
      );
      this.getIncomingExpense();
      let expenseDetailTemplate = {
        id: null,
        is_submitted: false,
        is_editing: true,
        order_size_and_color_id: null,
        order_expense_item_type_id: null,
        supplier_company_id: null,
        dispatch_location_id: null,
        currency_unit_id: "53",
        exchange_rate: "1",
        to_who: "",
        price: data.price,
        body_sizes: {},
        expiry_id: null,
        start_date: moment(),
        end_date: moment(),
        description: null,
        amount_per_parcel: 1,
        instruction_description: null,
        order_pdf_link: null,
        order_excel_link: null,
        snap_type_id: null,
        order_expense_detail_targets: [
          {
            date: moment(),
            target: null,
          },
        ],
      };

      let tmpData = { ...data, ...expenseDetailTemplate };

      this.expense_details[
        this.expense_data.selectedExpenseID
      ].order_expense_details.push(tmpData);
    },
    deleteNewExpenseDetail(index) {
      this.expense_details[
        this.expense_data.selectedExpenseID
      ].order_expense_details.splice(-1);
    },
    onExpenseDetailCurrencyChange(payload) {
      let index = payload.name.split("-")[1],
        newValue = payload.newValue,
        oldValue = payload.oldValue;
      let rate = 1;
      this.expense_details[this.expense_data.selectedExpenseID].order_expense_details[
        index
      ].exchange_rate = rate;

      if (!newValue) return;
      if (newValue[0] in this.options.currencyOptions) {
        rate = Number(
          this.options.currencyRateOptions[this.options.currencyOptions[newValue[0]]].rate
        );
      }
      for (
        let i = 0;
        i < this.currentOrderExpenseDetail.order_expense_details.length;
        i++
      ) {
        this.currentOrderExpenseDetail.order_expense_details[i].currency_unit_id =
          newValue[0];
      }
      this.expense_details[this.expense_data.selectedExpenseID].order_expense_details[
        index
      ].exchange_rate = rate;
    },
    convertReturnOrderValueToValidFormData(data) {
      let self = this;
      if (Number(data.sample_id) !== 0 && !isNaN(Number(data.sample_id)) && false) {
        this.$store
          .dispatch(GET_ITEM_DETAIL_BY_ID, {
            url: `api/samples/${data.sample_id}`,
            id: data.sample_id,
          })
          .then((result) => {
            if (result.status) {
              self.form.sample = result.data;
            }
          });
      }
      if (Number(data.esc_table_id) !== 0) {
        this.$store
          .dispatch(GET_ITEM_DETAIL_BY_ID, {
            url: `api/esc-tables/${data.esc_table_id}?single=1`,
            id: data.esc_table_id,
          })
          .then((result) => {
            if (result.status) {
              self.form.esc = result.data;
              self.form.esc_table_id = result.data.id;
            }
          });
      }
      data.status_id = data.status_id.toString();
      this.orderDate = data.order_date;
      if ("order_export_dates" in data) {
        data.order_export_dates = data.order_export_dates.map((value, index) => {
          value.export_date = moment(value.export_date, LARAVEL_DATE_FORMAT);
          return value;
        });
      }
      this.setExpenseDetail({});
      let body_sizes = [];
      if ("order_size_and_colors" in data) {
        data.order_size_and_colors = data.order_size_and_colors.map((value, index) => {
          try {
            value.body_sizes = JSON.parse(value.body_sizes);
            body_sizes.push({ ...value.body_sizes });
          } catch (e) {
            console.error(e);
            value.body_sizes = {};
            this.defaultBodySizes = null;
            body_sizes.push({});
          }

          return value;
        });
      }

      if ("order_fabrics" in data) {
        data.order_fabrics = data.order_fabrics.map((value, index) => {
          value.deadline_start = moment(value.deadline_start, LARAVEL_DATE_FORMAT);
          value.deadline_end = moment(value.deadline_end, LARAVEL_DATE_FORMAT);
          value.selected_order_fabric_compositions = [];

          if ("order_fabric_compositions" in value) {
            value.order_fabric_compositions = value.order_fabric_compositions.map(
              (fabric_composition, i) => {
                value.selected_order_fabric_compositions.push(
                  fabric_composition.fabric_composition_id
                );
                return fabric_composition;
              }
            );
          }
          return value;
        });
      }

      if ("order_expenses" in data) {
        data.selected_expenses = [];
        data.order_expenses = data.order_expenses.map((value, index) => {
          data.selected_expenses.push(value.expense_id);
          if (value.expense_id in this.options.expensesOptions) {
            value.name = this.options.expensesOptions[value.expense_id].name;
            value.is_default = 0;
          } else if (value.expense_id in this.options.optionalExpenseOptions) {
            value.name = this.options.optionalExpenseOptions[value.expense_id].name;
            value.is_default = 1;
          }
          this.expense_details[value.expense_id] = {};
          if (
            "order_expense_details" in value &&
            value.order_expense_details.length > 0
          ) {
            this.expense_details[
              value.expense_id
            ].order_expense_details = value.order_expense_details.map(
              (order_expense_detail, index) => {
                order_expense_detail.start_date = moment(
                  order_expense_detail.start_date,
                  LARAVEL_DATE_FORMAT
                );
                order_expense_detail.end_date = moment(
                  order_expense_detail.end_date,
                  LARAVEL_DATE_FORMAT
                );
                order_expense_detail.select_dispatch_locations = [];
                order_expense_detail.expense_type_id = value.expense.type_id;
                order_expense_detail.is_submitted = true;
                order_expense_detail.is_editing = false;
                order_expense_detail.pdf_link = order_expense_detail.order_pdf_link;

                if (
                  "order_expense_detail_dispatch_locations" in order_expense_detail &&
                  order_expense_detail.order_expense_detail_dispatch_locations.length > 0
                ) {
                  order_expense_detail.order_expense_detail_dispatch_locations.forEach(
                    (dispatch_location_detail) => {
                      order_expense_detail.select_dispatch_locations.push(
                        dispatch_location_detail.dispatch_location_id
                      );
                    }
                  );
                }

                try {
                  order_expense_detail.body_sizes = JSON.parse(
                    order_expense_detail.body_sizes
                  );
                } catch (e) {
                  order_expense_detail.body_sizes = {};
                }

                if (
                  !("order_expense_detail_targets" in order_expense_detail) &&
                  order_expense_detail.order_expense_detail_targets
                ) {
                  order_expense_detail.order_expense_detail_targets = {
                    date: moment(),
                    target: null,
                  };
                } else {
                  order_expense_detail.order_expense_detail_targets = order_expense_detail.order_expense_detail_targets.map(
                    (target) => {
                      target.date = moment(target.date, LARAVEL_DATE_TIME_FORMAT);
                      return target;
                    }
                  );
                }
                return order_expense_detail;
              }
            );
          } else {
            let expense_body_size = {},
              total = 0;
            body_sizes.forEach((body_size, i) => {
              for (let key in body_size) {
                if (key in expense_body_size) {
                  expense_body_size[key].amount =
                    Number(expense_body_size[key].amount) + Number(body_size[key].amount);
                  expense_body_size[key].max_amount =
                    Number(expense_body_size[key].amount) + Number(body_size[key].amount);
                } else {
                  expense_body_size[key] = { ...body_size[key] };
                }
                total += Number(body_size[key].amount);
              }
            });

            for (let key in expense_body_size) {
              expense_body_size[key].amount = expense_body_size[key].amount;
              expense_body_size[key].max_amount = expense_body_size[key].amount;
            }

            let tmpExpenseTypeId = 1;
            if (value.expense_id && value.expense_id in this.options.expensesOptions) {
              tmpExpenseTypeId = this.options.expensesOptions[value.expense_id].type_id;
            }

            if (
              value.expense_id &&
              value.expense_id in this.options.optionalExpenseOptions
            ) {
              tmpExpenseTypeId = this.options.optionalExpenseOptions[value.expense_id]
                .type_id;
            }

            this.expense_details[value.expense_id] = {
              order_expense_details: [
                {
                  id: null,
                  is_submitted: false,
                  is_editing: true,
                  order_expense_id: value.id,
                  expense_type_id: tmpExpenseTypeId,
                  order_size_and_color_id: null,
                  order_expense_item_type_id: null,
                  supplier_company_id: null,
                  dispatch_location_id: null,
                  currency_unit_id: "53",
                  exchange_rate: "1",
                  to_who: "",
                  expiry_id: null,
                  start_date: moment(),
                  end_date: moment(),
                  description: null, // nullable
                  order_pdf_link: null,
                  order_excel_link: null,
                  snap_type_id: null, // nullable if not cit cit
                  body_sizes: null,
                  total: 0,
                  unit_id: value.unit_id,
                  price: value.planned,
                  discount: null,
                  vat_rate_id: null,
                  order_expense_detail_targets: [
                    {
                      date: moment(),
                      target: null,
                    },
                  ],
                },
              ],
            };
          }
          // This line of code make expense_details reactive in vue
          this.setExpenseDetail({ ...this.expense_details });
          return value;
        });
      }

      if (data.hasOwnProperty("csr") && data.csr) {
        if (data.csr && !data.csr.disney_licences) {
          data.csr.disney_licences = {
            license_id: null,
            disney_id: null,
          };
        }

        if (!data.csr.join_life) {
          data.csr.join_life = [];
        }

        this.csr = data.csr;
      }

      data = { ...this.form, ...data };
      this.setForm(data);

      self.imageLinks = [data.image_link];

      this.getSupplierCompanies();
      this.getDefaultBodySize(data.company_id)
    },
    removeSizeFromList(index, key) {
      let self = this;
      this.sweetAlertConfirm(this.$t("general.are_you_sure")).then((result) => {
        if (result) {
          let temp = { ...self.form.order_size_and_colors[index].body_sizes };
          delete temp[key];
          self.$set(self.form.order_size_and_colors[index], "body_sizes", temp);
        }
      });
    },
    getSupplierCompanies() {
      let content = _.clone(this.csr);
      content.company_id = this.form.company_id ? this.form.company_id.toString() : null;

      if (this.instructionTypeId) {
        content.instructionTypeId = this.instructionTypeId;
      } else {
        delete content.instructionTypeId;
      }

      let self = this;
      this.$store
        .dispatch(CREATE_ITEM, {
          url: "api/csr/supplier-companies",
          contents: content,
        })
        .then((response) => {
          if (response.status) {
            let items = [];
            response.data.forEach((item) => {
              items.push({
                id: item.supplier_company.id,
                name: item.supplier_company.name,
              });
            });

            self.options.supplierCompanyOptions = self.convertArrayToObjectByKey(
              items,
              "id",
              "name"
            );
            self.csrSupplierCompanies = response.data;
          }
        });
    },
    submitOrderCsr() {
      if (!this.csr.join_life.length) {
        this.sweetAlertError(this.$t("csr.validations.join_life"));
        return false;
      }
      /*
         if (!this.csr.disney_licences.disney_id) {
             this.sweetAlertError(this.$t('csr.validations.disney_id'));
             return false;
           }

           if (this.csr.disney_licences.disney_id == 1 && !this.csr.disney_licences.license_id) {
             this.sweetAlertError(this.$t('csr.validations.license_id'));
             return false;
           }
     */
      if (this.id) {
        let contents = {
          csr: this.csr,
        };

        this.$store.dispatch(UPDATE_ITEM_BY_ID, {
          url: "api/orders/" + this.id + "/csr",
          contents: contents,
          showLoading: false,
        });
      }
      this.currentActiveTab = 1;
    },
    submitForm() {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      let self = this;
      this.sweetAlertLoading();

      let form = this.cloneForce(this.form);
      form.sale_price_currency_unit_id = form.currency_unit_id;
      for (let i = 0; i < form.order_export_dates.length; i++) {
        form.order_export_dates[i].export_date = moment(
          form.order_export_dates[i].export_date
        ).format(LARAVEL_DATE_FORMAT);
      }
      for (let i = 0; i < form.order_fabrics.length; i++) {
        form.order_fabrics[i].deadline_start = moment(
          form.order_fabrics[i].deadline_start
        ).format(LARAVEL_DATE_FORMAT);
        form.order_fabrics[i].deadline_end = moment(
          form.order_fabrics[i].deadline_end
        ).format(LARAVEL_DATE_FORMAT);
        form.order_fabrics[i].planned =
          Number(form.order_fabrics[i].pastal) * Number(form.order_fabrics[i].unit_price);
      }
      for (let i = 0; i < form.order_size_and_colors.length; i++) {
        form.order_size_and_colors[i].total = this.sizeAndColorTotalByIndex(i);
        form.order_size_and_colors[i].export_total = 0;
        let tempBodySize = _.cloneDeepWith(form.order_size_and_colors[i].body_sizes);
        for (let key in tempBodySize) {
          if (Number(tempBodySize[key].amount) === 0) delete tempBodySize[key];
        }
        let temp = JSON.stringify(tempBodySize);
        form.order_size_and_colors[i] = {
          ...form.order_size_and_colors[i],
          body_sizes: temp,
        };
      }

      form.csr = this.csr;
      form.esc_table_id = this.esc_id;
      let contents = new FormData();
      for (let key in form) {
        if (key === "image") continue;
        this.createFormDataForList(contents, key, form[key]);
      }
      if (form.image) {
        contents.append("image", form.image);
      }

      this.$store
        .dispatch(CREATE_ITEM, {
          url: "api/orders",
          contents: contents,
          showLoading: false,
        })
        .then((result) => {
          if (result.status) {
            self.form.is_submitted = true;
            self.form.image = null;
            self.convertReturnOrderValueToValidFormData(result.data);
            self.sweetAlertClose();
            self.sweetAlertSuccess(self.$t("general.successfully_created"));
            self.is_submitting = false;
            self.$router.push({ name: "order.index" });
          } else {
            self.isSubmitting = false;
            self.form.is_submitted = true;
            self.is_submitting = false;

            let response = result.data.response;
            if (
              response.hasOwnProperty("data") &&
              response.data.hasOwnProperty("message")
            ) {
              self.sweetAlertError(response.data.message);
            } else {
              self.sweetAlertError(response.data);
            }
          }
        })
        .catch((result) => {
          self.isSubmitting = false;
          self.$store.commit(SET_LOADING, false);
          self.sweetAlertClose();
        });
    },
    updateOrder() {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      let self = this;
      this.sweetAlertLoading();
      let form = this.cloneForce(this.form);
      form.order_expenses.map((item, index) => {
        delete item.order_expense_details;
      });
      delete form.esc;
      delete form.csr;
      delete form.status;
      delete form.company;
      delete form.order_fabric_requirements.order_fabric;
      delete form.order_fabric_requirements.order_size_and_color;

      form.sale_price_currency_unit_id = form.currency_unit_id;
      for (let i = 0; i < form.order_export_dates.length; i++) {
        form.order_export_dates[i].export_date = moment(
          form.order_export_dates[i].export_date
        ).format(LARAVEL_DATE_FORMAT);
      }
      for (let i = 0; i < form.order_fabrics.length; i++) {
        form.order_fabrics[i].deadline_start = moment(
          form.order_fabrics[i].deadline_start
        ).format(LARAVEL_DATE_FORMAT);
        form.order_fabrics[i].deadline_end = moment(
          form.order_fabrics[i].deadline_end
        ).format(LARAVEL_DATE_FORMAT);
        form.order_fabrics[i].planned =
          Number(form.order_fabrics[i].pastal) * Number(form.order_fabrics[i].unit_price);
      }
      for (let i = 0; i < form.order_size_and_colors.length; i++) {
        form.order_size_and_colors[i].total = this.sizeAndColorTotalByIndex(i);
        let tempBodySize = _.cloneDeepWith(form.order_size_and_colors[i].body_sizes);
        for (let key in tempBodySize) {
          if (Number(tempBodySize[key].amount) === 0) delete tempBodySize[key];
        }
        let temp = JSON.stringify(tempBodySize);
        form.order_size_and_colors[i] = {
          ...form.order_size_and_colors[i],
          body_sizes: temp,
        };
      }

      form.csr = this.csr;

      if (this.esc_id) {
        form.esc_table_id = this.esc_id;
      }

      let contents = new FormData();
      for (let key in form) {
        if (key === "image") continue;
        this.createFormDataForList(contents, key, form[key]);
      }
      if (this.form.image) {
        contents.append("image", this.form.image);
      }
      this.$store
        .dispatch(CREATE_ITEM, {
          url: "api/orders/" + this.id,
          contents: contents,
          showLoading: false,
        })
        .then((result) => {
          self.isSubmitting = false;
          self.form.is_submitted = true;

          if (result.status) {
            self.form.image = null;
            self.convertReturnOrderValueToValidFormData(result.data);
            self.sweetAlertSuccess(self.$t("general.successfully_created"));
            self.$router.push({ name: "order.index" });
          } else {
            let response = result.data.response;
            if (
              response.hasOwnProperty("data") &&
              response.data.hasOwnProperty("message")
            ) {
              self.sweetAlertError(response.data.message);
            } else {
              self.sweetAlertError(response.data);
            }
          }
          self.sweetAlertClose();
        })
        .catch((result) => {
          self.is_submitting = false;
          self.form.is_submitted = true;
          self.$store.commit(SET_LOADING, false);
          self.sweetAlertClose();
        });
    },
    submitAndContinueSupplier() {
      this.currentActiveTab = 2;
    },
    redirectToSelectSample() {
      let query = {
        type: this.type,
        id: this.id,
        redirect: 1,
        redirectName: "order",
        ...this.$route.query,
      };
      this.$router.replace({
        name: "sample",
        query: query,
      });
    },
    redirectToSelectESC() {
      let query = {
        type: this.type,
        id: this.id,
        redirect: 1,
        in_use: 0,
        redirectName: "order",
        ...this.$route.query,
      };
      this.$router.replace({
        name: "esc-meeting.index",
        query: query,
      });
    },
    onChangeOrderImage() {
      this.form.image = this.$refs.orderImage.files[0];
      let imageHolder = $("#image_holder");
      imageHolder.attr("src", URL.createObjectURL(this.form.image));
    },
    addExportDateInfo() {
      this.form.order_export_dates.push({
        export_date: moment(),
        amount: null,
      });
    },
    deleteExportDateInfo(index) {
      this.form.order_export_dates.splice(index, 1);
    },
    resetSizeAndColor(index) {
      this.form.order_size_and_colors[index].body_sizes = this.defaultBodySizes;
      this.form.order_size_and_colors[index].body_sizes=_.cloneDeep(this.defaultBodySizes);
    },
    addNewSizeAndColor() {
      let item = JSON.parse(JSON.stringify(this.form.order_size_and_colors.slice(-1)[0]));
      item.id = null;
      this.form.order_size_and_colors.push(item);
    },
    deleteNewSizeAndColor(index) {
      if(this.form.order_size_and_colors.length>1){
        this.form.order_size_and_colors.splice(index, 1)
      }

    },
    addNewOrderFabric() {
      this.form.order_fabrics.push({
        id: null,
        deadline_start: this.form.order_fabrics.slice(-1)[0].deadline_start,
        deadline_end: this.form.order_fabrics.slice(-1)[0].deadline_end,
        type: null,
        width: null,
        weight: null,
        pastal: null,
        unit_price: null,
        unit_id: this.options.unitOptions3.find((item) => item.code === "KG").id,
        planned: null,
        actual: null,
        selected_order_fabric_compositions: [],
        order_fabric_compositions: [],
      });
      /*      this.form.order_size_and_colors.push({
              order_fabric_id: uuid,
              color_number: null,
              color_name: null,
              body_sizes: _.cloneDeepWith(this.defaultSizeJSON),
              total: null,
              export_total: null,
            })*/
    },
    deleteNewOrderFabric(index) {
      this.form.order_fabrics.splice(index, 1);
    },
    onExpensesSave(payload) {
      let oldValue = payload.oldValue,
        newValue = payload.newValue,
        current = this.form.order_expenses;
      for (let i = 0; i < oldValue.length; i++) {
        if (!newValue.includes(oldValue[i])) {
          for (let j = 0; j < current.length; j++) {
            if (Number(current[j].expense_id) === Number(oldValue[i])) {
              current.splice(j, 1);
              break;
            }
          }
        }
      }
      for (let i = 0; i < newValue.length; i++) {
        if (
          !oldValue.includes(newValue[i]) &&
          !this.form.selected_expenses.includes(newValue[i])
        ) {
          this.form.order_expenses.push({
            actual: null,
            amount: null,
            planned: null,
            currency_unit_id: Object.keys(this.options.currencyOptions)[0].toString(),
            unit_id: this.options.unitOptions3
              .find((item) => item.code === "ADET")
              .id.toString(),
            expense_id: newValue[i],
            is_default: 0,
            name: this.options.expensesOptions[newValue[i]].name,
          });
        }
      }
    },
    DeleteExpensesSave(index) {
      this.form.order_expenses.splice(index, 1);
    },

    getDefaultBodySize(payload){
      let self=this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/body-sizes',
        filters: {
          company_id: payload
        }
      }).then(result => {
        if (result.status) {
          self.options.defaultBodySizes = self.convertArrayToObjectByKey(result.data, 'id');
          let json = {};
          for (let key in self.options.defaultBodySizes) {
            json[key] = {
              body_size_id: key,
              amount: null,
              name: self.getValueByDottedKey(self.options.defaultBodySizes[key], 'translations.0.name')
            }
          }
          self.defaultBodySizes = json;
        }
      })
    },
    onCompanyChange(payload) {
      if (this.form.company_id === null || this.form.company_id === undefined) return;
      if ((payload.oldValue === null || payload.oldValue === undefined) && this.id)
        return;
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/body-sizes",
          filters: {
            company_id: this.form.company_id,
          },
        })
        .then((result) => {
          if (result.status) {
            self.options.bodySizeOptions = self.convertArrayToObjectByKey(
              result.data,
              "id"
            );
            let json = {};
            for (let key in self.options.bodySizeOptions) {
              json[key] = {
                body_size_id: key,
                amount: null,
                name: self.getValueByDottedKey(
                  self.options.bodySizeOptions[key],
                  "translations.0.name"
                ),
              };
            }
            self.defaultSizeJSON = json;
            this.defaultBodySizes=json;
            for (let i = 0; i < self.form.order_size_and_colors.length; i++) {
              self.form.order_size_and_colors[i].instruction_check=true;
              self.form.order_size_and_colors[i].body_sizes = {
                ...self.defaultSizeJSON,
              };
            }
          }
        });
    },
    onOrderFabricCompositionChange(payload) {
      let index = payload.name.split("-")[1],
        oldValue = payload.oldValue,
        newValue = payload.newValue;
      let currentFabricComposition = this.form.order_fabrics[index];

      for (let i = 0; i < oldValue.length; i++) {
        if (!newValue.includes(oldValue[i])) {
          for (
            let j = 0;
            j < currentFabricComposition.order_fabric_compositions.length;
            j++
          ) {
            if (
              Number(
                currentFabricComposition.order_fabric_compositions[j]
                  .fabric_composition_id
              ) === Number(oldValue[i])
            ) {
              currentFabricComposition.order_fabric_compositions.splice(j, 1);
              break;
            }
          }
        }
      }
      let n = newValue.length - currentFabricComposition.order_fabric_compositions.length;
      let remaining = 0;
      for (
        let i = 0;
        i < currentFabricComposition.order_fabric_compositions.length;
        i++
      ) {
        remaining += Number(currentFabricComposition.order_fabric_compositions[i].amount);
      }
      let newPercent = 0;
      if (100 - remaining !== 0 || n > 0) {
        newPercent = ((100 - remaining) / n).toFixed(0);
      }
      if (Number(newPercent) < 0) newPercent = 0;

      for (let i = 0; i < newValue.length; i++) {
        if (
          !oldValue.includes(newValue[i]) &&
          !currentFabricComposition.selected_order_fabric_compositions.includes(
            newValue[i]
          )
        ) {
          currentFabricComposition.order_fabric_compositions.push({
            fabric_composition_id: newValue[i],
            amount: newPercent,
            valid: false,
          });
        }
      }
    },
    setup() {
      if (this.$route.query === this.$route.query) {
        this.resetForm();
      }
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$t("general.dashboard"), route: "dashboard" },
        { title: this.$t("order.orders"), route: "order.index" },
        {
          title: this.id
            ? this.$t("order.edit_order")
            : this.type === 1
            ? this.$t("order.create_order_from_esc")
            : this.$t("order.create_order_from_sample"),
        },
      ]);
      this.sweetAlertLoading();
      let self = this,
        promises = [];

      if (this.esc_id !== null) {
        this.form.esc_table_id = this.esc_id;
      }

      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/companies",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/statuses",
          filters: {
            type_id: 2,
          },
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/currency",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/users/qc-staff",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-compositions",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/expenses",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/units",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/snap-types",
        })
      );
      promises.push(
        this.$store.dispatch(CREATE_ITEM, {
          url: "api/csr/supplier-companies",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/currency-rate",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/vat-rates",
          filters: {},
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-yarn-types",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-knit-types",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-types",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-process",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/fabric-additions",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/press-types",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/expiries",
        })
      );
      promises.push(
        this.$store.dispatch(GET_ITEMS, {
          url: "api/product-process",
        })
      );

      promises.push(this.$store.dispatch(CREATE_ITEM, {
        url: 'api/order/form-stop-options',
      }));

      Promise.all(promises).then((results) => {
        if (results[0].status) {
          self.options.customerOptions = self.convertArrayToObjectByKey(
            results[0].data,
            "id",
            "name"
          );
        }
        if (results[1].status) {
          self.options.orderStatusOptions = self.convertArrayToObjectByKey(
            results[1].data,
            "id",
            "translations.0.name"
          );
          self.form.status_id = Object.keys(
            self.options.orderStatusOptions
          )[0].toString();
        }
        if (results[2].status) {
          let items = results[2].data;
          self.options.currencyOptions = self.convertArrayToObjectByKey(
            items,
            "id",
            "code"
          );
          self.form.currency_unit_id = items.find((item) => item.code == "TRY").id;
          self.options.currencyRawOptions = items;
        }
        if (results[3].status) {
          self.options.qcUserOptions = self.convertArrayToObjectByKey(
            results[3].data,
            "id",
            "name"
          );
        }
        if (results[4].status) {
          self.options.fabricCompositionOptions = self.convertArrayToObjectByKey(
            results[4].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[6].status) {
          self.options.unitOptions = self.convertArrayToObjectByKey(
            results[6].data,
            "id",
            "translations.0.name"
          );
          let tmpUnits = self.convertArrayToObjectByKey(
            results[6].data,
            "id",
            "translations.0.name"
          );
          self.options.unitOptions2 = tmpUnits;
          self.options.unitOptions3 = results[6].data;
        }
        if (results[5].status) {
          let temp = results[5].data;
          self.form.order_expenses = [];
          let tempExpenses = [],
            tempOptionalExpenses = [],
            tempFormOptionExpenses = [];
          for (let i = 0; i < temp.length; i++) {
            {
              if (Number(temp[i].is_default) === 1) {
                self.form.order_expenses.push({
                  planned: null,
                  actual: null,
                  amount: null,
                  unit_id: this.options.unitOptions3
                    .find((item) => item.code === "ADET")
                    .id.toString(),
                  expense_id: temp[i].id,
                  is_default: Number(temp[i].is_default),
                  ignore_check_price: Number(temp[i].ignore_check_price),
                  currency_unit_id: Object.keys(
                    self.options.currencyOptions
                  )[0].toString(),
                  name: temp[i].translations[0].name,
                  type_id: temp[i].type_id,
                });
                tempOptionalExpenses.push(temp[i]);
                tempFormOptionExpenses.push({
                  expense_id: temp[i].id,
                  amount: null,
                  name: temp[i].translations[0].name,
                  type_id: temp[i].type_id,
                });
              } else {
                tempExpenses.push({
                  expense_id: temp[i].id,
                  amount: null,
                  name: temp[i].translations[0].name,
                  type_id: temp[i].type_id,
                });
              }
            }
          }
          self.options.expensesOptions = self.convertArrayToObjectByKey(
            tempExpenses,
            "expense_id"
          );
          self.options.optionalExpenseOptions = self.convertArrayToObjectByKey(
            tempFormOptionExpenses,
            "expense_id"
          );
          // self.options.form.optionalExpenses = [...tempFormOptionExpenses];

          if (self.esc_id !== 0 && !isNaN(self.esc_id)) {
            self.$store
              .dispatch(GET_ITEMS, {
                url: `api/esc-tables/${self.esc_id}?single=1`,
              })
              .then((result) => {
                if (result.status) {
                  self.form.image_link = result.data.image_link;
                  self.form.image_link_large = result.data.image_link_large;
                  self.form.esc_id = result.data.id;
                  self.form.esc = result.data;
                  self.form.company_id = result.data.company_id;
                  self.form.model = result.data.model;
                  self.form.currency_unit_id = result.data.currency_unit_id;
                  self.form.description = result.data.description;
                  self.form.instruction_description = result.data.instruction_description;
                  self.form.amount_per_parcel = result.data.amount_per_parcel;

                  if (!self.id) {
                    let tempExpenses = self.convertArrayToObjectByKey(
                      result.data.esc_table_expenses,
                      "expense_id",
                      "amount"
                    );
                    let tempOrderExpenses = self.convertArrayToObjectByKey(
                      [...self.form.order_expenses],
                      "expense_id"
                    );
                    for (let key in tempExpenses) {
                      if (key in tempOrderExpenses) {
                        let index = Object.keys(tempOrderExpenses).indexOf(key);
                        self.form.order_expenses[index].planned = tempExpenses[key];
                        self.form.order_expenses[index].amount = 1;
                      } else {
                        let name = "";
                        if (key in self.options.expensesOptions) {
                          name = self.options.expensesOptions[key].name;
                        } else {
                          name = self.options.optionalExpenseOptions[key].name;
                        }
                        self.form.order_expenses.push({
                          actual: null,
                          amount: 1,
                          planned: tempExpenses[key],
                          currency_unit_id: Object.keys(
                            this.options.currencyOptions
                          )[0].toString(),
                          unit_id: this.options.unitOptions3
                            .find((item) => item.code === "ADET")
                            .id.toString(),
                          expense_id: key,
                          is_default: 0,
                          name: name,
                        });
                      }
                    }
                    if (result.data.fabric_calculations.length > 0) {
                      self.form.order_fabrics = [];
                      result.data.fabric_calculations.forEach((fabric, index) => {
                        fabric.start_date = moment(fabric.start_date).format(
                          LARAVEL_DATE_FORMAT
                        );
                        fabric.end_date = moment(fabric.end_date).format(
                          LARAVEL_DATE_FORMAT
                        );
                        fabric.type = fabric.fabric;
                        fabric.pastal = fabric.consumption;
                        fabric.unit_price = fabric.price;
                        fabric.unit_id = this.options.unitOptions3
                          .find((item) => item.id === +fabric.unit_id)
                          .id.toString();
                        fabric.planned = null;
                        fabric.actual = null;
                        fabric.selected_order_fabric_compositions = [];
                        fabric.order_fabric_compositions = [];
                        self.form.order_fabrics.push({ ...fabric });
                      });
                    }
                  }
                }
              });
          }
          if (self.sample_id !== null && !isNaN(self.sample_id)) {
            self.form.sample_id = this.sample_id;
            self.$store
              .dispatch(GET_ITEM_DETAIL_BY_ID, {
                url: `api/samples/${this.sample_id}`,
                id: self.sample_id,
              })
              .then((result) => {
                if (result.status) {
                  self.form.sample = result.data;

                  // TODO: get fabrics
                  if (!self.id && result.data.sample_product_informations.length > 0) {
                    self.form.order_fabrics = [];
                    result.data.sample_product_informations.forEach((fabric) => {
                      fabric.order_id = null;
                      fabric.deadline_start = moment();
                      fabric.deadline_end = moment();
                      fabric.type = fabric.fabric_type.translations[0].name;
                      fabric.pastal = null;
                      fabric.unit_id = this.options.unitOptions3
                        .find((item) => item.id === +fabric.unit_id)
                        .id.toString();
                      fabric.planned = null;
                      fabric.actual = null;
                      fabric.selected_order_fabric_compositions = [];
                      fabric.order_fabric_compositions = [];

                      if ("fabric_composition_sample_product_informations" in fabric) {
                        fabric.fabric_composition_sample_product_informations.forEach(
                          (composition) => {
                            fabric.selected_order_fabric_compositions.push(
                              composition.fabric_composition_id
                            );
                            fabric.order_fabric_compositions.push({
                              fabric_composition_id: composition.fabric_composition_id,
                              amount: composition.percent,
                              valid: false,
                            });
                          }
                        );
                      }

                      self.form.order_fabrics.push({ ...fabric });
                    });
                  }
                }
              });
          }
          if (self.id) {
            self.form.is_submitted = true;
            self.currentActiveTab = 4;

            self.getInstructionTypes();
            self.$store
              .dispatch(GET_ITEM_DETAIL_BY_ID, {
                url: `api/orders/${self.id}`,
              })
              .then((result) => {
                if (result.status) {
                  self.tempFormOrderExpensesForQrScanner=result.data.order_expenses;
                  self.convertReturnOrderValueToValidFormData(result.data);
                  if(this.$route.query.qr_scanner && this.$route.query.qr_type=="expense"){
                  let self =this;
                  self.currentActiveTab=2;
                  let expense = this.$route.query.expense
                  this.changeSelectedExpense(expense)
                }
                if(this.$route.query.qr_scanner && this.$route.query.qr_type){
                  let self =this;
                  self.currentActiveTab=2;
                  self.activeTab=2;
                }
                }
              });
          } else {
            self.form.fabric_supply_type_id = null;
          }
        }

        if (results[8].status) {
          self.options.dispatchLocationOptions = self.convertArrayToObjectByKey(
            results[8].data,
            "supplier_company_id",
            "supplier_company.name"
          );
        }
        if (results[9].status) {
          self.options.currencyRateOptions = results[9].data.data;
        }
        if (results[10].status) {
          self.options.VATRateOptionsRaw = results[10].data;
        }
        if (results[11].status) {
          self.options.fabricYarnTypeOptions = self.convertArrayToObjectByKey(
            results[11].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[12].status) {
          self.options.fabricKnitTypeOptions = self.convertArrayToObjectByKey(
            results[12].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[13].status) {
          self.options.fabricTypeOptions = self.convertArrayToObjectByKey(
            results[13].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[14].status) {
          self.options.fabricProcessTypeOptions = self.convertArrayToObjectByKey(
            results[14].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[15].status) {
          self.options.fabricAdditionOptions = self.convertArrayToObjectByKey(
            results[15].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[16].status) {
          self.options.fabricPrintTypeOptions = self.convertArrayToObjectByKey(
            results[16].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[17].status) {
          self.options.expirationTypeOptions = self.convertArrayToObjectByKey(
            results[17].data,
            "id"
          );
        }
        if (results[18].status) {
          self.options.productProcesses = self.convertArrayToObjectByKey(
            results[18].data,
            "id",
            "translations.0.name"
          );
        }
        if (results[7].status) {
          self.options.snapTypeOptions = self.convertArrayToObjectByKey(
            results[7].data,
            "id",
            "translations.0.name"
          );
        }

        if (results[19].status) {
          self.options.formStopOptions = self.convertArrayToObjectByKey(results[19].data, 'id', 'translation.name');
        }
        self.sweetAlertClose();
      });
    },
  },
  mounted() {
    if (!this.isUserGranted("Order", ["create", "update", "view"])) {
      return false;
    }

    this.setup();
    if(this.$route.query.qr_scanner && this.$route.query.qr_type=="expense"){
      setTimeout(() => {
          let instructionsId = this.$route.query.instruction_id
          let element = this.$refs["expense_"+instructionsId][0]
          if(element){
            element.scrollIntoView({behavior: "smooth"})
          }
        }, 2500);
    }
  },
  watch: {
    vatRateOptions(value) {
      if (value) {
        this.options.VATRateOptions = value;
      }
    },
    $route() {
      this.id = this.$route.params.id;
      this.type = Number(this.$route.params.type);
      this.esc_id = Number(this.$route.query.esc_id);
      this.sample_id = Number(this.$route.query.sample_id);
      this.setup();
    },
    "form.order_expenses": {
      handler: function (newValue, oldValue) {
        if (newValue === undefined || oldValue === undefined) return;
        newValue.map((item) => {
          item.planned = item.planned ? item.planned.replace(",", ".") : item.planned;
          return item;
        });
      },
      deep: true,
    },
    "form.order_fabrics": {
      handler: function (newValue, oldValue) {
        newValue.map((item) => {
          item.pastal = item.pastal ? item.pastal.replace(",", ".") : item.pastal;
          item.unit_price = item.unit_price
            ? item.unit_price.replace(",", ".")
            : item.unit_price;
          return item;
        });
      },
      deep: true,
    },
    "form.company_id"(newValue, oldValue) {
      this.onCompanyChange({ newValue, oldValue });
    },
    currentActiveTab(value) {
      this.instructionTypeId = null;
      if (Number(value) === 2 && this.expense_data.selectedExpenseID === null) {
        this.expense_data.selectedExpenseID = Number(
          this.form.order_expenses[0].expense_id
        );
        this.expense_data.typeId = Number(this.form.order_expenses[0].expense.type_id);
        this.expense_data.orderExpenseId = Number(this.form.order_expenses[0].id);
        this.instructionTypeId = Number(
          this.form.order_expenses[0].expense.instructionable[0].id
        );
        this.expense_data.instructionTypeId = Number(
          this.form.order_expenses[0].expense.instructionable[0].id
        );
        this.options.supplierCompanyOptions=[];
        this.csrSupplierCompanies=[];

        this.getSupplierCompanies();
      }
    },
    activeTab(value) {
      this.instructionTypeId = null;

      if (Number(value) === 1) {
        this.expense_data.selectedExpenseID = Number(
          this.form.order_expenses[0].expense_id
        );
        this.expense_data.typeId = Number(this.form.order_expenses[0].expense.type_id);
        this.expense_data.orderExpenseId = Number(this.form.order_expenses[0].id);
        this.instructionTypeId = Number(
          this.form.order_expenses[0].expense.instructionable[0].id
        );
        this.expense_data.instructionTypeId = Number(
          this.form.order_expenses[0].expense.instructionable[0].id
        );
        this.options.supplierCompanyOptions=[];
        this.csrSupplierCompanies=[];

      }

      if (Number(value) === 2) {
        this.instructionTypeId = null;
      }

      this.getSupplierCompanies();
    },
    "csr.join_life": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          if (newValue.includes("1")) {
            if (newValue.length > 1) {
              this.csr.join_life = ["1"];
            }
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.table thead th,
.table thead td {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.table th,
.table td {
  padding: 0.5rem !important;
  vertical-align: middle !important;
}
</style>
<style>
@media only screen and (max-width: 760px) {
  .test {
    border: 1px solid green;
  }

  .main_wrapper {
    display: flex;
    flex-direction: column;
    height: auto !important;
    font-family: "Poppins", sans-serif !important;
  }

  .card_top {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
  }

  .top_order_number {
    font-weight: bolder !important;
  }

  .card_menu {
    height: 3vh;
    padding: 4px 0;
  }

  .card_top .card_sub_top {
    display: flex;
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 12px;
  }
  .card_top .card_sub_top .card_img {
    order: 1;
    width: 67% !important;
    max-height: 17vh !important;
    position: relative;
    margin-right: 12px;
  }
  .card_top .card_sub_top .card_img img {
    width: 100% !important;
    height: 100% !important;
  }
  .card_top .card_sub_top .card_img .plus_icon {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    width: 8vw !important;
    height: 8vw !important;
  }

  .card_top .card_sub_top .card_qr {
    order: 2;
    width: 40% !important;
    height: 25vw !important;
    position: relative;
    display: flex;
    align-items: flex-start;
  }

  .card_top .card_sub_top .card_qr .card_qr_sub {
    width: 100%;
    height: 67%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .card_top .card_sub_top .card_qr .card_qr_sub .qr_self {
    width: 100%;
    height: 55%;
    margin-bottom: 8px !important;
  }

  .instruct_btn {
    position: absolute;
    bottom: -23%;
    left: 25%;
    background-color: #0b5a85;
    color: #fff;
    margin-top: 4px !important;
    padding: 4px 6px;
  }

  .order_detail {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    justify-content: space-between;
    padding: 0px 4px;
    margin-top: 0 !important;
  }

  .order_detail .order-col {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid rgb(247, 237, 237);
    padding: 4px 0 !important;
  }
  .order_detail .detail_itm {
    color: #101010;
  }
  .card_footer_wrapper {
  }
  .list_order_card_footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .list_order_card_footer span {
    display: block;
    font-weight: 600;
    width: 20vw;
    font-size: 2.5vw;
    padding: 2px 0px;
    text-align: center;
    color: #101010;
    flex-grow: 1;
    border-right: 1px solid rgb(182, 187, 182);
  }

  .list_order_card_footer span:last-child {
    border: none;
  }

  .description_p {
    font-weight: 400;
    padding: 6px 12px;
    display: inline-block;
    font-size: 2.2vw;
    font-family: "Roboto", sans-serif;
  }

  .order_menu_d {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    border-bottom: 1px solid rgb(182, 187, 182);
    margin: 0 !important ;
  }

  .order_menu_d .order_menu_item_d {
    display: inline-block !important;
    font-size: 2.5vw !important;
    color: #757070;
    padding: 4px 8px;
  }

  .order_menu_d .order_menu_item_d_active {
    border-bottom: 2px solid #101010;
    color: #101010;
    font-weight: bolder;
  }

  .join-life .is-valid,
  .join-life .my-input {
    background-color: #f3f6f9 !important;
  }

  .join-life .my-input {
    border-color: #78cdea !important;
  }

  /*Detail inputs */
  .detail_customer_input {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end !important;
    padding: 0 !important;
  }

  .detail_label {
    font-weight: 600;
    font-size: 9px;
    color: #101010;
  }

  .detail_label_soft {
    font-weight: 400;
    font-size: 10px;
  }
  .select-collection-number {
    font-weight: 400;
    font-size: 10px;
  }
  .select-collection-number > span {
    border: 1px solid #78cdea !important;
    margin: 0 !important;
    font-size: 10px !important;
    height: 10px !important;
    display: inline !important;
    background-color: #f3f6f9 !important;
  }

  .detail_customer_input .my-input {
    border: none !important;
    display: flex !important;
    justify-content: flex-end;
    padding: 0 !important;
    height: 10px !important;
    background: transparent !important;
    font-size: 10px !important;
  }

  .detail_customer_input .is-valid {
    background-image: none !important;
    padding: 0 !important;
    margin-top: 4px !important;
  }

  .detail_customer_input .my-text span {
    font-size: 10px !important;
    padding-right: 10px;
  }

  .order-col-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 50%;
  }

  .order-col-row-subs {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  /*ESC Detail Btn */
  .order-col-row-subs .esc_detail_btn {
    border: 1px solid #78cdea !important;
    margin: 0 !important;
    font-size: 10px !important;
    height: 10px !important;
    display: inline !important;
    background-color: #f3f6f9 !important;
  }
  /*PO NO Detail Input */
  .custom_txt_input_main {
    padding: 0 !important;
    margin: 0 2px 0 0 !important;
    max-width: 50px !important;
    max-height: 10px !important;
  }
  .custom_txt_input_detail_wrapper {
    display: flex !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .txt_input_detail_label {
    display: none !important;
  }

  .txt_input_detail_self {
    padding: 0 !important;
    display: inline !important;
    width: 100px !important;
    height: auto !important;
    font-size: 10px !important;
    border-radius: 0px !important;
    border: none !important;
  }

  .detail-bottom-btn {
    font-weight: 600 !important;
    border-radius: 18px !important;
    padding: 2px 10px !important;
    width: 140px !important;
    height: 40px !important;
    font-size: 14px !important;
  }

  .btn-w-icn .button-with-icon-custom-icon {
    margin-right: 12px;
  }

  /*KOLEKSIYON NUMUNESINDEN MASTER */

  .collection-sample-master {
    background-color: #f3f6f9 !important;
  }

  .collection-sample-master .is-valid,
  .collection-sample-master .is-invalid,
  .collection-sample-master .my-input,
  .collection-sample-master .my-date-filter-main input,
  .collection-sample-master .s-input input,
  .collection-sample-master .esc_detail_btn,
  .collection-sample-master .custom-number-input {
    background-color: #f3f6f9 !important;
    border-color: #78cdea !important;
    font-size: 1rem !important;
    border: none !important;
  }

  .collection-sample-master .total-div {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .collection-sample-master .total-div .custom-number-input {
    padding: 0 !important;
    margin: 0 !important;
  }

  .collection-sample-master .total-div .custom-number-input::placeholder {
    color: #545564 !important;
    font-size: 1rem !important;
    text-align: center !important;
  }

  .collection-sample-master .total-div .text-danger {
    display: none !important;
  }

  .collection-sample-master .esc_detail_btn {
    outline: none !important;
    border: none !important;
    margin: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100px !important;
  }

  /*  Txt Input */
  .collection-sample-master .custom_txt_input_main {
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    width: 100px !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 30px !important;
  }

  .collection-sample-master .custom_txt_input_detail_wrapper {
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .collection-sample-master .custom_txt_input_detail_wrapper .txt_input_detail_label {
    display: none !important;
  }

  .collection-sample-master .custom_txt_input_detail_wrapper div {
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .collection-sample-master .custom_txt_input_detail_wrapper div span,
  .collection-sample-master .custom_txt_input_detail_wrapper div p {
    display: none !important;
  }

  .collection-sample-master .custom_txt_input_detail_wrapper div .txt_input_detail_self {
    margin: 0 !important;
    padding: 0 !important;
    background-color: #f3f6f9 !important;
    display: inline !important;
    padding: 3px 0px !important;
    min-width: 100px !important;
    height: 18px !important;
    text-indent: 34px !important;
    font-size: 1rem !important;
  }

  .collection-sample-master input[type="text"]:focus {
    background-color: transparent !important;
  }

  .collection-sample-master .custom_txt_input_detail_wrapper div input::placeholder {
    color: #545564 !important;
    font-size: 1rem !important;
    text-align: end !important;
  }

  /* Custom-Multi-Select */
  .collection-sample-master .my-input-main {
    display: flex !important;
    align-items: center !important;
  }

  .collection-sample-master .my-input-main label {
    font-size: 10px !important;
    color: #101010 !important;
  }

  .collection-sample-master .is-valid,
  .collection-sample-master .is-invalid {
    border: none !important;
    background-image: none !important;
    width: 100px !important;
    min-height: 20px !important;
    margin-left: 22px !important;
    font-size: 1rem !important;
    display: flex !important;
    align-items: center !important;
  }

  .collection-sample-master .cms-extra .is-valid,
  .collection-sample-master .cms-extra .is-invalid {
    margin-left: 20px !important;
    padding: 0 !important;
  }

  .collection-sample-master
    .txt-extra
    .custom_txt_input_detail_wrapper
    div
    input::placeholder {
    color: #545564 !important;
    font-size: 1rem !important;
    margin-right: 18px !important;
  }

  .collection-sample-master
    .txt-extra
    .custom_txt_input_detail_wrapper
    div
    .txt_input_detail_self {
    text-indent: 16px !important;
  }
  .collection-sample-master .is-valid span,
  .collection-sample-master .is-invalid span {
    text-align: center !important;
  }

  .collection-sample-master .order-no-span {
    margin-left: 25% !important;
  }

  .collection-sample-master .detail_label {
    font-size: 10px !important;
  }

  /*GENEL MENU DETAIL*/
  .genel-divider {
    width: 100%;
    display: flex;
    border: 1px solid #0b5a85;
    background-color: #f3f6f9;
    border-radius: 12px;
    padding: 4px !important;
    margin-bottom: 8px;
  }

  .genel-divider .genel-sub-divider {
    width: 50%;
  }

  .genel-vertical-divider {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #0b5a85;
    background-color: #f3f6f9 !important;
    border-radius: 12px;
    padding: 4px !important;
    margin-bottom: 8px;
  }

  .genel-vertical-divider textarea {
    resize: none;
    text-indent: 8px;
    font-size: 12px;
    background-color: #f3f6f9 !important;
    font-family: "Poppins", sans-serif !important;
  }

  /* Customize Components */
  .genel-divider .custom-number-input,
  .genel-divider .my-input,
  .genel-divider .my-date-pick {
    background-color: #f3f6f9 !important;
    border-color: #78cdea !important;
    width: 100% !important;
  }

  .genel-divider .num-input-label,
  .genel-divider .multi-select-label,
  .genel-divider .txt_input_detail_self {
    font-weight: bolder !important;
  }

  .genel-divider .my-input {
    width: 150px !important;
  }

  /* Additional Fields (Termin) */
  .additional-global {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    padding: 0;
    margin: 0;
  }

  .termin-master {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    border: 1px solid #0b5a85;
    background-color: #f3f6f9 !important;
    border-radius: 12px;
    padding: 14px 4px 4px 4px !important;
    margin-bottom: 36px;
  }

  .termin-master .termin-add {
    position: absolute;
    margin-top: 3px;
    top: 0;
    right: -1%;
  }

  .termin-master .termin-genel-divider {
    width: 100%;
    display: flex;
  }

  .termin-master .termin-genel-divider .termin-genel-sub-divider {
    width: 50% !important;
    display: flex;
  }

  .termin-genel-sub-divider .my-date-pick-main .is-valid {
    width: 150px !important;
  }

  .termin-genel-sub-divider .my-date-pick-main label {
    font-weight: bolder !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .termin-genel-sub-divider .my-date-pick-main div {
    margin: 0 !important;
  }

  .termin-genel-divider .custom-number-input,
  .termin-genel-divider .my-input,
  .termin-genel-divider .my-date-pick {
    background-color: #f3f6f9 !important;
    border-color: #78cdea !important;
    width: 100% !important;
  }

  .composition-div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 6px;
  }

  .composition-div .my-input-main {
    width: 150px !important;
  }

  .composition-div .my-input {
    background-color: #f3f6f9 !important;
  }

  .composition-div .my-input-main label {
    font-weight: bolder !important;
  }

  .composition-div .relative .my-input {
    width: 150px !important;
  }

  /* composition table */
  .termin-master table tbody tr:first-child {
    border-left: 1px solid #78cdea;
  }

  /*Cins Table */
  .cins-master {
    overflow-x: auto;
    border: 1px solid #0b5a85;
    background-color: #f3f6f9 !important;
    border-radius: 12px;
  }

  .cins-master .cins-th {
    border-bottom: 1px solid #78cdea !important;
    width: 100px !important;
  }

  .cins-master .cins-th div {
    display: inline-flex !important;
    font-size: 12px !important;
    padding: 0 20px !important;
    min-width: 100px !important;
  }
  .cins-master table tbody tr td {
    border-right: 1px solid #78cdea !important;
  }

  .cins-master .my-input {
    background-color: #f3f6f9 !important;
    border-color: #78cdea !important;
  }
  /*Masraf Master */
  .masraf-master .masraf-body {
    border: 1px solid #0b5a85;
    background-color: #f3f6f9 !important;
    border-radius: 12px;
  }

  .masraf-master .masraf-top-menu-items {
    font-weight: bolder;
  }

  .masraf-master .masraf-txt-input {
    width: 45px !important;
    font-size: 9px;
    border-color: #78cdea;
    background-color: #f3f6f9;
  }

  .masraf-master select option {
    background-color: #f3f6f9 !important;
  }

  .beden-master {
    border: 1px solid #0b5a85;
    background-color: #f3f6f9 !important;
    border-radius: 12px;
  }

  .guncelle-btn-div .esc_detail_btn {
    border: 1px solid #78cdea;
    background-color: #f3f6f9 !important;
    border-radius: 12px;
  }

  .renk-plus {
    border-bottom: 1px solid #78cdea;
  }

  .renk-plus span {
    width: 35px !important;
    height: 35px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .renk-plus span img {
    width: 35px !important;
    height: 35px !important;
  }

  /*TEDARIK START */
  .tedarik-master {
    border: 1px solid #0b5a85;
    background-color: #f3f6f9 !important;
    border-radius: 12px;
    margin-top: 24px !important;
  }

  .tedarik-master .masraf-content-tbl .my-input,
  .tedarik-master .masraf-content-tbl .my-date-pick {
    background-color: #f3f6f9 !important;
  }

  .tedarik-master .masraf-content-tbl .txt_input_detail_label {
    display: none !important;
  }
  .tedarik-master .custom_txt_input_main {
    margin: 0 !important;
    width: 95px !important;
    max-width: 150px !important;
  }

  .tedarik-master .custom_txt_input_detail_wrapper div {
    margin: 0 !important;
    padding: 0 !important;
  }

  .tedarik-master .custom_txt_input_detail_wrapper label {
    display: none !important;
  }

  #to_who {
    background-color: #f3f6f9 !important;
    width: 100px !important;
    margin: 0 !important;
    border: 1px solid #78cdea !important;
    padding: 4px 12px !important;
    border-radius: 12px !important;
  }

  .tedarik-top-btns {
    border: 1px solid #78cdea !important;
    border-radius: 12px;
  }

  .custom-tab-box {
    background: #0b5a85 !important;
    color: #fff !important;
  }

  .masraf-badge-master {
    width: 100%;
    display: flex !important;
    justify-content: space-around !important;
    align-items: center !important;
    gap: 8px;
  }
  .masraf-badge-master select:focus,
  .masraf-badge-master select:active {
    outline: 1px solid #78cdea !important;
  }

  .masraf-badge-master .is-valid,
  .masraf-badge-master .is-invalid {
    background-color: #f3f6f9;
    border-color: #78cdea;
  }

  .tbl-border-bottom-right {
    border-bottom: 1px solid #78cdea !important;
    border-right: 1px solid #78cdea !important;
  }

  .tbl-border-right {
    border-right: 1px solid #78cdea !important;
  }

  .birim-fiyat-iskonto-kdv {
    display: flex;
    overflow-x: auto;
    border-top: 1px solid #0b5a85;
    margin-top: 24px !important;
    padding-top: 8px;
    width: 100% !important;
  }

  .masraf-alt .is-valid,
  .masraf-alt .is-invalid,
  .masraf-content-tbl .is-invalid,
  .date-goals-div .custom-number-input {
    background-color: #f3f6f9 !important;
    border-color: #78cdea !important;
  }

  .date-goals-div:nth-child(odd) {
    border-left: 1px solid #78cdea;
  }

  .expense-bottom-btns .esc_detail_btn {
    font-size: 10px !important;
    font-weight: bolder !important;
    margin-bottom: 10px;
    background-color: #0b5a85 !important;
    color: #fff !important;
    padding: 4px 8px !important;
  }

  .expense-bottom-btns .esc_detail_btn:hover {
    background-color: #fff !important;
    border-color: #78cdea !important;
    color: #0b5a85 !important;
  }

  .esc-div .esc_detail_btn {
    border: none !important;
  }

  .po-div {
    margin-right: 24px !important;
  }
  .po-div .txt_input_detail_self {
    background-color: #f3f6f9 !important;
  }
}
@media only screen and (max-width: 375px) and (max-height: 740px) {
  .instruct_btn {
    position: absolute;
    bottom: -35%;
    left: 20%;
    background-color: #0b5a85;
    color: #fff;
    margin-top: 4px !important;
    padding: 4px 6px;
  }

  .detail_label {
    font-size: 8px;
  }

  .detail_label_soft {
    font-size: 9px;
  }

  .detail_customer_input {
    position: absolute !important;
    top: 0 !important;
    left: -21% !important;
  }

  .detail_customer_input .my-text span {
    font-size: 8px !important;
  }

  .esc_detail_btn {
    font-size: 8px !important;
  }

  .txt_input_detail_self {
    font-size: 8px !important;
  }

  .detail-bottom-btn {
    font-weight: 600 !important;
    border-radius: 18px !important;
    padding: 2px 10px !important;
    width: 140px !important;
    height: 40px !important;
    font-size: 12px !important;
  }

  .genel-divider .my-date-pick-main .is-valid,
  .genel-divider .custom-number-input,
  .genel-divider .my-input {
    width: 120px !important;
  }

  .masraf-m-items {
    font-size: 7px !important;
  }

  .masraf-m-items:last-of-type {
    left: 80% !important;
  }
  .masraf-m-items-s {
    font-size: 7px !important;
  }

  .masraf-m-items-s:last-of-type {
    left: 82% !important;
  }

  .termin-genel-sub-divider .my-date-pick-main .is-valid {
    width: 120px !important;
  }

  .collection-sample-master {
    overflow-x: hidden !important;
  }

  .collection-sample-master .esc_detail_btn {
    font-size: 1rem !important;
    width: 90px !important;
  }

  .collection-sample-master .total-div .custom-number-input {
    height: 18px;
    margin-left: 14px !important;
  }

  .collection-sample-master .total-div .custom-number-input::placeholder {
    color: #545564 !important;
    font-size: 1rem !important;
    text-align: end !important;
  }

  .collection-sample-master
    .txt-extra
    .custom_txt_input_detail_wrapper
    div
    input::placeholder {
    margin-right: 22px !important;
  }
}
@media only screen and (max-width: 375px) and (max-height: 680px) {
  .instruct_btn {
    position: absolute;
    bottom: -30%;
    left: 22%;
    background-color: #0b5a85;
    color: #fff;
    margin-top: 4px !important;
    padding: 4px 6px;
  }

  .detail-bottom-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600 !important;
    border-radius: 18px !important;
    padding: 2px 10px !important;
    width: 100px !important;
    height: 25px !important;
    font-size: 10px !important;
  }

  .detail-close-btn {
    width: 20px !important;
    height: 20px !important;
  }

  .button-with-icon-custom-icon img {
    width: 15px !important;
    height: 15px !important;
  }
}
.my-order-amount > div > div > input {
  border: none !important;
  margin: 0 !important;
  font-size: 10px !important;
  height: 10px !important;
  display: inline !important;
  background-color: #fff !important;
}
</style>
