<template>
  <div>
    <div class="w-100 d-flex justify-content-center mt-2 items-center">
      <button-with-icon
        v-if="isEditing"
        :btn-styles="{ border: '1px solid red !important', color: 'red !important' }"
        :icon-name="null"
        :text="$t('order_form.delete_form').toUpperCase()"
        class="mx-1"
        @onClick="onClickCancelForm"
      ></button-with-icon>

      <button-with-icon
        v-if="showSubmitButton"
        :icon-name="null"
        :text="
          $t(
            isEditing ? 'order_form.update_form' : 'order_form.create_form'
          ).toUpperCase()
        "
        btn-classes="text-primary"
        size="md"
        @onClick="$emit('submit')"
      ></button-with-icon>
    </div>
  </div>
</template>

<script>
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import { CREATE_ITEM } from "@/core/services/store/REST.module";

export default {
  name: "BottomButtons",
  components: { CustomMultiSelect, ButtonWithIcon },
  props: {
    isValidToSubmit: {
      required: true,
      type: Boolean,
    },
    isEditing: {
      required: true,
      type: Boolean,
    },
    model: {
      required: true,
    },
    emails: {
      required: true,
    },
    name: {
      required: true,
      type: String,
    },
    orderId: {
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onClickCancelForm() {
      const self = this;
      this.sweetAlertConfirm(
        this.$t(
          "order.are_you_sure_about_cancelling_all_the_instruction_from_current_type"
        )
      ).then((response) => {
        if (response) {
          self.$emit("onClickCancelForm");
        }
      });
    },
  },
  computed: {
    showSubmitButton() {
      if (this.isEditing) {
        return this.isValidToSubmit;
      } else {
        return !this.model.id && this.isValidToSubmit && this.isAllSubmitted;
      }
    },
    isAllSubmitted() {
      return true;
    },
  },
};
</script>

<style scoped></style>
